import React, { useEffect } from "react";
import { useLoginMutation } from "../../lib/features/auth/authApiSlice";
import { Button, Checkbox, Form, FormProps, Input } from "antd";
import {
  errorMessage,
  infoMessage,
  successMessage,
} from "../../constants/notification";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticate } from "../../lib/features/auth/authSlice";
import { setProfile } from "../../lib/features/user/userSlice";
import { useLazyGetStartUpQuery } from "../../lib/features/startup/startupApiSlice";
import { useLazyGetConversationsQuery } from "../../lib/features/conversation/conversationApiSlice";
import { CiMail } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
};

function LoginComponent({
  isLogin,
  setIsLogin,
}: {
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [login, { isLoading: loginIsLoading }] = useLoginMutation();
  const [getStartUp] = useLazyGetStartUpQuery();
  const [getConversations] = useLazyGetConversationsQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const helperLogin = useCallback(
    async (params: { [key: string]: string }) => {
      await login(params)
        .unwrap()
        .then(async (res: any) => {
          localStorage.setItem("first_login", "false");

          // await updateProfileImage({ userId: res?.userData.id })
          if (res?.detail?.session) {
            localStorage.setItem("session", res?.detail?.session);
            localStorage.setItem("username", params.username);
            successMessage(res?.detail?.message || "New password required.");
            navigate("/new-password");
          } else {
            dispatch(authenticate(res.signinResponse.AuthenticationResult));
            dispatch(setProfile(res?.userData));
            getStartUp(res?.userData.id);
            getConversations(res?.userData.id);
            if (res?.userData.on_boarding_complete) {
              navigate("/funder-pipeline", { replace: true });
              successMessage("Login successful");
            } else {
              navigate("/get-started/questions", { replace: true });
              infoMessage(
                "You need to complete onboarding before we move forward!"
              );
            }
          }
        })
        .catch((err) => {
          errorMessage(err?.data?.detail || "Failed to login");
        });
    },
    [dispatch, getConversations, getStartUp, login, navigate]
  );

  const location = useLocation();
  useEffect(() => {
    // Extract the code from the URL
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      console.log("Authorization code received:", code);
      // Call the login function with the code
      helperLogin({ username: "", password: "", code: code });
    } else {
      console.error("No authorization code found in URL");
    }
  }, [location, helperLogin]);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    if (isLogin) {
      try {
        const { username, password } = values;
        if (!username || !password) {
          errorMessage("Complete the fields");
          return;
        } else if (password.length <= 7) {
          errorMessage("Password should be at least 8 characters long");
          return;
        }

        helperLogin({ username: username, password: password, code: "" });
      } catch (e: any) {
        errorMessage(e);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/funder-pipeline", { replace: true });
    }
  }, [navigate]);

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {};
  return (
    <div className="mt-8">
      <Form
        layout="vertical"
        style={{ width: 350 }}
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          hasFeedback
          label="Email"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input
            className="h-11"
            placeholder="Enter your email"
            suffix={<CiMail size={16} className="text-grey" />}
          />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          hasFeedback
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="h-11" placeholder="••••••••" />
        </Form.Item>

        <div className="flex items-center justify-between mb-6">
          <div>
            <Checkbox>Remember me</Checkbox>
          </div>
          <div>
            <Link to={"/forgot-password"} className="">
              <span className="text-primary cursor-pointer">
                Forgot Password?
              </span>
            </Link>
          </div>
        </div>

        <Form.Item>
          <Button
            disabled={loginIsLoading}
            loading={loginIsLoading}
            className="py-4 w-full bg-primary600 text-base font-semibold text-white"
            htmlType="submit"
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginComponent;
