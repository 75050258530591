import { Button, Form, FormProps, Input } from "antd";
import React from "react";
import { useRegisterMutation } from "../../lib/features/auth/authApiSlice";
import { errorMessage, successMessage } from "../../constants/notification";
import { CiMail } from "react-icons/ci";
import { FaLinkedin } from "react-icons/fa6";

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
};

function RegisterComponent({
  isLogin,
  setIsConfirm,
}: {
  isLogin: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [register, { isLoading: registerIsLoading }] = useRegisterMutation();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      if (isLogin) {
        return;
      } else {
        const { email, password, first_name, last_name } = values;
        await register({
          username: "",
          email: email,
          password: password,
          first_name: first_name,
          last_name: last_name,
        })
          .unwrap()
          .then((res) => {
            successMessage(
              "Registration successful, Kindly confirm your account."
            );
            localStorage.setItem("EMAIL", email ?? "");
            setIsConfirm(true);
          })
          .catch((err) => {
            if (err?.data?.detail === "User not confirmed") {
              successMessage(
                "Registration successful, Kindly confirm your account."
              );
              localStorage.setItem("EMAIL", email ?? "");
              setIsConfirm(true);
              return;
            }
            errorMessage(
              err?.data?.detail?.length < 50
                ? err?.data?.detail
                : "Something went wrong, Please try again later"
            );
          });
      }
    } catch (e: any) {
      errorMessage(e);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {};
  return (
    <Form
      layout="vertical"
      style={{ width: 350 }}
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mt-8"
    >
      <Form.Item
        name={"email"}
        hasFeedback
        label="Email"
        rules={[
          { required: true, message: "Email is invalid!", type: "email" },
        ]}
      >
        <Input
          className="h-11"
          placeholder="Enter your email"
          suffix={<CiMail size={16} className="text-grey" />}
        />
      </Form.Item>

      <Form.Item<FieldType>
        hasFeedback
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "Please input your first name!" }]}
      >
        <Input className="h-11" placeholder="Enter your first name" />
      </Form.Item>

      <Form.Item<FieldType>
        hasFeedback
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "Please input your last name!" }]}
      >
        <Input className="h-11" placeholder="Enter your last name" />
      </Form.Item>

      <Form.Item<FieldType>
        label="Password"
        name="password"
        hasFeedback
        rules={[
          { required: true, message: "Please input your password!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || value.length <= 7) {
                return Promise.reject(
                  new Error("Please input a stronger password")
                );
              }
              const regex =
                /^(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
              if (!regex.test(value)) {
                return Promise.reject(
                  new Error(
                    "Password must contain at least one number, one special character (!@#$%^&*-), one uppercase letter, and one lowercase letter."
                  )
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.Password className="h-11" placeholder="Create a password" />
      </Form.Item>

      {/* <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password className='h-11' />
            </Form.Item> */}

      <div className="mt-2.5 space-y-8">
        <Form.Item>
          <Button
            disabled={registerIsLoading}
            loading={registerIsLoading}
            type="primary"
            style={{ backgroundColor: "#228BE6", width: "100%" }}
            htmlType="submit"
          >
            Get started
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default RegisterComponent;
