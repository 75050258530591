import { cn } from "../utils/utils";
import { MemoizedReactMarkdown } from "./markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

export function AIMessage({
  content,
  className,
}: {
  content: string;
  className?: string;
}) {
  return (
    <div className={cn("group relative flex items-start", className)}>
      <div className="flex-1 space-y-2 overflow-hidden px-1">
        <MemoizedReactMarkdown
          className="space-y-3 leading-loose lg:text-[18px] text-gray-700 text-sm"
          remarkPlugins={[remarkGfm, remarkMath]}
          // components={{
          //     p({ children }: {children: any}) {
          //         return <p className="mb-2 last:mb-0">{children}</p>
          //     },
          // }}
        >
          {content}
        </MemoizedReactMarkdown>
      </div>
    </div>
  );
}
