import { useCallback, useEffect, useRef, useState } from "react";
import { IoClose, IoMicOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import LandingPage from "../../../assets/landingPage.png";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import {
  useAiCompleteProfileMutation,
  useGetSingleAIConversationQuery,
} from "../../../lib/features/ai_agent/aiAgentApiSlice";
import { errorMessage, successMessage } from "../../../constants/notification";
import { Spin } from "antd";
import { motion } from "framer-motion";
import { HiOutlineMicrophone } from "react-icons/hi2";
import { useGetUserQuery } from "../../../lib/features/user/userApiSlice";
import { useGetStartUpQuery } from "../../../lib/features/startup/startupApiSlice";

const mimeType = "audio/webm";

const Questions = () => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const [permissions, setPermissions] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [startRecording, setStartRecording] = useState(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userId = useAppSelector((state: RootState) => state.user?.id);
  const startupId = useAppSelector((state: RootState) => state.startup?.id);

  const { refetch: userDataRefetch } = useGetUserQuery(userId);
  const { refetch: startupDataRefetch } = useGetStartUpQuery(userId);

  const { refetch } = useGetSingleAIConversationQuery({ userID: userId });

  const [aiCompleteProfile] = useAiCompleteProfileMutation();

  const navigate = useNavigate();

  const uploadAudio = async (blob: Blob) => {
    const formData = new FormData();
    formData.append("user_id", userId as string);
    formData.append("startup_id", startupId as string);

    if (blob) {
      formData.append("user_audio", blob, "recording.webm");
    }

    setIsLoading(true);
    await aiCompleteProfile(formData)
      .unwrap()
      .then((res) => {
        const base64Audio = res.response;

        const byteCharacters = atob(base64Audio);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const byteArray = new ArrayBuffer(1024);
          const byteArrayView = new Uint8Array(byteArray);

          for (let i = 0; i < byteArrayView.length; i++) {
            byteArrayView[i] = byteCharacters.charCodeAt(offset + i);
          }

          byteArrays.push(byteArrayView);
        }

        const audioBlob = new Blob(byteArrays, {
          type: "audio/mpeg",
        });
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);
        setIsAnimating(true);
        audio.onended = () => {
          setIsAnimating(false);
          setIsLoading(false);

          if (res.profile_completed) {
            userDataRefetch();
            startupDataRefetch();
            refetch();
            successMessage(
              "You have completed all the questions, You will be redirected to the recommendation page in 3 seconds"
            );
            const timer = setTimeout(() => {
              navigate("/get-started/startup-form", { replace: true });
            }, 3000);
            return () => clearTimeout(timer);
          }
        };
        audio.play();
      })
      .catch((error) => {
        errorMessage(error.data.detail || "Error! Problem fetching issue");
      })
      .finally(() => setIsLoading(false));
  };

  const getMicrophonePermissions = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setPermissions(true);
      setStream(streamData);
    } catch (error: any) {
      alert(error.message || "Failed to get microphone permissions.");
    }
  };

  const handleStartRecording = () => {
    if (!stream) return;
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    media.start();
    setStartRecording(true);

    const chunks: Blob[] = [];
    media.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    media.onstop = () => {
      const audioBlob = new Blob(chunks, { type: mimeType });
      uploadAudio(audioBlob);
    };
  };

  const handleStopRecording = () => {
    if (mediaRecorder.current && startRecording) {
      mediaRecorder.current.stop();
      setStartRecording(false);
    }
  };

  const fetchDataFirstTime = useCallback(async () => {
    if (permissions) {
      const formData = new FormData();
      formData.append("user_id", userId!!);
      formData.append("startup_id", startupId!!);
      formData.append("user_response", "");

      setIsLoading(true);

      await aiCompleteProfile(formData)
        .unwrap()
        .then((res) => {
          const base64Audio = res.response;

          const byteCharacters = atob(base64Audio);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const byteArray = new ArrayBuffer(1024);
            const byteArrayView = new Uint8Array(byteArray);

            for (let i = 0; i < byteArrayView.length; i++) {
              byteArrayView[i] = byteCharacters.charCodeAt(offset + i);
            }

            byteArrays.push(byteArrayView);
          }

          const audioBlob = new Blob(byteArrays, {
            type: "audio/mpeg",
          });
          const audioUrl = URL.createObjectURL(audioBlob);

          const audio = new Audio(audioUrl);
          setIsAnimating(true);
          audio.onended = async () => {
            if (res.profile_completed) {
              await startupDataRefetch();
              await userDataRefetch();
              await refetch();
              setIsAnimating(false);
              setIsLoading(false);
              successMessage(
                "You have completed all the questions, You will be redirected to the recommendation page in 3 seconds"
              );
              const timer = setTimeout(() => {
                navigate("/get-started/startup-form", { replace: true });
              }, 3000);
              return () => clearTimeout(timer);
            }

            setIsAnimating(false);
            setIsLoading(false);
          };
          audio.play();
        })
        .catch((err) => {
          errorMessage(err.data.detail || "Error! Problem fetching issue");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    aiCompleteProfile,

    navigate,
    startupId,
    userId,
    permissions,
    refetch,
    startupDataRefetch,
    userDataRefetch,
  ]);

  useEffect(() => {
    getMicrophonePermissions();
  }, []);

  useEffect(() => {
    fetchDataFirstTime();
  }, [fetchDataFirstTime]);

  return (
    <div className="flex flex-col h-screen bg-white">
      <nav className="flex items-center justify-between pt-6 px-4 md:px-12 lg:px-32">
        <img
          src={Logo}
          alt="Logo"
          className="h-8 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </nav>
      <input type="file" name="ai-audio" hidden ref={fileRef} />
      <div className="flex-grow flex mt-20 md:mt-36">
        <div className="w-full flex flex-col justify-between items-center px-2">
          <motion.div
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 20 }}
            className="bg-[#D6F0FF26] flex items-center justify-center rounded-full w-[264px] h-[264px] rounded-full"
            style={{
              boxShadow:
                "0px -6.5px 9.75px 0px #1F9FDF26, 0px 6.5px 6.5px 0px #1F9FDF26",
            }}
          >
            <img
              src={LandingPage}
              alt="landingPage"
              className={`h-[189px] w-[158px] ${
                startRecording
                  ? "animate-pulse"
                  : isAnimating
                  ? "animate-bounce"
                  : ""
              }`}
            />
          </motion.div>
          <motion.div
            className="h-[158px] w-full flex flex-col items-center gap-y-4 pt-4 border-t-2 border-primary600"
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 20 }}
          >
            <div className="flex items-center gap-2 text-[#031016]">
              <HiOutlineMicrophone size={20} />
              <p className="text-[16px]">
                Tap to start recording. Speak your mind!
              </p>
            </div>
            <div className="w-full flex items-center justify-center">
              {isLoading && <Spin className="mt-2" />}
              {!permissions && !isLoading && (
                <button
                  onClick={getMicrophonePermissions}
                  className="bg-white border border-primary600 rounded-lg flex items-center p-4 gap-2 cursor-pointer hover:bg-primary400 transition-all duration-300 hover:text-white hover:border-primary400"
                >
                  Get Permissions
                </button>
              )}
              <div className="flex gap-4">
                {permissions && !startRecording && !isLoading && (
                  <div
                    onClick={handleStartRecording}
                    className="bg-primary600 w-[64px] h-[64px] text-white rounded-full flex items-center justify-center cursor-pointer"
                  >
                    <IoMicOutline size={24} />
                  </div>
                )}
                {permissions && startRecording && !isLoading && (
                  <button
                    onClick={handleStopRecording}
                    className="bg-[#D2ECF9] w-[64px] h-[64px] rounded-full flex items-center justify-center cursor-pointer"
                  >
                    <IoClose size={24} color="#136086" />
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
