import Logo from "../../../assets/logo.png";
import LandingPage from "../../../assets/landingPage.png";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormProps,
  Input,
  Select,
  Spin,
  Tooltip,
  Upload,
  UploadProps,
} from "antd";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../lib/store";
import { ReactNode, useEffect, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { capitalizeFirstLetter } from "../../Recommendation/Recommendation";
import {
  Entrepreneurial_Experience,
  Ethnicities,
  Funds_Raised,
  Genders,
  Industries,
  MRR,
  Nationalities,
  ProductStagess,
  Races,
  Users,
} from "../../../constant/data";
import { Countries } from "../../../constants/countries";
import { motion } from "framer-motion";
import {
  useGetStartUpQuery,
  useUpdateStartUpAndUserMutation,
} from "../../../lib/features/startup/startupApiSlice";
import { errorMessage, successMessage } from "../../../constants/notification";
import UploadGif from "../../../assets/gifs/upload.gif";
import useAnalyticsEventTracker from "../../../utils/Analytics";
import {
  useGetUserQuery,
  useLazyGetGeneratedFeedbackQuery,
} from "../../../lib/features/user/userApiSlice";
import { useAppSelector } from "../../../lib/hooks";
import UploadIcon from "../../../components/Icons/UploadIcon";
import { usePitchDeckProcessMutation } from "../../../lib/features/pitch_deck/pitchDeck";
import PitchDeckLoading from "../../../components/PitchDeckLoading";
const { Option } = Select;

const StartUpProfile = () => {
  const navigate = useNavigate();

  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    name_of_start_up: "",
    about_start_up: "",
    product_stages: "",
    industry: "",
    monthly_recurring_revenue: "",
    users: "",
    foundername: "",
    founder_bios: "",
    prominent_investor_bios: "",
    fund_raised: "",
    entrepreneurial_experience: "",
    pre_accelerators: "",
    race: "",
    ethnicity: "",
    gender: "",
    country: "",
    nationality: "",
    recent_employer: "",
    penultimate_employer: "",
    employers_last_10_years: "",
    under_school: "",
    graduate_school: "",
    highest_degree: "",
  });

  const [updateStartUpAndUser, { isLoading: submitLoading }] =
    useUpdateStartUpAndUserMutation();
  const [pitchDeckProcess] = usePitchDeckProcessMutation();
  const [trigger] = useLazyGetGeneratedFeedbackQuery();

  const user = useSelector((state: RootState) => state.user, shallowEqual);
  const startupData = useSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );
  const gAnalytics = useAnalyticsEventTracker("onBoarding");

  const { refetch: userDataRefetch } = useGetUserQuery(user?.id);
  const { refetch: startupDataRefetch } = useGetStartUpQuery(user?.id);

  const accessToken = useAppSelector((state: RootState) => state.auth.access);
  const [form] = Form.useForm();

  const props: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_BACKEND_BASE_URL}pitch_deck_uploader/uploading_pitch_deck/`,
    method: "POST",
    accept: ".pdf,.ppt,.pptx,.doc,.docx",
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    data: {
      user_id: user?.id,
      startup_id: startupData?.id,
    },
    async onChange(info) {
      await gAnalytics("Chose to Upload Pitch deck", user?.email || "User");

      if (info.file.status !== "uploading") {
        setUploadLoading(true);

        await pitchDeckProcess({ user_id: user.id })
          .unwrap()
          .then(async () => {
            await startupDataRefetch();
            await userDataRefetch();
            await trigger(true);
            successMessage(`Pitch Deck Data uploaded successfully!`);
          })
          .catch((err) => {
            errorMessage(
              err?.data?.detail ?? `Error! Failed to upload Pitch Deck Data`
            );
          })
          .finally(() => {
            setUploadLoading(false);
          });
      }
      if (info.file.status === "done") {
        successMessage(`${info.file.name} file uploaded successfully`);
        navigate("/get-started/options");
      } else if (info.file.status === "error") {
        errorMessage(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleSubmit: FormProps<any>["onFinish"] = async (values) => {
    const formData = {
      id: user.id,
      startup_id: startupData.id,
      user_data: {
        entrepreneurial_experience: values?.entrepreneurial_experience || "",
        pre_accelerators: values?.pre_accelerators || "",
        race: values?.race || "",
        ethnicity: values?.ethnicity || "",
        gender: values?.gender || "",
        country: values?.country || "",
        nationality: values?.nationality || "",
        recent_employer: values?.recent_employer || "",
        penultimate_employer: values?.penultimate_employer || "",
        employers_last_10_years: values?.employers_last_10_years || "",
        under_school: values?.under_school || "",
        graduate_school: values?.graduate_school || "",
        highest_degree: values?.highest_degree || "",
      },
      start_up_data: {
        name_of_start_up: values?.name_of_start_up || "",
        about_start_up: values?.about_start_up || "",
        product_stages: values?.product_stages || "",
        industry: values?.industry || "",
        monthly_recurring_revenue: values?.monthly_recurring_revenue || "",
        users: values?.users || "",
        foundername: values?.foundername || "",
        founder_bios: values?.founder_bios || "",
        prominent_investor_bios: values?.prominent_investor_bios || "",
        fund_raised: values?.fund_raised || "",
      },
    };
    await updateStartUpAndUser(formData)
      .unwrap()
      .then(async () => {
        await trigger(true);
        navigate("/get-started/options", { replace: true });
        successMessage("Startup data added successfully!");
      })
      .catch((err) => {
        errorMessage(err.data.detail || "Error! Try again");
      });
  };

  useEffect(() => {
    const updatedValues = {
      name_of_start_up: startupData?.name_of_start_up || "",
      about_start_up: startupData?.about_start_up || "",
      product_stages: startupData?.product_stages || "",
      industry: startupData?.industry || "",
      monthly_recurring_revenue: startupData?.monthly_recurring_revenue || "",
      users: startupData?.users || "",
      foundername: startupData?.foundername || "",
      founder_bios: startupData?.founder_bios || "",
      prominent_investor_bios: startupData?.prominent_investor_bios || "",
      fund_raised: startupData?.fund_raised || "",
      entrepreneurial_experience: user?.entrepreneurial_experience || "",
      pre_accelerators: user?.pre_accelerators || "",
      race: user?.race || "",
      ethnicity: user?.ethnicity || "",
      gender: user?.gender || "",
      country: user?.country || "",
      nationality: user?.nationality || "",
      recent_employer: user?.recent_employer || "",
      penultimate_employer: user?.penultimate_employer || "",
      employers_last_10_years: user?.employers_last_10_years || "",
      under_school: user?.under_school || "",
      graduate_school: user?.graduate_school || "",
      highest_degree: user?.highest_degree || "",
    };

    setInitialValues(updatedValues);

    form.setFieldsValue(updatedValues);
  }, [startupData, user, form]);

  if (uploadLoading) return <PitchDeckLoading />;

  return (
    <div className="flex flex-col bg-white px-4 md:px-12 lg:px-32">
      <nav className="flex items-center justify-between pt-6 ">
        <img
          src={Logo}
          alt="Logo"
          className="h-8 cursor-pointer"
          onClick={() => navigate("/")}
        />

        <div className="bg-white border border-primary600 rounded-lg flex items-center p-4 gap-2 cursor-pointer hover:bg-primary400 transition-all duration-300 hover:text-white hover:border-primary400">
          <img src={LandingPage} alt="AI-logo" className="h-8 cursor-pointer" />
          <p>Talk to your AI Pilot</p>
        </div>
      </nav>

      <div className="w-full h-full flex flex-col bg-white mt-16">
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="w-full flex items-center justify-between"
        >
          <h2 className="font-semibold text-[30px] leading-[38px] text-gray-900">
            Your Startup Profile
          </h2>
          <button
            onClick={() => {
              navigate("/get-started/options", { replace: true });
            }}
            className="bg-white border text-primary600 border-primary600 rounded-lg font-semibold text-[18px] leading-[28px] flex items-center py-3 px-20 cursor-pointer hover:bg-primary600 transition-all duration-300 hover:text-white hover:border-primary400"
          >
            Skip
          </button>
        </motion.div>

        <motion.p
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="text-[14px] text-gray-600 leading-[20px] mt-8"
        >
          Build Your Startup Profile: Help us tailor recommendations to your
          specific needs by sharing more about your startup journey. Your
          information is private and only used to optimize your experience.
        </motion.p>

        <motion.div
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="w-full flex gap-2 items-center mt-4"
        >
          <div className="w-full bg-green-100 rounded-full h-2.5">
            <div
              className="bg-green-500 h-2.5 rounded-full"
              style={{ width: "60%" }}
            ></div>
          </div>
          <p className="text-gray-700 text-[14px] font-medium leading-[20px]">
            60%
          </p>
        </motion.div>

        <motion.p
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="text-[14px] text-gray-600 leading-[20px] mt-8"
        >
           Your story is unique, and so are our recommendations. Complete your
          profile to unlock tailored insights and opportunities. Rest assured,
          your data stays private—always.
        </motion.p>

        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="flex flex-col mt-12 bg-white"
        >
          <h2 className="font-semibold text-[24px] mb-8 leading-[32px] text-gray-900">
            Business Information
          </h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            requiredMark={(label: ReactNode, info: { required: boolean }) => {
              return info.required ? (
                <div className="flex items-start gap-x-1">
                  <p className="text-[#0F172A] font-medium text-sm">{label}</p>
                  <span className="text-red-600">*</span>
                  {/* <p className='bg-[#BDDCFF] text-[#4083CE] rounded-md text-[10px] px-2 py-1 font-medium'>Required</p> */}
                </div>
              ) : (
                <p className="text-[#0F172A] font-medium text-sm">{label}</p>
              );
            }}
          >
            <div className="grid md:grid-cols-2 gap-x-5">
              <Form.Item
                name="name_of_start_up"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the name of your start-up!",
                  },
                ]}
              >
                <Input placeholder="Name of Start-Up" />
              </Form.Item>

              <Form.Item
                name="foundername"
                label="Founder Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the founder's name!",
                  },
                ]}
              >
                <Input placeholder="Founder Name" />
              </Form.Item>
            </div>

            <div className="grid lg:grid-cols-2 gap-x-5">
              <Form.Item
                name="product_stages"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Stages</p>
                    <Tooltip
                      title={
                        <div>
                          <p>
                            Choose the stage that best describes the current
                            phase of your product's development
                          </p>
                          <ul className="list-disc">
                            <li>
                              <b>Pre-Seed</b>: Your product is in the initial
                              concept phase, where you're focusing on idea
                              validation, market research, and possibly building
                              a prototype.
                            </li>
                            <li>
                              <b>Seed</b>: You've moved beyond the concept stage
                              and are now developing the product, securing
                              initial funding, and possibly starting to build a
                              small team.
                            </li>
                            <li>
                              <b>Seed A</b>: Your product is gaining traction
                              with users or customers, and you're securing
                              further funding to expand development and
                              operations.
                            </li>
                            <li>
                              <b>Seed B+</b>: Your product is in the growth
                              phase, focusing on scaling, increasing market
                              share, and potentially preparing for large-scale
                              expansion or additional rounds of investment.
                            </li>
                          </ul>
                        </div>
                      }
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select the product stage!",
                  },
                ]}
              >
                <Select placeholder="Select a product stage">
                  <Option value={""}>Select a product stage</Option>
                  {ProductStagess.map((stage) => (
                    <Option
                      key={stage.stage}
                      value={stage.stage}
                      style={{ display: "flex" }}
                    >
                      {stage.stage}
                      {/* <Tooltip title={stage.tip} placement="right">
                                                        <IoIosInformationCircleOutline />
                                                    </Tooltip> */}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="industry"
                // label="Industry"
                label={"Industry"}
                rules={[
                  { required: true, message: "Please select the industry!" },
                ]}
              >
                <Select
                  placeholder="Select an industry"
                  mode="multiple"
                  suffixIcon={
                    <Tooltip
                      title="Specify the primary field or domain in which your startup or company operates. This information helps us match you with the most suitable venture capitalists or investors who specialize in your industry"
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  }
                >
                  <Option value={""}>Select an industry</Option>
                  {Industries.map((industry) => (
                    <Option key={industry} value={industry}>
                      {capitalizeFirstLetter(industry)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="monthly_recurring_revenue"
                // label="Monthly Recurring Revenue"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Monthly Recurring Revenue</p>
                    <Tooltip
                      title="Enter the total amount of predictable, recurring revenue your startup or company generates each month from subscriptions, contracts, or other recurring sources. This metric helps us understand the financial stability and growth potential of your business."
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select the monthly recurring revenue!",
                  },
                ]}
              >
                <Select placeholder="Select monthly recurring revenue">
                  <Option value={""}>Select monthly recurring revenue</Option>
                  {MRR.map((revenue) => (
                    <Option key={revenue} value={revenue}>
                      {revenue}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="users"
                // label="Users"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Users</p>
                    <Tooltip
                      title="Provide the estimated number of users or customers currently engaged with your startup or company. This can include active users, subscribers, or customers who regularly interact with your product or service."
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: false,
                    message: "Please select the number of users!",
                  },
                ]}
              >
                <Select placeholder="Select number of users">
                  <Option value={""}>Select number of users</Option>
                  {Users.map((user) => (
                    <Option key={user} value={user}>
                      {user}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="grid lg:grid-cols-2 gap-x-5">
              <Form.Item
                name="entrepreneurial_experience"
                // label="Entrepreneurial Experience"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Entrepreneurial Experience</p>
                  </div>
                }
                rules={[{ required: false }]}
              >
                <Select
                  placeholder="Select entrepreneurial experience"
                  mode="multiple"
                >
                  <Option value={""}>Select entrepreneurial experience</Option>
                  {Entrepreneurial_Experience.map((experience) => (
                    <Option key={experience} value={experience}>
                      {experience}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="pre_accelerators"
                label="Pre-Accelerators and/or Accelerators"
                // label={<div className="flex gap-x-1 items-center">
                //     <p>Pre-Accelerators and/or Accelerators</p>
                //
                // </div>}
              >
                <Input
                  placeholder="Pre-Accelerators and/or Accelerators"
                  suffix={
                    <Tooltip
                      title="Pre-accelerators are short-term programs aimed at helping early-stage startups validate their business ideas and develop prototypes. Accelerators are intensive programs that help startups with a developed product or service scale up and grow rapidly."
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </div>

            <div className="grid lg:grid-cols-2 gap-x-5">
              <Form.Item
                name="prominent_investor_bios"
                // label="Lead Investors"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Lead Investors</p>
                    <Tooltip
                      title="A lead investor is the primary or anchor investor in a funding round for a startup or business. This investor often contributes a significant portion of the total investment and plays a crucial role in negotiating the terms of the deal."
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
              >
                <Input placeholder="Prominent Investor Bios" />
              </Form.Item>

              <Form.Item
                name="fund_raised"
                // label="Total Amount Raised"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Total Amount Raised</p>
                    <Tooltip
                      title="The total amount raised refers to the cumulative sum of money that a startup or company has secured from investors over one or multiple funding rounds. This figure includes all forms of investment such as equity financing, debt financing, convertible notes, and other financial instruments. It provides a snapshot of the financial backing a company has received to support its growth and operations."
                      placement="right"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
              >
                <Select placeholder="Select total amount raised">
                  <Option value={""}>Select total amount raised</Option>
                  {Funds_Raised.map((fund) => (
                    <Option key={fund} value={fund}>
                      {fund}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              name="founder_bios"
              label="Founder Bio(s)"
              rules={[
                { required: true, message: "Please input the founder's bios!" },
              ]}
            >
              <Input.TextArea placeholder="Founder Bios" />
            </Form.Item>

            <Form.Item
              name="about_start_up"
              label="About Start-Up"
              rules={[{ required: true, message: "Please describe startup" }]}
            >
              <Input.TextArea placeholder="About Start-Up" />
            </Form.Item>

            <Form.Item label="Upload your pitch deck">
              <div className="flex flex-col p-6 mx-auto w-full text-center text-gray-900 bg-white border border-gray-300 rounded-lg xl:p-8">
                <div className="flex items-center justify-center">
                  <img src={UploadGif} alt="" className="size-20" />
                </div>
                <h3 className="mb-4 mt-2 text-lg font-semibold">
                  Upload Your Pitch Deck
                </h3>
                <p className="font-light text-gray-500 sm:text-[13px]">
                  Upload your pitch deck and let our AI do the heavy lifting by
                  analyzing it for key insights.
                </p>
                <div className="flex justify-center items-baseline my-7 w-full ">
                  <Upload {...props} className="w-full">
                    {/* <Button size='large' type='primary' className='!bg-primary' icon={<UploadOutlined />}>Click to Upload</Button> */}
                    <button
                      disabled={uploadLoading}
                      className="flex w-full justify-center items-center gap-x-3 bg-primary600 text-[15px] text-white px-6 py-3 rounded-lg text-sm font-normal text-center disbaled:bg-gray-100 disabled:text-gray-300"
                    >
                      <UploadIcon />
                      <p>Upload Pitch Deck</p>
                    </button>
                  </Upload>
                </div>
              </div>
            </Form.Item>
            <h2 className="font-semibold text-[24px] mb-8 leading-[32px] text-gray-900">
              Demographic Information
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-5">
              <Form.Item
                name="race"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Race</p>
                    <Tooltip
                      title={<p>Choose your race</p>}
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select your race!",
                  },
                ]}
              >
                <Select placeholder="Select your race">
                  {Races.map((race) => (
                    <Option key={race} value={race} style={{ display: "flex" }}>
                      {race}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="ethnicity"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Ethnicity</p>
                    <Tooltip
                      title={<p>Choose your ethnicity</p>}
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select your ethnicity!",
                  },
                ]}
              >
                <Select placeholder="Select your ethnicity">
                  {Ethnicities.map((ethnicity) => (
                    <Option
                      key={ethnicity}
                      value={ethnicity}
                      style={{ display: "flex" }}
                    >
                      {ethnicity}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="gender"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Gender</p>
                    <Tooltip
                      title={<p>Choose your gender</p>}
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select your gender!",
                  },
                ]}
              >
                <Select placeholder="Select your gender">
                  {Genders.map((gender) => (
                    <Option
                      key={gender}
                      value={gender}
                      style={{ display: "flex" }}
                    >
                      {gender}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
              <Form.Item
                name="nationality"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Nationality</p>
                    <Tooltip
                      title={<p>Choose your Nationality</p>}
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select your nationality!",
                  },
                ]}
              >
                <Select placeholder="Select your nationality">
                  {Nationalities.map((nationality) => (
                    <Option
                      key={nationality}
                      value={nationality}
                      style={{ display: "flex" }}
                    >
                      {nationality}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="country"
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>Country</p>
                    <Tooltip
                      title={<p>Choose your country</p>}
                      placement="right"
                      overlayClassName="lg:max-w-[500px] max-w-[350px]"
                    >
                      <IoIosInformationCircleOutline />
                    </Tooltip>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              >
                <Select placeholder="Select your country">
                  {Countries.map((item) => (
                    <Option key={item.code} value={item.name}>
                      {item.emoji} {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-5">
              <Form.Item
                name="recent_employer"
                label="Recent Employer"
                rules={[
                  {
                    required: true,
                    message: "Please input the name of your Recent Employer!",
                  },
                ]}
              >
                <Input placeholder="Name of Recent Employer" />
              </Form.Item>

              <Form.Item
                name="penultimate_employer"
                label="Penultimate Employer"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input the name of your Penultimate Employer!",
                  },
                ]}
              >
                <Input placeholder="Name of Penultimate Employer" />
              </Form.Item>

              <Form.Item
                name="employers_last_10_years"
                label="Employers in last 10 years"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input the name of your Employers in last 10 years!",
                  },
                ]}
              >
                <Input placeholder="Name of Employer in the last 10 years" />
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-5">
              <Form.Item
                name="under_school"
                label="Undergraduate School"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input the name of your Undergraduate School!",
                  },
                ]}
              >
                <Input placeholder="Name of Undergraduate School" />
              </Form.Item>

              <Form.Item
                name="graduate_school"
                label="Graduate School"
                rules={[
                  {
                    required: true,
                    message: "Please input the name of your Graduate School!",
                  },
                ]}
              >
                <Input placeholder="Name of Graduate School" />
              </Form.Item>

              <Form.Item
                name="highest_degree"
                label="Highest Degree"
                rules={[
                  {
                    required: true,
                    message: "Please input the name of your Highest Degree!",
                  },
                ]}
              >
                <Input placeholder="Name of Highest Degree" />
              </Form.Item>
            </div>

            <Form.Item className="w-full flex justify-center">
              <button
                className="bg-[#1980b3] h-[60px] rounded-md w-[210px] text-white text-[18px] font-semibold hover:bg-white hover:text-[#1980b3] hover:border hover:border-[#1980b3] transition-all duration-300 disabled:bg-gray-300"
                type="submit"
                disabled={submitLoading}
              >
                {submitLoading ? <Spin /> : "Submit"}
              </button>
            </Form.Item>
          </Form>
        </motion.div>
      </div>
    </div>
  );
};

export default StartUpProfile;
