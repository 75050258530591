import React, { FC } from "react";

interface IProps {
  username?: string;
  comment: string;
  avatar?: string;
  onClick: () => void;
}

export const SessionItem: FC<IProps> = ({
  username,
  comment,
  avatar,
  onClick,
}) => {
  return (
    <div
      className=" flex items-center mb-3 mt-1 gap-2 px-2.5 py-3 p-2 text-white border-b border-white text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all"
      onClick={onClick}
    >
      <div>
        {avatar && <img src={avatar} alt={"avatar"} className="size-9" />}
      </div>
      <div className="flex flex-col gap-1">
        {username && <div className="text-xs text-white">{username}</div>}
        <div className="text-white text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
          {comment}
        </div>
      </div>
    </div>
  );
};
