import { Modal, Tooltip } from "antd";
import { FC, useState } from "react";
import AILogo from "../../../assets/Animations/AILogo.png";
import { FaCheck, FaLinkedin } from "react-icons/fa6";
import Gmail from "../../../assets/svg/gmail.svg";
import { MdOutlineContentCopy } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { successMessage } from "../../../constants/notification";

type funnelModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
};
const FunnelModal: FC<funnelModalProps> = ({ isOpen, setIsOpen, data }) => {
  const user = useAppSelector((state: RootState) => state.user);

  const handleCopy = () => {
    const emailText = `
      Subject: Introduction and Opportunity to Collaborate
      Dear VC,
      I hope this email finds you well. My name is ${user.first_name}, and I am a [Your Role, e.g., Frontend Developer] with expertise in [mention your key skills or technologies, e.g., React, JavaScript, and Redux].
      
      I recently came across [your company/your profile/your project], and I am highly impressed by [specific details about their work or achievements, e.g., their innovative solutions in AI-driven platforms]. I would love to connect and explore how my skills in [specific skills relevant to them] can contribute to your goals.
      
      Please let me know if there’s a convenient time to discuss this further. I look forward to the possibility of working together.

      Best regards,
      ${user.first_name}
      ${user.email}
    `;
    navigator.clipboard.writeText(emailText.trim());
    successMessage("Email copied to clipboard!");
  };

  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={false}
      width={900}
    >
      <div className="flex flex-col gap-y-2 pt-8">
        <div className="flex items-center justify-end">
          {!data.fund ? (
            <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
              ANGEL
            </span>
          ) : (
            <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
              VENTURE CAPITALIST
            </span>
          )}
        </div>
        <div className="flex items-center gap-x-4">
          <img
            src={data.image || AILogo}
            alt=""
            className="size-16 rounded-full"
          />
          <div>
            <p className="text-[18px] font-semibold text-grey900">
              {data.name}
            </p>
            <p className="text-[16px]">
              <b className="text-grey900 font-medium">{"Fund: "}</b>
              <span className="text-grey600">
                {data.fund ? data.fund : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">Stages</p>
          <div className="flex gap-x-2 items-center">
            {data?.stages.split(",")?.map((stage: any) => (
              <span
                key={stage}
                className="w-max bg-primary100 text-primary700 h-fit text-xs font-medium me-2 flex items-center px-2 py-1 rounded-2xl"
              >
                {stage}
              </span>
            ))}
          </div>
        </div>

        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Focus Area(s)
          </p>
          <div className="flex gap-x-2 items-center">
            {data?.focus?.split(",").map((item: any) => (
              <span
                key={item}
                className="w-max bg-gray-100 text-gray-700 h-fit text-xs font-medium me-2 flex items-center px-2 py-1 rounded-2xl"
              >
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Willing to Lead
          </p>
          <div className="flex gap-x-2 items-center">
            <span
              className={`w-max ${
                data?.willing
                  ? "bg-primary100 text-primary700"
                  : "bg-red-100 text-red-700"
              } h-fit text-xs font-medium me-2 flex items-center px-2 py-1 rounded-full`}
            >
              {data.willing ? <FaCheck size={12} /> : <IoClose size={12} />}
            </span>
          </div>
        </div>

        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Recent Investment
          </p>
          <div className="flex gap-x-4 items-center">
            <span className="w-max bg-green-100 text-green-700 h-fit text-xs font-medium me-2 flex items-center px-2 py-1 rounded-2xl">
              {data?.recent_investment?.name ?? "-"}
            </span>
            <p className="text-[14px] text-black">
              {data?.recent_investment?.date ?? "-"}
            </p>
          </div>
        </div>

        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Shared Interest
          </p>
          <div className="flex gap-x-2 items-center">
            {data?.shared_interest?.map((item: any) => (
              <span
                key={item}
                className="w-max bg-primary100 text-primary700 h-fit text-xs font-medium me-2 flex items-center px-2 py-1 rounded-2xl"
              >
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className="flex gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Investment Range
          </p>
          <div className="flex gap-x-4 items-center">
            <p className="text-[14px] text-black">
              {data?.investment_range ?? "-"}
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-x-4">
          <p className="text-[16px] font-medium text-black py-2">
            Why This Match Works
          </p>
          <p className="text-[14px] text-grey600 pt-2 line-clamp-3">
            {data.reason}
          </p>
        </div>

        {!showMore && (
          <button
            onClick={() => setShowMore(true)}
            className="bg-white outline-none border border-primary700 rounded-md text-[14px] font-semibold py-2 hover:bg-primary700 hover:text-white transition-all duration-300"
          >
            Get Connected
          </button>
        )}

        {showMore && (
          <div className="w-full flex flex-col">
            <div className="flex mt-2 gap-x-2">
              <div className="flex items-center gap-x-2">
                <Tooltip title="Email">
                  <a href={`mailto:${data.email}`}>
                    <img src={Gmail} alt="" className="w-[50px] h-[50px]" />
                  </a>
                </Tooltip>
                <p className="text-grey600">Send Email</p>
              </div>
              <div className="flex items-center gap-x-2">
                <Tooltip title="Linkedin">
                  <a href={`${data.linkedin}`} target="_blank" rel="noreferrer">
                    <FaLinkedin
                      size={40}
                      className="text-[#0A66C2] hover:text-primary cursor-pointer"
                    />
                  </a>
                </Tooltip>
                <p className="text-grey600">Reach on LinkedIn</p>
              </div>
            </div>

            <div className="bg-primary50 w-full flex flex-col gap-y-4 py-2 px-4">
              <div className="flex w-full items-center justify-between text-gray-700">
                <div className="flex items-center gap-x-2" onClick={handleCopy}>
                  <MdOutlineContentCopy size={18} />
                  <p className="text-[18px]">Copy Email</p>
                </div>
                <span
                  onClick={() => setShowMore(false)}
                  className="cursor-pointer"
                >
                  <IoClose size={18} />
                </span>
              </div>
              <div className="bg-white p-2">
                {`Subject: Introduction and Opportunity to Collaborate Dear VC, I
                hope this email finds you well. My name is ${user.first_name},
                and I am a [Your Role, e.g., Frontend Developer] with expertise
                in [mention your key skills or technologies, e.g., React,
                JavaScript, and Redux]. I recently came across [your
                company/your profile/your project], and I am highly impressed by
                [specific details about their work or achievements, e.g., their
                innovative solutions in AI-driven platforms]. I would love to
                connect and explore how my skills in [specific skills relevant
                to them] can contribute to your goals. Please let me know if
                there’s a convenient time to discuss this further. I look
                forward to the possibility of working together. Best regards,
                ${user.first_name}
                ${user.email}`}
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FunnelModal;
