import React, { useEffect } from "react";
const Billing3 = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // pricing table with one product with multiple pricings
  // return React.createElement("stripe-pricing-table", {
  //   "pricing-table-id": "prctbl_1QWAYECHwtU8rPsFgakNr4jQ",
  //   "publishable-key": "pk_test_51QGJtNCHwtU8rPsFrCDDgW9NOhJ7wP6DEkxrjZ6uZ9xwZs3a4wxSWj4mOlMQVJRWJwwK682kYqYEIIDcEnYmiznh00MBhB0IWb",
  // });
  
  // pricing table with multiple products
  // return React.createElement("stripe-pricing-table", {
  //   "pricing-table-id": "prctbl_1QWBoFCHwtU8rPsF43Dyzf09",
  //   "publishable-key": "pk_test_51QGJtNCHwtU8rPsFrCDDgW9NOhJ7wP6DEkxrjZ6uZ9xwZs3a4wxSWj4mOlMQVJRWJwwK682kYqYEIIDcEnYmiznh00MBhB0IWb",
  // });

  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
  

  return(
    <div ref={scrollableDivRef} className="h-screen overflow-y-scroll">
      <section className="xl:py-[150px] py-16 max-w-[1440px] mx-auto px-4">
        <div className="xl:block flex flex-col items-center justify-center ">
          <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5 ">
            Pricing
          </span>
          <h3 className="text-4xl font-semibold mt-3 xl:text-left text-center">
            Start Your Free Trial Today!
          </h3>
          <p className="text-grey600 text-xl font-regular mt-5 text-center xl:text-left">
            Sign up now and save 50% with our annual plan, No credit card
            required!
          </p>
        </div>

        <div className="mt-24 max-w-[1440px] mx-auto">
          {
            React.createElement("stripe-pricing-table", {
              "pricing-table-id": "prctbl_1QY6BLCHwtU8rPsFoYBiJoPd",
              "publishable-key": "pk_live_51QGJtNCHwtU8rPsFKKbMQdsYXPbvyozac4TdAqdsYdGZr3jcJuViyEFzhfRe0MN2buw9iiVu364oXyOsKhlmtDF500ROHbE6WH",
            })
          }
        </div>
      </section>
  </div>
  )
};
export default Billing3;