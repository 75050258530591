import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import Play from "../../../assets/svg/Play.svg";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { IoClose } from "react-icons/io5";
import { Upload, UploadProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../lib/features/user/userApiSlice";
import { errorMessage, successMessage } from "../../../constants/notification";
import UploadIcon from "../../../components/Icons/UploadIcon";
import {
  useGetPitchDeckQuery,
  usePitchDeckDeleteMutation,
} from "../../../lib/features/pitch_deck/pitchDeck";
import { DocumentViewer } from "react-documents";
import { FiDelete, FiEdit, FiUpload } from "react-icons/fi";
import UploadGif from "../../../assets/gifs/upload.gif";

function PitchDeck() {
  const [isReplaceModalOpen, setReplaceIsModalOpen] = useState(false);
  const [isShareModalOpen, setShareIsModalOpen] = useState(false);

  const user = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const [updateUser] = useUpdateUserMutation();

  const accessToken = useAppSelector((state: RootState) => state.auth.access);
  const startupData = useAppSelector((state: RootState) => state.startup);

  const { refetch: userDataRefetch } = useGetUserQuery(user?.id);

  const {
    data: pitchDeckFile,
    isLoading: pitchDeckIsLoading,
    refetch: pitcheDeckRefetch,
  } = useGetPitchDeckQuery(user?.id);

  const [pitchDeckDelete, { isLoading: pitchDeckDeleteIsLoading }] =
    usePitchDeckDeleteMutation();

  const handleCancel = () => {
    setReplaceIsModalOpen(false);
  };
  const handleShareModalCancel = () => {
    setShareIsModalOpen(false);
  };
  const handleShareModalOk = () => {
    setShareIsModalOpen(false);
  };
  const handleOk = () => {
    setReplaceIsModalOpen(false);
  };

  const props: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_BACKEND_BASE_URL}pitch_deck_uploader/uploading_pitch_deck/`,
    method: "POST",
    accept: ".pdf,.ppt,.pptx,.doc,.docx",
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    data: {
      user_id: user?.id,
      startup_id: startupData?.id,
    },
    onChange(info) {
      console.log(info.file.status, "status");
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        updateUser({ userId: user?.id, uploaded_pitch_deck: true })
          .then((res) => {
            setReplaceIsModalOpen(false);
            navigate("/profile");
            // userDataRefetch().then(() => {
            //     navigate('/process-pitch-deck')
            // })
          })
          .catch((err) => {
            // errorMessage("Failed to update information")
          });
        successMessage(`${info.file.name} file uploaded successfully`);
        // navigate('/ai-complete-profile')
      } else if (info.file.status === "error") {
        errorMessage(`${info.file.name} file upload failed.`);
      }
    },
  };
  console.log("Pitchy", pitchDeckFile);
  return (
    <>
      <div className="lg:px-10 px-4 pt-4 w-full">
        <section className="border-b w-full border-[#E9ECEF]">
          <div className="flex flex-col md:flex-row items-center justify-between ">
            <div className="flex gap-x-6 items-center">
              <h2 className="text-xl font-medium">Pitch Deck</h2>
            </div>
            <div className="flex items-center gap-x-4">
              {/* <p className='mr-8'>2 Views</p> */}
              <Button
                size="large"
                onClick={() => {
                  pitchDeckDelete({ user_id: user?.id })
                    .then(() => {
                      pitcheDeckRefetch();
                      navigate("/profile");
                      successMessage("Pitch Deck deleted successfully");
                    })
                    .catch((err) => {
                      errorMessage("Failed to delete pitch deck");
                    });
                }}
                className="text-grey700 px-6 py-2.5 border-primary600"
                icon={<FiDelete />}
              >
                Delete
              </Button>
              <Button
                size="large"
                onClick={() => {
                  setShareIsModalOpen(true);
                }}
                className="text-grey700 px-6 py-2.5 border-primary600"
                icon={<FiUpload />}
              >
                Share
              </Button>
              <Button
                size="large"
                onClick={() => {
                  setReplaceIsModalOpen(true);
                }}
                className="bg-primary600 text-white px-6 py-2.5"
                icon={<FiEdit />}
              >
                Upload
              </Button>
            </div>
          </div>

          <div
            className="mt-8"
            style={{
              height: "950px",
            }}
          >
            {!pitchDeckIsLoading && pitchDeckFile ? (
              <DocumentViewer
                url={pitchDeckFile?.file_path}
                viewer="url"
                viewerUrl={
                  "https://docs.google.com/gview?url=%URL%&embedded=true"
                }
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-2xl font-bold">No pitch deck uploaded yet</p>
              </div>
            )}
          </div>

          <div className=" mt-5"></div>
        </section>
      </div>

      <Modal
        centered
        title="Upload your Pitch Deck Again!"
        closeIcon={
          <IoClose className="size-6 absolute right-0 top-0 rounded-md bg-white text-black" />
        }
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={isReplaceModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="lg:!w-1/3 !w-full"
      >
        <div className="flex justify-center items-baseline my-7">
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white  xl:p-8 border rounded-xl">
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white  xl:p-8">
              <div className="flex items-center justify-center">
                <img src={UploadGif} alt="" className="size-20" />
              </div>
              <h3 className="mb-4 mt-2 text-lg font-semibold">
                Upload Your Pitch Deck
              </h3>
              <p className="font-light text-gray-500 sm:text-[13px] max-w-[400px]">
                Upload your pitch deck and let our AI do the heavy lifting by
                analyzing it for key insights.
              </p>
              <div className="flex justify-center items-baseline my-7 w-full ">
                <Upload {...props} className="w-full">
                  {/* <Button size='large' type='primary' className='!bg-primary' icon={<UploadOutlined />}>Click to Upload</Button> */}
                  <button className="flex w-full justify-center items-center gap-x-3 bg-primary600 text-[15px] text-white px-6 py-3 rounded-lg text-sm font-normal text-center">
                    <UploadIcon />
                    <p>Upload Pitch Deck</p>
                  </button>
                </Upload>
              </div>
            </div>
            
          </div>
        </div>
      </Modal>

      <Modal
        centered
        title="Share"
        closeIcon={
          <IoClose className="size-6 absolute right-0 top-0 rounded-md bg-white text-black" />
        }
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={isShareModalOpen}
        onOk={handleShareModalOk}
        onCancel={handleShareModalCancel}
        className="lg:!w-1/3 !w-full"
      >
        <div className="flex flex-col gap-y-3">
          <div className="w-full py-4 px-5 border border-[#E8E8E8] rounded-md shadow-sm shadow-black/10 ">
            <Form layout="vertical" className="w-full">
              <div className="flex gap-x-1 items-end w-full">
                <Form.Item
                  name="name_of_start_up"
                  className="w-full"
                  label="Share your pitch"
                  rules={[
                    {
                      required: false,
                      message: "Please input the name of your start-up!",
                    },
                  ]}
                >
                  <Input
                    addonBefore={<p>https://</p>}
                    placeholder="Share your pitch"
                    defaultValue={pitchDeckFile}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="text"
                    htmlType="submit"
                    className="bg-black text-white"
                  >
                    Copy Link
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PitchDeck;
