import React, { FC, useCallback, useState } from "react";
import { Alert, Progress } from "antd";
import type { TabsProps } from "antd";
import BusinesInfo from "./tabs/businesInfo";
import DemographicsInfo from "./tabs/demographicsInfo";
import { useAppSelector } from "../../lib/hooks";
import { RootState } from "../../lib/store";
import { shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculatePercentage } from "../../utils/calculatePercentage";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/Tabs";
import AiBlue from "../../assets/svg/ai-blue.svg";

interface IProps {}

export const Profile: FC<IProps> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user, shallowEqual);
  const startupData = useAppSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );

  const [activeKey, setActiveKey] = useState("1");

  const calculateNonEmptyPercentage = useCallback(() => {
    const val = calculatePercentage(user, startupData);
    return val;
  }, [startupData, user]);

  return (
    <div className="h-full overflow-y-scroll">
      {/*TITLE*/}
      <div
        style={{
          fontSize: 24,
          borderBottom: "1px solid #E9ECEF",
        }}
        className="lg:p-5 p-4"
      >
        Edit your information
      </div>

      <div className="lg:px-10 px-4 pt-4">
        {parseInt(calculateNonEmptyPercentage()) < 99 && (
          <div className="bg-[#E3F0FF] p-1 rounded-md flex items-center justify-between">
            <div className="flex items-center gap-x-3 pl-5">
              <img src={AiBlue} alt="" />
              <p className="text-sm text-primary">
                Complete your profile using our AI assistant. (Recommended)
              </p>
            </div>
            <button
              onClick={() => {
                navigate("/ai-complete-profile");
              }}
              className="flex items-center gap-x-2 bg-primary text-[15px] text-white px-5 py-3.5 rounded-md text-sm font-normal"
            >
              <p>
                {parseInt(calculateNonEmptyPercentage()) > 40
                  ? "Continue"
                  : "Get Started"}
              </p>
            </button>
          </div>
        )}

        <p className="text-[13px] mt-7 text-[#4A4A4A]">
          Please complete your profile. The more information you provide, the
          more personalized our recommendations
        </p>
        <div className="mt-5 mb-7">
          <Progress percent={parseInt(calculateNonEmptyPercentage())} />
        </div>
        {parseInt(calculateNonEmptyPercentage()) >= 90 && (
          <div className="mb-3">
            <Alert
              message="🎉 Your profile is sufficiently complete!"
              description={
                <div>
                  <p>
                    You can now explore personalized recommendations just for
                    you. Click the button below to check them out.
                  </p>
                  <p>
                    Alternatively, if you have any questions or need assistance,
                    feel free to ask our assistant anytime!
                  </p>
                  <div className="flex gap-3 mt-4">
                    <Link
                      className="bg-primary text-white rounded-lg px-3 py-1.5"
                      to="/recommendation"
                    >
                      Get personalized investor recommendation
                    </Link>
                    <Link
                      className="bg-primary text-white rounded-lg px-3 py-1.5"
                      to="/chat"
                    >
                      Get personalized support and advice
                    </Link>
                  </div>
                </div>
              }
              type="info"
              showIcon
              closable
              // onClose={() => setNavigateToAsk(false)}
            />
          </div>
        )}

        <Tabs defaultValue="Business">
          <TabsList className="gap-x-10">
            <TabsTrigger value="Business">Business Information</TabsTrigger>
            <TabsTrigger value="Demographic">
              Demographic Information
            </TabsTrigger>
          </TabsList>

          <TabsContent value="Business">
            {/* <BusinesInfo setActiveKey={setActiveKey} /> */}
          </TabsContent>
          <TabsContent value="Demographic">
            <DemographicsInfo
              complete={parseInt(calculateNonEmptyPercentage()) >= 90}
            />
          </TabsContent>
        </Tabs>

        {/* <Tabs defaultActiveKey="1" items={items} activeKey={activeKey} /> */}
      </div>
    </div>
  );
};
