import { Mutex } from "async-mutex";
import type { RootState } from "../store";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authenticate, unauthenticate } from "../features/auth/authSlice";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  // baseUrl: "http://127.0.0.1:8000",
  credentials: "same-origin",
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const accessToken: string | null =
      (getState() as RootState)?.auth.access ||
      localStorage.getItem("accessToken");
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  const state = api.getState() as RootState;
  const username = localStorage.getItem("COGNITO_USERNAME") || state.user.email;
  const refreshToken =
    localStorage.getItem("REFRESH_TOKEN") || state.auth.refresh;
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "/auth/refreshToken/",
            method: "POST",
            body: {
              username: username || "",
              refreshToken: refreshToken || "",
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(authenticate(refreshResult.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(unauthenticate());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};
