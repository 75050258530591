import React from "react";
import LandingLayout from "../Layout";
// import Inclusive from "../../../components/landing/blog/Inclusive";
import Vanguard from "../../../components/landing/blog/Vanguard";
import { Link } from "react-router-dom";

function BlogVanguard() {
  return (
    <LandingLayout>
      <section>
        <div className="bg-primary700 py-4 flex items-center justify-center gap-x-6">
          <Link
            to={"/blog/inclusive_gold_list"}
            className={`py-2.5 md:px-4 px-2 md:text-base text-sm rounded-lg transition-all text-white`}
          >
            Inclusive Gold List
          </Link>
          <Link
            to={"/blog/black_vanguard_list"}
            className={`py-2.5 md:px-4 px-2 md:text-base text-sm rounded-lg transition-all bg-primary50 text-primary700`}
            
          >
            The Black Vanguard List
          </Link>
        </div>

        <div className="mt-20">
          <Vanguard />
        </div>
      </section>
    </LandingLayout>
  );
}

export default BlogVanguard;
