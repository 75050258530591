import { FC } from "react";
import AILogo from "../../../assets/landingPage.png";
type receivedMessageProps = {
  message: string;
};

const ReceivedMessage: FC<receivedMessageProps> = ({ message }) => {
  return (
    <div className="flex justify-start my-2">
      <div className="max-w-lg px-4 flex flex-col py-2">
        <img src={AILogo} className="w-[40px] h-[40px]" alt="AI-logo" />
        <p className="text-[16px] text-gray-700">{message}</p>
      </div>
    </div>
  );
};

export default ReceivedMessage;
