// import { TutorialVideo } from '../../assets/video';
import Logo from "../../assets/logo.png";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function FirstTimeLogin() {
  const navigate = useNavigate();

  return (
    <div className="xl:px-[100px] px-10 py-6">
      <div>
        <img src={Logo} style={{ height: 32 }} alt={"logo"} />
      </div>
      <div className="h-[90%] flex justify-center items-center">
        <div className="xl:p-20 w-full mt-20">
          {/* <video
            controls
            autoPlay
            className="max-w-[1200px] mx-auto h-full rounded-3xl flex justify-center items-center"
            ref={videoRef}
          >
            <source src={"https://www.youtube.com/watch?v=UadpaeE3dfc"} type="video/mp4" />
          </video> */}
          <iframe
            width="100%"
            height="100%"
            className="max-w-[800px] mx-auto rounded-3xl flex justify-center items-center xl:h-[500px] h-[300px]"
            src="https://www.youtube.com/embed/UadpaeE3dfc"
            title="MyCofoundr.AI: Demo Video (GS - no music)"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>

          <div className="flex items-center justify-center w-full mt-5 gap-x-4">
            <Button
              type="primary"
              size="large"
              className="!bg-primary600"
              onClick={() => navigate("/cta")}
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstTimeLogin;
