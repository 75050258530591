import { useEffect, useMemo, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";
import Logo from "../assets/logo.png";
import AILogo from "../assets/Animations/AILogo.png";

const PitchDeckLoading = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const semanticNotif = useMemo(
    () => ["Extracting Data...", "Nearly Done...", "Finalizing..."],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => {
        if (prevStep >= semanticNotif.length - 1) {
          clearInterval(interval);
          return prevStep;
        }
        return prevStep + 1;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [semanticNotif.length]);

  return (
    <>
      <div className="fixed inset-0 z-50 bg-white"></div>
      <nav className="flex justify-between items-center p-4 bg-[#f0f5f3] z-[999] fixed top-0 left-0 w-full">
        <div className="pt-2 pb-1">
          <img className="h-8" src={Logo} alt="Logo" />
        </div>
      </nav>
      <div className="fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg md:max-w-xl translate-x-[-50%] translate-y-[-50%] gap-4 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
        <div className="p-4 flex flex-col gap-2 justify-center items-center">
          <img
            src={AILogo}
            className="w-[48px] h-[48px] fit-contain"
            alt="Loading_eligibility_questions"
          />
          <h2 className="text-[30px] font-semibold text-center text-gray-900 mb-4">
            Processing Pitch Deck...
          </h2>
          <p className="text-center text-[16px] text-gray-600 mb-4">
            We’re reviewing your pitch deck file to extract data.
          </p>
          <div className="w-full max-w-lg mx-auto space-y-1 p-4 border-gray-300">
            {semanticNotif.map((text, index) => {
              return (
                index <= currentStep && (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 rounded-md"
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center space-x-4">
                        <div
                          className={`flex-shrink-0 w-6 h-6 bg-[#E0EBE7]  rounded-full flex items-center justify-center ${
                            index < currentStep ? "" : "animate-pulse"
                          } `}
                        >
                          {index < currentStep && (
                            <GiCheckMark className="w-4 h-4 text-primary" />
                          )}
                        </div>
                        {index <= currentStep ? (
                          <span className="text-[16px] text-gray-600">
                            {text}
                          </span>
                        ) : (
                          <div className="h-4 w-64 bg-bg-[#E0EBE7] animate-pulse rounded-full" />
                        )}
                      </div>

                      <FaChevronDown className="w-4 h-4 text-gray-500" />
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PitchDeckLoading;
