import T5a from "../../../assets/blog/25a.png";
import T5b from "../../../assets/blog/25b.png";
import T5c from "../../../assets/blog/25c.png";
import T4 from "../../../assets/blog/24.png";
import T3 from "../../../assets/blog/23.png";
import T2a from "../../../assets/blog/22a.png";
import T2b from "../../../assets/blog/22b.png";
import T1a from "../../../assets/blog/21a.png";
import T1b from "../../../assets/blog/21b.png";
import T0a from "../../../assets/blog/20a.png";
import T0b from "../../../assets/blog/20b.png";
import O9 from "../../../assets/blog/19.png";
import O8 from "../../../assets/blog/18.png";
import O7a from "../../../assets/blog/17a.png";
import O7b from "../../../assets/blog/17b.png";
import O6 from "../../../assets/blog/16.png";
import O5 from "../../../assets/blog/15.png";
import O4a from "../../../assets/blog/14a.png";
import O4b from "../../../assets/blog/14b.png";
import O3a from "../../../assets/blog/13a.png";
import O3b from "../../../assets/blog/13b.png";
import O2a from "../../../assets/blog/12a.png";
import O2b from "../../../assets/blog/12b.png";
import O1a from "../../../assets/blog/11a.png";
import O1b from "../../../assets/blog/11b.png";
import N0 from "../../../assets/blog/10.png";
import N9a from "../../../assets/blog/09a.png";
import N9b from "../../../assets/blog/09b.png";
import N9c from "../../../assets/blog/09c.png";
import N9d from "../../../assets/blog/09d.png";
import N9e from "../../../assets/blog/09e.png";
import N9f from "../../../assets/blog/09f.png";
import N8 from "../../../assets/blog/08.png";
import N7a from "../../../assets/blog/07a.png";
import N7b from "../../../assets/blog/07b.png";
import N6a from "../../../assets/blog/06a.png";
import N6b from "../../../assets/blog/06b.png";
import N5 from "../../../assets/blog/05.jpg";
import N4a from "../../../assets/blog/04a.png";
import N4b from "../../../assets/blog/04b.png";
import N3 from "../../../assets/blog/03.png";
import N2 from "../../../assets/blog/02.png";
import N1 from "../../../assets/blog/01.png";

export const vanguards = [
  {
    id: 1,
    title: "25. 360 Venture Collective: Miami’s Venture Capital Powerhouse",
    additional: 'In Miami’s vibrant tech scene, 360 Venture Collective is redefining venture capital with bold investments and an unapologetically inclusive approach. Since its founding in 2021, this woman-led firm has become a beacon for under-networked founders, focusing on fintech, health-tech, prop-tech, and the future of work. By specializing in pre-seed and seed-stage investments ranging from $50K to $500K, 360 Venture Collective is proving that backing underestimated founders drives innovation and delivers stellar returns.',
    investors: [
      {
        name: "Kelly O’Connell: The Architect of Opportunity",
        image: T5a,
        description: (
          <p>
            When it comes to redefining what’s possible in venture capital, Kelly O’Connell is leading the charge. As Managing Partner of 360 Venture Collective, Kelly has made it her mission to back under-networked founders in fintech, health-tech, and prop-tech, proving that great ideas don’t just come from well-trodden paths. With a keen eye for talent and a knack for turning underdogs into industry disruptors, Kelly is rewriting the rulebook.
            <br /> <br />
            But her vision goes beyond startups—she’s reshaping the entire VC ecosystem. From diversifying her firm’s LP base to mentoring founders through the toughest scaling challenges, Kelly is showing the world that inclusion isn’t just good for business—it’s essential for innovation.
          </p>
        ),
      },
      {
        name: "Ann Metzger: The Miami Maverick",
        image: T5b,
        description: (
          <p>
            Ann Metzger is making waves in Miami’s sizzling startup scene as General Partner at 360 Venture Collective. Her focus? Giving historically excluded founders the chance to shine in fintech, health-tech, and the future of work. With her belief that diverse perspectives fuel groundbreaking solutions, Ann doesn’t just invest in companies—she invests in people.
            <br />
            <br />
            Ann is also laser-focused on breaking barriers for investors, opening doors for LPs who share her vision for equity. At 360 Venture Collective, she’s proving that backing bold founders and bold ideas is more than a strategy—it’s a revolution.
          </p>
        ),
      },
      {
        name: "Shelley Iocona: The Tech Trailblazer",
        image: T5c,
        description: (
          <p>
            Known as “Techgirl” among her peers, Shelley Iocona brings a unique mix of product design, coding, and business acumen to her role as General Partner at 360 Venture Collective. Shelley is passionate about helping startups turn big ideas into impactful, scalable solutions. With a background spanning Fortune 500s and early-stage startups, she’s the ally every founder needs to take their tech to the next level.
            <br />
            <br />
            From building sustainable products to creating responsible tech solutions, Shelley’s work ensures that 360 Venture Collective’s portfolio companies aren’t just innovative—they’re transformational.
          </p>
        ),
      },
    ],
    double: true,
  },
  {
    id: 2,
    title: "24. Shila Nieves Burney: The Southern Strategist",
    image: T4,
    description: (
      <p>
        In the heart of Atlanta, Shila Nieves Burney is proving that the future
        of tech isn't just in Silicon Valley. As the founding partner of Zane
        Venture Fund, Burney is channeling capital to under-networked founders
        in the Southeast and beyond. <br />
        <br /> With over two decades of experience spanning nonprofits, public
        health, and education, Burney brings a unique perspective to venture
        capital. Her fund isn't just writing checks; it's closing gaps. Through
        Zane Access, a nonprofit arm of her venture, Burney has helped 67
        companies secure over $13 million in funding. <br />
        <br /> A sought-after speaker and industry thought leader, Burney is
        more than an investor—she's driving systemic change, building wealth in
        marginalized communities and empowering the next generation of diverse
        entrepreneurs.
      </p>
    ),
    double: false,
  },
  {
    id: 3,
    title: "23. Kimmy Paluch: The Hands-On Mentor",
    image: T3,
    description: (
      <p>
        Kimmy Paluch is the Jamaican-born, MIT-educated powerhouse who's not
        just accelerating startups—she's catapulting entire communities into the
        future. This Dartmouth computer science and Spanish double threat who
        has walked the entrepreneurial path herself. From creating award-winning
        products for TiVo and LeapFrog to founding Beta Boom, with assets under
        management of $14.5 million to $25 million, Kimmy's journey is a
        testament to the power of perspective. At Beta Boom, she's created a
        launchpad for the next wave of diverse founders. Her mission? To prove
        that innovation knows no gender, color, or zip code. Recognized as a VC
        Journal Woman of Influence and an Impact Magazine Woman of Impact, Kimmy
        isn't just collecting accolades; she's collecting allies in the fight
        for equity. In Kimmy's world, every startup success is a win for
        diversity.
      </p>
    ),
    double: false,
  },
  {
    id: 4,
    title: "22. The BK-XL Builders: Redefining Brooklyn's Startup Ecosystem",
    investors: [
      {
        name: "Timi Dayo-Kayode: The Kinfolk Connector",
        image: T2a,
        description: (
          <p>
            Meet Timi Dayo-Kayode, the co-lead investor at BK-XL who's not just
            writing checks—he's rewriting the entire playbook for startup
            success in Brooklyn. This Tufts alum isn't playing small; he's on a
            mission to turn $125,000 investments into empires, proving that when
            you bet on diverse talent, everybody wins. <br /> <br />
            From founding Kinfolq to managing African investments at Roundtrip,
            Timi's journey is a masterclass in hustle and heart. At BK-XL, he's
            not just investing in companies; he's helping to realize dreams,
            ensuring that under-networked founders get more than just
            capital—they get a shot at greatness. Recognized with the
            Outstanding Young American Award, Timi isn't just collecting
            accolades; he's collecting allies in the fight for equity. In Timi's
            world, every investment is a step towards a more inclusive tech
            ecosystem.
          </p>
        ),
      },
      {
        name: "Christopher Martinez: The Brooklyn Bridge Builder",
        image: T2b,
        description: (
          <p>
            In the borough that gave us Jay-Z and Biggie, Christopher Martinez
            is dropping beats of a different kind—venture capital. As the
            co-lead of BK-XL Chris is not just accelerating startups—he's
            catapulting entire communities into the future. Chris isn't here to
            play; he's here to slay the status quo in VC. From VP seats at top
            investment firms to the beating heart of Brooklyn's startup scene,
            Christopher's journey is a testament to the power of perspective. At
            BK-XL, he's not just offering $125,000 for 7% equity; he's offering
            a lifeline to founders who've been historically shown the door
            instead of the term sheet. 
            <br />
            <br />
            At BK-XL, Timi Dayo-Kayode and Christopher Martinez are changing the
            game. In a world of followers, they're the leaders we've been
            waiting for, proving that when inclusion meets innovation, magic
            happens. 
          </p>
        ),
      },
    ],
    double: true,
  },
  {
    id: 5,
    title: "21. The Hearst Trailblazers: Carlton Charles and Shashi Srikantan",
    investors: [
      {
        name: "Carlton Charles: The Corporate Visionary",
        image: T1a,
        description: (
          <p>
            Carlton Charles is the Chairman of Level Up Ventures. He is also the
            Senior Vice President of Treasury and Risk Management at Hearst and
            a Member of Hearst’s Board of Directors. Hearst is one of the
            nation’s largest global, diversified information, services and media
            companies with over $11 billion in revenue, a portfolio of more than
            360 businesses, and a commitment to innovation through strategic
            investments in technology and diverse startups via its Level Up
            Ventures initiative.
            <br /> <br />
            With over two decades of experience in corporate finance, Carlton
            brings a unique perspective to venture investing. His commitment to
            diversity in tech isn't just a side project—it's a core part of his
            vision for a more inclusive and innovative future.
          </p>
        ),
      },
      {
        name: "Shashi Srikantan: The Innovation Catalyst",
        image: T1b,
        description: (
          <p>
            Meet Shashi Srikantan, the dynamic Managing Director of Hearst's
            Level Up Ventures. This Columbia Business School MBA isn't just
            investing in startups; she's on a mission to identify and nurture
            early-stage companies that are led by diverse founders who are
            pushing the boundaries of innovation. Her focus on diverse founders
            isn't just talk—it's baked into the DNA of Level Up's investment
            strategy. Shashi's not just writing checks; she's building bridges
            between a corporate giant and cutting-edge tech, proving that when
            you bet on diverse talent, everybody wins. <br />
            <br />
            Together, Carlton and Shashi are proving that corporate venture
            capital can be a powerful force for diversity and innovation.
            They're not just changing the face of corporate investment; they're
            giving it a whole new heartbeat.
          </p>
        ),
      },
    ],
    double: true,
  },
  {
    id: 6,
    title:
      "20. The Morgan Stanley Mavericks: Trailblazing a New Era of Inclusive Finance",
    additional:
      "Alice Vilma and LaToya Wilson are more than finance leaders—they are the architects of a new, inclusive financial frontier. Through Morgan Stanley's groundbreaking initiatives, these two powerhouses are ensuring that under-networked founders don’t just get a seat at the table—they get the funding, mentorship, and resources to build their own tables. ",
    investors: [
      {
        name: "Alice Vilma: The Next Level Visionary",
        image: T0a,
        description: (
          <p>
            Alice Vilma isn’t merely navigating the world of venture capital;
            she’s reshaping it. As the Co-Head and Co-Portfolio Manager of
            Morgan Stanley’s Next Level Fund, Alice is driving the future of
            inclusive investing. The $50 million fund is focused on early-stage
            companies led by under-networked founders, proving that innovation
            thrives when diverse talent is empowered. <br />
            Her portfolio spans industries from fintech to consumer tech,
            amplifying voices that traditional finance has long overlooked.
            Alice’s approach blends Wall Street acumen with an unwavering
            commitment to leveling the playing field, showing that inclusion is
            not just ethical—it’s strategically essential.  
          </p>
        ),
      },
      {
        name: "LaToya Wilson: The Inclusive Ventures Architect",
        image: T0b,
        description: (
          <>
            <p>
              LaToya Wilson is building more than startups—she’s building an
              entire ecosystem of opportunity. As the Americas Head of Morgan
              Stanley’s Inclusive Ventures Group, LaToya leads the firm’s
              in-house accelerator, designed to supercharge diverse-led
              startups. With over 20 years of investment experience, her career
              is a testament to the power of purpose-driven finance. <br />
              <br /> Under her leadership, the Inclusive Ventures Lab has
              accelerated 117 companies since 2017, providing each participant
              with a $250,000 investment and a wealth of strategic resources.
              The 2024 cohort, the largest yet, welcomed 25 startups spanning
              nine countries, including Ghana, Saudi Arabia, and Uruguay. <br />
              <br />
              LaToya’s dedication doesn’t stop in the boardroom. As a Girl
              Scouts troop leader, she’s fostering the next generation of
              fearless female leaders, proving that true change begins with
              early empowerment.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                Key Milestones and Metrics
              </h3>
              <ul className="list-disc mt-4 pl-7 text-xl">
                <li>
                  Next Level Fund: $50 million raised, focusing on early-stage
                  companies led by under-networked founders
                </li>
                <li>
                  Inclusive Ventures Lab: 117 companies accelerated since 2017
                </li>
                <li>Largest cohort: 25 startups in 2024</li>
                <li>
                  Investment: $250,000 for each Inclusive Ventures Lab
                  participant
                </li>
                <li>Global Reach: Portfolio spans nine countries</li>
                <li>
                  Corporate Partners: Collaborations with Hearst, Microsoft,
                  Walmart and Altria 
                </li>
              </ul>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                A New Era in Finance
              </h3>
              <p>
                Morgan Stanley isn’t just participating in the conversation on
                levelling the playing field—they’re leading it. With a bold goal
                to accelerate 1,000 diverse CEOs by 2026, the firm is proving
                that inclusion is not a trend—it’s the future of finance. <br />
                <br /> Alice Vilma and LaToya Wilson are driving this mission
                with conviction, showing that when diverse founders are equipped
                with the right tools and capital, everyone wins. Their work is
                more than innovation; it’s a blueprint for equity, empowerment,
                and excellence.
              </p>
            </div>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 7,
    title: "19. Elizabeth Galbut: The Millennial Mogul",
    image: O9,
    description: (
      <p>
        Elizabeth Galbut isn't waiting for a seat at the table; she's building
        her own. As the co-founder of SoGal Ventures, the first female-led
        millennial VC firm, Galbut is reshaping the landscape. <br />
        <br /> With a portfolio boasting over 150 companies, including
        heavyweights like Everlywell (which has raised $325 million) and
        Function of Beauty (which has raised $248 million), Galbut's eye for
        innovation is matched only by her commitment to inclusivity. Her secret
        weapon? A unique blend of design thinking and financial acumen, honed at
        institutions from Johns Hopkins to the London School of Economics.{" "}
        <br />
        <br /> Armed with degrees from Johns Hopkins, Georgetown, and LSE, her
        early days at Deloitte laid the groundwork for a VC career dedicated to
        leveling the playing field for women and minority founders. From gracing
        Forbes 30 Under 30 to driving global change through the SoGal
        Foundation, Elizabeth’s proving that diverse investments aren’t just
        ethical—they’re essential.
      </p>
    ),
    double: false,
  },
  {
    id: 8,
    title: "18. Ivan Alo: The Brooklyn Disruptor",
    image: O8,
    description: (
      <p>
        Brooklyn-based and boundary-breaking, Ivan Alo is the badass who's not
        just writing checks—he's rewriting the entire playbook for startup
        success. This Boston College finance whiz isn't playing small; he's on a
        mission to turn that measly 1% of VC funding for Black and Latino
        founders into yesterday's news. <br />
        <br />
        From TIAA-CREF to New Age Capital, Ivan's journey is a masterclass in
        hustle and heart. At New Age, he's not just investing in companies; he's
        investing in dreams, proving that when you bet on under-networked
        talent, everybody wins. New Age Capital raised $25 million for its debut
        fund and focuses on seed-stage tech companies founded by Black and
        Hispanic entrepreneurs. New Age Capital typically invests around
        $900,000 per deal, aiming for 10-15% ownership in seed rounds between
        $1.5 million and $2.5 million. <br />
        <br />
        Ivan’s impact hasn't gone unnoticed—Forbes recognized him in their
        prestigious 30 Under 30 list, cementing his status as a rising star in
        the VC world. His secret sauce? A potent blend of Wall Street smarts,
        Brooklyn grit, and a deep-rooted belief that innovation knows no color.
      </p>
    ),
    double: false,
  },
  {
    id: 9,
    title:
      "17. The Aperture Architects: Pioneering Inclusive Innovation in Venture Capital",
    investors: [
      {
        name: "William Crowder: The Bi-Coastal VC Virtuoso",
        image: O7a,
        description: (
          <p>
            William Crowder is a force of nature who isn’t just opening doors
            for diverse founders—he’s tearing down walls. A Duke MBA and NC
            State computer science graduate, William approaches inclusive
            innovation like a chess master, always several steps ahead. From
            coast to coast, his career has been a blueprint for disruption.{" "}
            <br /> <br />
            As the visionary behind Comcast Ventures Catalyst Fund and now the
            $75 million Aperture Venture Capital, William’s portfolio of over 70
            investments includes standout successes like Squire, Flutterwave and
            Partpic (acquired by Amazon). But he’s not here to check boxes; he’s
            here to cash checks and deliver returns. For William, investing in
            diverse founders isn’t charity—it’s smart business. <br />
            <br />
            At Aperture, William blends his razor-sharp tech background with an
            unwavering commitment to equity, proving that every dollar invested
            in diversity fuels a stronger, more innovative ecosystem. From
            Philadelphia to Los Angeles, he’s not just reshaping venture
            capital—he's giving it a whole new pulse. 
          </p>
        ),
      },
      {
        name: "Garnet Heraman: The Multicultural Maverick",
        image: O7b,
        description: (
          <>
            <p>
              Garnet Heraman is a venture visionary whose global perspective
              fuels a mission to dismantle borders in innovation and funding. A
              native of Trinidad & Tobago with degrees from Columbia University,
              NYU, and the London School of Economics, Garnet could have taken
              the well-worn path of tradition. Instead, he forged his own,
              founding multiple startups and orchestrating two successful exits,
              including one to a public company. <br />
              <br />
              At Aperture Venture Capital, Garnet’s passion is palpable: backing
              under-networked founders and multicultural startups isn’t just a
              personal mission—it’s an economic imperative. He’s not just
              investing in companies; he’s ensuring Black and minority
              entrepreneurs have the funding and support to thrive. His
              influence extends beyond the boardroom. As chair of FUEL
              OZ.Capital, Garnet cultivates ecosystems that transform overlooked
              communities into vibrant hubs of entrepreneurial energy. Through
              his roles at the Brookings Institute and Columbia Venture
              Community, Garnet amplifies the voices of those too often left
              unheard. From Time Magazine to Bloomberg, his impact is
              recognized, respected, and rising.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                A Revolution in the Making
              </h3>
              <p>
                Together, William Crowder and Garnet Heraman are the Aperture
                Architects, visionaries who believe that where challenges exist,
                opportunity thrives. They’re not just investing in
                startups—they’re investing in a future where innovation reflects
                the diversity of the world it serves. 
                <br />
                <br />
                In an industry at a crossroads, William and Garnet remind us
                that true progress comes not from exclusivity, but from
                inclusivity. The Aperture revolution is here, and it’s making
                waves from Silicon Valley to every overlooked corner of
                innovation. Watch closely—the future they’re building is one
                where everyone rises.
              </p>
            </div>
          </>
        ),
      },
    ],
    additional:
      "In an industry too often defined by exclusivity, William Crowder and Garnet Heraman of Aperture Venture Capital are leading a revolution—expanding access, amplifying voices, and proving that the future of innovation is diverse, dynamic, and inclusive. These trailblazers are making sure the doors they open stay wide enough for others to walk through.",
    double: true,
  },
  {
    id: 10,
    title: "16. Jackson Cummings: The Access Advocate",
    image: O6,
    description: (
      <p>
        Jackson Cummings isn’t here to make statements about diversity—he’s here
        to fund it with a nine-figure resolve that demands attention. As the
        Head of Wellington Access Ventures (WAV), this Stanford alum is
        disrupting venture capital, proving that while talent is universal,
        opportunity still needs a champion. And Jackson is that champion. <br />
        <br />
        Armed with a $150 million war chest, Jackson isn’t just opening doors
        for diverse founders—he’s knocking down entire walls. At WAV, he’s
        writing checks of $1-6 million to early-stage startups led by those
        who’ve historically been sidelined. But make no mistake: this isn’t
        charity. It’s smart business, powered by the trillion-dollar force of
        Wellington Management.
        <br />
        <br />
        Jackson’s influence doesn’t stop at WAV’s portfolio. As a board member
        of BLCK VC and co-creator of the Black Venture Institute, he’s funding
        the next generation of diverse founders and cultivating the next wave of
        Black VCs. His mission? To dismantle the systemic barriers that have
        held tech back for too long. It’s a one-two punch that lands hard and
        reshapes the industry.
        <br />
        <br />
        From the football field to his days at Salesforce Ventures, Jackson has
        always played to win. Now, he’s scoring touchdowns in the high-stakes
        game of venture capital, showing that when you bet on diverse talent,
        everybody wins. In his world, every investment is a step toward a more
        equitable, innovative tech ecosystem.
        <br />
        <br />
        Watch out, Sand Hill Road—Jackson and WAV aren’t just participating in
        the game; they’re changing it. This is more than venture capital; it’s a
        revolution in access, opportunity, and returns. And in Jackson’s game,
        everyone is invited to play. 
      </p>
    ),
    double: false,
  },
  {
    id: 11,
    title: "15. Brian Brackeen: The Bias-Busting VC Visionary",
    image: O5,
    description: (
      <p>
        Brian Brackeen isn’t just funding startups—he’s funding a movement to
        unlock overlooked potential. As the General Partner at Lightship
        Capital, Brian is wielding a $50 million fund like a wrecking ball,
        shattering the barriers that have kept women and minority-led startups
        on the sidelines. In a world that talks about diversity, Brian doesn’t
        just talk—he invests, he builds, he disrupts, and he delivers. <br />
        <br />
        His resume reads like a Silicon Valley highlight reel: Apple, IBM, and
        his own groundbreaking venture, Kairos, a facial recognition company
        where he took a bold stand against algorithmic bias. Now, at Lightship,
        Brian’s flipping the script, turning those biases into billion-dollar
        opportunities. He knows that when you bet on overlooked talent, the
        return isn’t just financial—it’s transformational. <br />
        <br />
        But Brian’s vision goes beyond the checkbook. As a co-owner of Black
        Tech Week, he’s building platforms where Black innovators can showcase
        brilliance, access resources, and scale their dreams. Through mentorship
        programs with Girls Who Code and Black Girls Code, he’s sowing seeds for
        a future where diversity isn’t an exception—it’s the expectation. <br />
        <br />
        In a world of coastal elites, Brian is betting on the Midwest, proving
        that the next big thing doesn't need a zip code to change the world.
        From the heartland, he’s giving venture capital a new face and a new
        heartbeat—one that pulses with the energy of untapped potential and
        unstoppable ambition. In this game of startup chess, he’s not just
        moving pieces; he’s flipping the whole board. 
      </p>
    ),
    double: false,
  },
  {
    id: 12,
    title: "14. Collide Capital: Building the Future of Venture in Brooklyn",
    investors: [
      {
        name: "Brian Hollins: The Brooklyn Bridge-Builder",
        image: O4a,
        description: (
          <p>
            Brian Hollins is turning Brooklyn into the new epicenter of diverse
            entrepreneurship. This Stanford and Harvard Business School alum
            isn't playing small; he's on a mission to prove that the next big
            thing in tech could come from anywhere—and anyone. <br />
            <br />
            From his six-year stint at Goldman Sachs to launching initiatives
            like the Emerging Entrepreneurs Coverage group, Brian's career reads
            like a masterclass in disrupting the VC status quo. At Collide
            Capital, he's not just writing checks; he's creating pathways for
            diverse talent to thrive, proving that inclusion and innovation go
            hand in hand. <br />
            <br />
            But Brian's not content with personal success. As the founder of The
            Takeoff Institute and a board member of BLCK VC, he's building a
            pipeline of diverse talent that's set to reshape the face of venture
            capital. In Brian's world, every investment is a step towards a more
            equitable tech ecosystem.
          </p>
        ),
      },
      {
        name: "Aaron Samuels: The Renaissance Investor",
        image: O4b,
        description: (
          <p>
            Aaron Samuels is a corporate strategist turned media mogul and
            spoken word artist who's redefining what it means to be a VC. As the
            founder and managing partner of Collide Capital, Aaron isn't just
            funding startups; he's fueling a movement. <br />
            <br />
            From co-founding Blavity to being a driving force behind AfroTech,
            Aaron's career is a testament to the power of amplifying Black
            voices in tech. At Collide Capital, he's not just looking for great
            ideas; he's seeking out companies that tackle systemic barriers
            faced by under-networked communities. <br />
            <br />
            But Aaron's impact goes beyond the boardroom. As a nationally
            acclaimed spoken word artist, he's using the power of storytelling
            to change the narrative around diversity in tech. In Aaron's world,
            every pitch is a performance, and every investment is a step towards
            a more inclusive future. <br />
            <br />
            Together, Brian and Aaron are the Collide Trailblazers, building a
            future where innovation is as diverse as Brooklyn itself. They're
            not just changing the face of VC; they're giving it a whole new
            heartbeat, right in the center of one of New York's most vibrant
            boroughs.
          </p>
        ),
      },
    ],
    additional:
      "Brian Hollins and Aaron Samuels are the dynamic duo behind Collide Capital, who aren't just investing in startups—they're igniting a revolution in venture capital right from the heart of Brooklyn. Collide Capital is a VC firm that they founded that manages approximately $66 million in assets under management (AUM). The firm focuses on early-stage investments in sectors such as enterprise SaaS, supply chain infrastructure, and consumer software across the United States. Notably, Collide Capital is Black-owned and led by two founders under the age of 35: Aaron co-founded AfroTech, and Brian is a founding Board Member of BLCK VC.",
    double: true,
  },
  {
    id: 13,
    title:
      "13. The Collab Capital Changemakers: Forging New Paths in Venture Capital",
    investors: [
      {
        name: "Jewel Burks Solomon: The Tech Titan Turned VC Visionary",
        image: O3a,
        description: (
          <p>
            Jewel Burks Solomon isn’t just writing checks—she’s redefining Black
            entrepreneurship. As a driving force behind Collab Capital’s $51
            million fund, Jewel has invested in 36 Black-led companies, proving
            that when you bet on Black brilliance, the returns are exponential.{" "}
            <br />
            <br />
            Before she was backing founders, Jewel was building empires of her
            own. Her brainchild, Partpic, wasn’t just a startup; it was a
            computer vision revolution that caught Amazon’s eye and led to a
            successful acquisition. At Amazon, she launched PartFinder, turning
            every smartphone into a maintenance powerhouse. <br />
            <br />
            But Jewel’s vision is bigger than personal success. As the former
            Head of Google for Startups U.S., she helped to unleash $50 million
            in non-dilutive funding for under-networked founders. Now, as a
            Henry Crown Fellow, she’s not just sitting at the table—she’s
            expanding it for diverse voices to take their rightful place. <br />
            <br />
            In a world full of talk, Jewel is a doer, showing that when Black
            founders get the funding they deserve, innovation knows no limits.
          </p>
        ),
      },
      {
        name: "Barry Givens: The Engineer of Equity",
        image: O3b,
        description: (
          <>
            <p>
              Barry Givens is an architect of opportunity, a mechanical engineer
              turned venture capital visionary. At the helm of Collab Capital’s
              $51 million fund, Barry isn’t just opening doors for Black
              entrepreneurs—he’s demolishing walls. He’s not playing checkers;
              he’s playing 3D chess in the game of Black wealth creation. <br />
              <br />
              This Georgia Tech alum doesn’t just dream—he builds. His first
              venture, Monsieur, a robotic bartender, wasn’t just a novelty; it
              was a $4 million showcase of Black ingenuity that had sports
              arenas and luxury hotels lining up to toast his innovation. <br />{" "}
              <br />
              But Barry didn’t stop at mixing drinks. He pivoted to mixing
              capital and purpose, co-founding Collab Capital to fix a system
              that was more broken than a bartender’s corkscrew on New Year’s
              Eve. With 36 Black-led startups in his portfolio, Barry is
              crafting a blueprint for Black economic empowerment that future
              generations will follow.
              <br />
              <br />
              From Techstars to CNN, from TechCrunch to Invest Atlanta’s
              boardroom, Barry Givens is the name that turns talk about
              diversity into cold, hard capital. He’s leading the charge,
              proving that when you invest in Black excellence, the returns are
              nothing short of revolutionary.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                A Future Supercharged{" "}
              </h3>
              <p>
                Together, Jewel and Barry are the Collab Capital
                Changemakers—trailblazers who believe that Black
                entrepreneurship doesn’t just need support; it needs to be
                supercharged. From their base in Atlanta, they’re redefining the
                face—and the heartbeat—of venture capital.
              </p>
            </div>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 14,
    title: "12. The Slauson Strategists: Transforming LA's Tech Landscape",
    investors: [
      {
        name: "Ajay Relan: The Neighborhood Alchemist",
        image: O2a,
        description: (
          <p>
            Ajay Relan isn't just investing in startups; he's investing in
            neighborhoods. As the Managing Partner of Slauson & Co., this Los
            Angeles native is turning overlooked communities into hotbeds of
            innovation. Ajay is proving that betting on diverse founders isn't
            just good karma—it's good business. His philosophy? Talent may be
            evenly distributed, but opportunity isn't. That's where Slauson &
            Co. comes in. <br />
            <br />
            From his days at Queensbridge Venture Partners to founding
            #HashtagLunchbag, Ajay has always had one foot in finance and the
            other in community service. Now, he's creating “Slauson Avenues”
            across America, transforming underserved areas into thriving
            entrepreneurial ecosystems.
          </p>
        ),
      },
      {
        name: "Austin Clements: The Equity Architect",
        image: O2b,
        description: (
          <p>
            Austin Clements isn't just talking about diversity in tech—he's
            building it from the ground up. As the Co-founder and Managing
            Partner of Slauson & Co., this Morehouse and NYU Stern alum is
            redefining what it means to be a VC in the 21st century. With a
            career spanning from AllianceBernstein to TenOneTen Ventures, Austin
            has seen firsthand the barriers facing under-networked founders.
            Now, he's tearing those barriers down. Through initiatives like the
            Friends & Family Accelerator, he's not just writing checks—he's
            creating a new generation of tech moguls. But Austin's impact
            extends far beyond Slauson & Co. As the founding Chair of PledgeLA,
            he's holding the entire tech industry accountable, ensuring that
            diversity isn't just a buzzword—it's a benchmark. <br />
            <br />
            Together, Ajay and Austin are the Slauson Strategists, building a
            future where innovation thrives in every neighborhood. They're not
            just changing the face of VC; they're giving it a whole new
            heartbeat, right in the center of Los Angeles.
          </p>
        ),
      },
    ],
    additional:
      "Ajay Relan and Austin Clements are the visionary duo behind Slauson & Co., who aren't just investing in startups—they're reimagining entire communities as launchpads for innovation. Slauson & Co. is a Los Angeles-based VC firm founded in 2020 by Ajay and Austin with assets under management (AUM) estimated between $50 million and $99 million. They are raising a $100 million Fund II. The firm focuses on early-stage investments in SMB SaaS and culturally aligned consumer technology, aiming to democratize access to entrepreneurship for historically under-networked founders. Notably, Slauson & Co. is backed by influential tech companies such as Google, Meta, Twitter and PayPal.",
    double: true,
  },
  {
    id: 15,
    title: "11. The GV Trailblazers: Elevating Diversity in Venture Capital",
    investors: [
      {
        name: "Candice Morgan: The Diversity Dynamo",
        image: O1a,
        description: (
          <p>
            Candice Morgan is a trailblazer in venture capital, serving as GV's
            first Equity, Diversity & Inclusion Partner. With GV managing over
            $10 billion in assets and a portfolio of 400 active companies across
            North America and Europe, Candice is directing substantial
            investments toward diverse-led startups. 
            <br />
            <br />  Before joining GV, Candice made waves at Pinterest,
            increasing under-networked talent from 3% to 10%. At GV, she boosted
            funding for diverse founders by an incredible 50% in her first year.
            With average check sizes ranging from $4.2 million for seed
            investments to $28.6 million for Series A, she’s proving that
            diversity pays dividends.  
            <br />
            <br />
            Candice doesn’t stop at writing checks—she’s coaching CEOs on
            diverse board recruitment and shaping conversations in platforms
            like the Harvard Business Review. In her world, diversity isn’t a
            buzzword; it’s the secret sauce for innovation.
          </p>
        ),
      },
      {
        name: "KJ Sidberry: The Consumer Tech Visionary",
        image: O1b,
        description: (
          <>
            <p>
              KJ Sidberry brings a powerful blend of neurobiology and computer
              science from Harvard to GV, where he’s reshaping the consumer tech
              landscape. With GV’s vast resources, KJ is backing diverse
              founders and helping build the next generation of breakout
              companies like Oura and Alkeme.  
              <br />
              <br />
              KJ isn’t just following trends; he’s creating them. His
              investments are designed to fix systemic “glitches” and deliver
              smarter, more inclusive tech. A champion of Black representation
              in VC, KJ proves that diverse perspectives lead to better
              investments and stronger returns.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                Pioneering Inclusive Innovation
              </h3>
              <p>
                Together, Candice and KJ are driving GV’s mission to integrate
                diversity at the core of venture capital. They’re making sure
                that GV’s $10 billion fund doesn’t just support innovation—it
                supports an innovation ecosystem that looks like the world we
                live in.
              </p>
            </div>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 16,
    title: "10. Kofi Ampadu: The Inclusion Igniter",
    image: N0,
    description: (
      <p>
        Kofi Ampadu isn’t just unlocking opportunities for under-networked
        founders—he’s laying down superhighways of possibility. As the architect
        of Andreessen Horowitz's TxO Initiative, this Carnegie Mellon MBA is
        transforming the VC landscape. <br />
        <br />
        Kofi’s journey—from his roots in Ghana to the epicenter of Silicon
        Valley—is a testament to resilience, vision, and grit. At a16z, he’s
        more than just a partner; he’s a builder of futures. Through the TxO
        accelerator, he’s turning raw potential into tech powerhouses. Fifty
        startups and counting—each equipped with $175,000 in funding and a
        16-week crash course in scaling success.
        <br />
        <br />
        But for Kofi, it’s never just about the numbers; it’s about changing the
        narrative. He’s proving that entrepreneurship isn’t bound by zip codes,
        accents, or color lines. Innovation is universal, and Kofi is ensuring
        that access to opportunity reflects that truth.
        <br />
        <br />
        Before his game-changing work at a16z, Kofi was already making waves. As
        the founder of SKU'd Ventures, he was shaking up the CPG world. At
        ForbesBLK, he was amplifying the voices and visions of Black innovators.
        Now, he’s the bridge between bold ideas and billion-dollar outcomes,
        transforming "under-networked" into unstoppable.
        <br />
        <br />
        In Kofi’s blueprint, diversity isn’t a corporate talking point—it’s the
        engine of success. He’s nurturing a community where today’s mentees
        evolve into tomorrow’s mentors, sparking a ripple effect of Black
        excellence that reverberates across tech.
        <br />
        <br />
        Kofi Ampadu isn’t just giving VC a new face—he’s infusing it with a new
        spirit. In a world of gatekeepers, he’s the gatebreaker, showing us that
        when you invest in overlooked talent, the wins are limitless. The future
        isn’t coming; it’s being built right now, and Kofi is at the helm.
      </p>
    ),
    double: false,
  },
  {
    id: 17,
    title: "9. The Techstars Trailblazers: Pioneering Inclusive Innovation",
    additional: (
      <p>
        Techstars is more than just an accelerator; it’s a global engine of
        opportunity. With $1 billion in Assets Under Management (AUM) and a
        portfolio of over 3,400 companies across 54 accelerators in 39 cities
        and 14 countries, Techstars is the lifeblood of early-stage
        entrepreneurship. Their commitment to diversity isn’t just a
        mission—it’s a movement. Since 2021, Techstars has funded over 1,150
        CEOs from under-networked backgrounds and, through a partnership with
        JPMorgan, is deploying $80 million to support 400 diverse-led companies
        across the U.S.  
        <br />
        <br />
        We’ve selected a few visionary Managing Directors who are themselves
        from under-networked communities and are transforming the landscape of
        venture capital and proving that when diverse talent is empowered,
        innovation thrives.
      </p>
    ),
    investors: [
      {
        name: "Andres Barreto: The Global Ecosystem Guru ",
        image: N9a,
        description: (
          <p>
            At Techstars NYC, Andres Barreto is connecting continents through
            capital. A Colombian-born serial entrepreneur behind Grooveshark and
            Onswipe, Andres champions customer-obsessed founders and builds
            ecosystems where innovation transcends borders—from Medellín to
            Manhattan.
          </p>
        ),
      },
      {
        name: "Tricia Martinez: The Impact Innovator",
        image: N9b,
        description: (
          <>
            <p>
              Leading Techstars Chicago, Tricia Martinez is a force for
              inclusive tech. A Latina trailblazer and blockchain innovator,
              she’s leveraging her experience in financial platforms to drive
              AI-driven innovation. At Techstars, Tricia is creating a space
              where groundbreaking ideas from under-networked founders don’t
              just get heard—they get funded.
            </p>
          </>
        ),
      },
      {
        name: "Melissa Pegus: The Scaling Strategist",
        image: N9c,
        description: (
          <>
            <p>
              Overseeing Techstars Atlanta and New Orleans, Melissa Pegus blends
              over a decade of startup scaling experience with a passion for
              diversity. With seven acquisitions under her belt, Melissa focuses
              on commerce, consumer tech, climate, and the creator economy. Her
              mission? To ensure diverse founders have access to funding,
              mentorship, and growth opportunities that turn visions into
              ventures.
            </p>
          </>
        ),
      },
      {
        name: "Adam Phillips: The Policy-to-Startup Pioneer",
        image: N9d,
        description: (
          <>
            <p>
              At Techstars’ Equitech Accelerator in Baltimore, Maryland, Adam
              Phillips is transforming the capital’s policy landscape into
              fertile ground for innovation. A former U.S. Department of
              Transportation attorney-advisor, Adam bridges the worlds of public
              policy and entrepreneurship. He connects mission-driven founders
              with the resources and support to make a lasting impact.
            </p>
          </>
        ),
      },
      {
        name: "Neal Sales-Griffin: The Coding Crusader",
        image: N9e,
        description: (
          <>
            <p>
              At the helm of Techstars San Francisco, Oakland, and Chicago, Neal
              Sales-Griffin is shaping ecosystems where innovation and inclusion
              intersect. A self-taught coder and serial entrepreneur, Neal
              co-founded The Starter League and led CodeNow, democratizing tech
              education for under-networked communities. At Techstars, he’s
              empowering diverse founders with the tools and mentorship they
              need to succeed, ensuring talent meets opportunity.
            </p>
          </>
        ),
      },
      {
        name: "Monica Wheat: The Ecosystem Engineer",
        image: N9f,
        description: (
          <>
            <p>
              At Techstars Detroit, Monica Wheat is a serial entrepreneur and
              founder of Venture Catalysts. Focused on mobility tech and
              underserved communities, Monica is crafting pathways for Black and
              Brown founders to thrive in the tech economy. These Techstars
              leaders are proving that when diversity and opportunity align, the
              results are transformative. With $1 billion in play, their
              commitment to inclusive innovation is more than a strategy—it’s a
              blueprint for the future. They’re not just investing in startups;
              they’re building an ecosystem where every brilliant idea has the
              chance to soar.
            </p>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 18,
    title: "8. Nasir Qadree: The Inclusive Investing Maverick",
    image: N8,
    description: (
      <p>
        Nasir Qadree is on a mission to make venture capital more inclusive. As
        the founder and managing partner of Zeal Capital Partners, a fund that
        raised an oversubscribed initial fund of $62.1 million in July 2021 and
        is raising a $120 million Fund II, this financial wunderkind is proving
        that doing good and making bank aren't mutually exclusive as he backs
        fintech and future-of-work startups led by diverse teams tackling
        systemic disparities. With a background that reads like a finance fairy
        tale—from humble beginnings to Goldman Sachs prodigy—Qadree could have
        easily rested on his laurels. Instead, he's using his financial acumen
        to bridge the opportunity gap for under-networked founders in fintech
        and the future of work.  <br />
        <br />
        With initiatives like the Barclays Black Formation Investments fund and
        a Venture Scout Program, Nasir isn’t just allocating capital—he’s
        building a movement. His philosophy of Inclusive Investing™ underscores
        the power of diverse leadership to drive superior outcomes. For Nasir,
        financial returns and societal impact are two sides of the same coin.
      </p>
    ),
    double: false,
  },
  {
    id: 19,
    title: "7. The Harlem Capital Revolutionaries: Redefining Venture Capital's DNA",
    additional: (
      <p>
        Jarrid Tingle and Henri Pierre-Jacques are more than VCs—they’re
        architects of an inclusive future. As the visionary co-founders of
        Harlem Capital, they’re driving a mission to invest in 1,000 diverse
        founders over the next two decades. With $174 million in assets under
        management across two funds, they’re proving that when you bet on
        under-networked talent, innovation and returns go hand-in-hand.
      </p>
    ),
    investors: [
      {
        name: "Jarrid Tingle: The Resilience Architect",
        image: N7a,
        description: (
          <p>
            Jarrid Tingle embodies what it means to turn resilience into
            revolution. From growing up on welfare to graduating from Wharton
            and Harvard Business School (where he was a Baker Scholar, an honor
            for the top 5% of his class), Jarrid’s journey is a testament to
            grit and determination.  
            <br />
            <br />
            At Harlem Capital, he’s not just writing checks—he’s deploying over
            $62 million into diverse-led startups. With investments in over 30
            companies, where 92% are led by diverse founders (61% Black or
            Latino, 43% female), Jarrid is proving that diversity is the
            cornerstone of innovation.
            <br />
            <br />
            Named to Forbes 30 Under 30 and Inc. 30 Under 30, Jarrid isn’t here
            for the accolades. He’s here to build a future where diversity isn’t
            a box to check—it’s the engine of exponential growth.
          </p>
        ),
      },
      {
        name: "Henri Pierre-Jacques: The Diversity Dynamo",
        image: N7b,
        description: (
          <>
            <p>
              Henri Pierre-Jacques is a powerhouse reshaping venture capital
              from the ground up. Born in Detroit with Haitian roots, Henri
              blends his Northwestern and Harvard Business School credentials
              with an unrelenting drive to level the playing field.  
              <br />
              <br />
              In 2020, Henri and Jarrid closed a landmark $134 million Fund II,
              bringing their total assets under management across two funds to
              $174 million and solidifying Harlem Capital as a Black-led VC
              powerhouse. But Henri is not stopping there. As a board member of
              the Amazon Black Business Accelerator, he is tearing down barriers
              and championing diverse founders in every corner of the economy.{" "}
              <br />
              <br />
                 Recognized by Forbes, Inc. and EBONY, Henri’s mission is clear:
              diversity isn’t a perk—it’s a necessity for thriving businesses.
              His influence stretches from the pages of The Wall Street Journal
              to the headlines of TechCrunch, where he challenges the industry
              to keep pace with his vision for equity.
            </p>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 20,
    title: "6. Kapor Capital: Pioneers of Equitable Investing",
    additional: (
      <p>
        Brian Dixon and Ulili Onovakpuri are not just VCs—they’re architects of
        change, driving a vision where inclusion fuels innovation. At the helm
        of Kapor Capital, with over $500 million in assets under management and
        a portfolio of 170+ startups, they’re proving that diversity is not a
        side hustle—it’s the foundation of smart investing.
      </p>
    ),
    investors: [
      {
        name: "Brian Dixon: The Trailblazing VC Prodigy",
        image: N6a,
        description: (
          <p>
            Brian Dixon is more than a VC partner—he’s a trailblazer redefining
            what’s possible for young, Black investors. From starting as an
            intern to becoming one of the youngest African-American partners in
            Silicon Valley, Brian’s ascent at Kapor Capital has been nothing
            short of meteoric. A graduate of Northeastern University and Babson
            College, he has invested in over 130 startups, proving that talent
            and vision are timeless.  
            <br />
            <br />
            At Kapor, Brian’s focus is crystal clear: backing founders who’ve
            historically been locked out of the room. His investments amplify
            voices and visions that mainstream VC overlooks. Recognized by
            Business Insider as one of the “46 Most Important African Americans
            in Technology,” Brian takes his insights to the stages of TechCrunch
            Disrupt and SXSW, challenging the status quo and inspiring a new
            wave of innovators.
          </p>
        ),
      },
      {
        name: "Ulili Onovakpuri: The Impact-Driven Investor",
        image: N6b,
        description: (
          <>
            <p>
              Ulili Onovakpuri is a force of purpose, channeling her expertise
              into startups that change lives. With an MBA from Duke University,
              Ulili’s journey spans roles at Village Capital and Fresco Capital,
              but her mission found its home at Kapor Capital. She champions
              founders tackling the toughest challenges in healthcare,
              education, and tech, where lived experiences aren’t
              optional—they’re essential. 
              <br />
              <br />
              Her portfolio brims with companies that solve real-world problems
              for communities left behind by traditional tech. For Ulili, each
              investment is a commitment to a future where innovation serves
              everyone, not just a privileged few.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                A Vision Realized  
              </h3>
              <p>
                In 2022, Brian and Ulili raised Kapor Capital’s largest fund to
                date—a bold $126 million Fund III. With support from Cambridge
                Associates, the Ford Foundation and PayPal, they’ve already
                funded 15 companies where 46% of founders are women and 53% are
                Black.
                <br />
                <br />
                Their Founders’ Commitment initiative ensures diversity isn’t an
                afterthought—it’s embedded in the DNA of every portfolio
                company. With 59% of investments led by women and
                under-networked founders, Kapor Capital is proving that
                inclusion delivers.
                <br />
                <br />
                In a world where less than 0.5% of venture funding reaches Black
                founders, Brian and Ulili are more than changemakers—they’re the
                blueprint for a more equitable tech ecosystem. They’re not
                waiting for the future of VC to arrive—they’re building it, one
                investment at a time.
              </p>
            </div>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 21,
    title: "5. Michael Seibel: The Startup Sage",
    image: N5,
    description: (
      <p>
        Michael Seibel isn’t just shaping the future of startups—he’s
        transforming the blueprint of entrepreneurship. As a Group Partner at Y
        Combinator (YC) and its first African-American partner, Michael’s
        mission is clear: to make Silicon Valley’s opportunities as
        wide-reaching as its ambitions. <br />
        <br />
        A Yale political science graduate turned serial entrepreneur, Michael’s
        story is one of vision, execution, and impact. From co-founding
        Justin.tv—which evolved into Twitch and sold to Amazon for $970
        million—to launching Socialcam, acquired by Autodesk for $60 million in
        just 18 months, his entrepreneurial success is nothing short of
        legendary. But for Michael, success is not the finish line; it’s the
        starting point for building opportunity for others.
        <br />
        <br />
        At YC, Michael is the guiding force behind a new generation of founders.
        With over 6,500 office hours logged and 1,745+ companies mentored, his
        influence is woven into a portfolio valued at a staggering $192 billion.
        He’s proving that investing in under-networked talent isn’t a gamble.
        <br />
        <br />
        Michael’s reach goes beyond YC’s walls. He is also a board member for
        giants like Reddit and Dropbox who has been named to the EBONY Power 100
        and recognized by Forbes. Michael is here to build a network of allies,
        advocates, and game-changers. His philosophy? Talent is everywhere;
        what’s missing is access. And he’s dedicated to fixing that.
        <br />
        <br />
        From mentoring the co-founders of Airbnb to championing success stories
        like Paystack and Brex, Michael is doing more than influencing tech—he’s
        infusing it with new life. He’s not changing the face of tech; he’s
        giving it a new purpose, a new pulse, and a new path forward. The future
        will be built by Michael Seibel and the diverse founders he champions.
      </p>
    ),
    double: false,
  },
  {
    id: 22,
    title: "4. The MaC Mavericks: Redefining Venture Capital's Playbook",
    additional:
      "Marlon Nichols and Charles D. King are architects of a new era in investing. As the minds behind MaC Venture Capital, with over $200 million in assets under management, they’re setting a new standard where diverse talent and innovative ideas intersect to drive the future forward.",
    investors: [
      {
        name: "Marlon Nichols: The Visionary Virtuoso",
        image: N4a,
        description: (
          <p>
            Marlon Nichols is a force to be reckoned with—an expert investor who
            blends tech savvy, private equity acumen, and media insight into a
            potent formula for startup success. As co-founder and managing
            general partner of MaC Venture Capital, this Kauffman Fellow is
            turning early-stage investments into industry-shaping ventures.{" "}
            <br />
            <br />
            His portfolio is a roll call of transformative companies: Gimlet
            Media, Blavity and MongoDB, to name a few. Marlon doesn’t just
            invest in ideas; he invests in futures with potential to disrupt and
            inspire. And his impact doesn’t stop at the investment table—he
            drove a 175% increase in minority representation in the Kauffman
            Fellows program over just four years. <br />
            <br />
            Named one of Pitchbook’s 25 Black Founders and VCs to Watch and
            honored by TechWeek 100, Marlon is walking the walk and building new
            pathways for others to follow. His mission is clear: to ensure that
            innovation reflects the richness of the world we live in.
          </p>
        ),
      },
      {
        name: "Charles D. King: The Hollywood Power Player",
        image: N4b,
        description: (
          <>
            <p>
              Charles D. King has taken his gift for storytelling from the glitz
              of Hollywood to the world of venture capital, where he’s crafting
              a new narrative for success. Born in Harlem, Charles climbed the
              ranks to become the first Black partner at William Morris
              Endeavor, a feat that set the stage for his next chapter as a VC
              visionary. <br />
              <br />
              As the founder and CEO of MACRO, Charles has produced cultural
              milestones like “Judas and the Black Messiah” and earned a
              reputation for championing authentic, diverse voices. Armed with a
              Vanderbilt degree and a Howard Law J.D., he brings a rare blend of
              legal expertise and creative vision to the investment world.{" "}
              <br />
              <br />
              Beyond the boardroom, Charles fuels the future by supporting
              initiatives like the Sundance Institute and College Track,
              ensuring that the next generation of talent has the tools to
              succeed. His goal? To make sure the next wave of innovation isn’t
              just groundbreaking—it’s inclusive, representative, and
              transformative.
            </p>
            <div>
              <h3 className="text-primary600 text-3xl font-paytone mt-12 mb-3">
                A New Blueprint for VC
              </h3>

              <p>
                Together, Marlon and Charles are leading the charge at MaC
                Venture Capital, with investments in over 50 companies—69% of
                which are led by Black and Latino founders. They’re not just
                reshaping venture capital; they’re giving it a new pulse, a new
                rhythm, and a new direction. <br />
                <br />
                The MaC Mavericks are here, and they’re building a future where
                funding meets fairness and diversity drives disruption.
              </p>
            </div>
          </>
        ),
      },
    ],
    double: true,
  },
  {
    id: 23,
    title:
      "3. The Fearless Phenom: Arian Simone's Revolution in Venture Capital",
    image: N3,
    description: (
      <>
        <p>
          Arian Simone is doing more than breaking barriers—she’s engineering a
          seismic shift in venture capital. A double graduate of Florida A&M
          University, Arian is the driving force behind the Fearless Fund, the
          first VC fund created by women of color, for women of color. She’s not
          here to take up space; she’s here to redefine venture capital.  
          <br />
          <br />
          Before championing founders, Arian was crafting her own success story.
          She worked PR magic for Hollywood giants like Sony and Disney, honing
          a blend of marketing prowess and entrepreneurial grit that now fuels
          her investment strategy. Her transition from media maven to VC
          visionary was no pivot—it was a power move.  
          <br />
          <br />
          Under her leadership, the Fearless Fund has:
        </p>
        <ul className="list-disc mt-4 pl-7 text-xl">
          <li>Raised $25 million for its inaugural fund.</li>
          <li>Invested in over 40 companies led by women of color.</li>
          <li>
            Partnered with major corporations like JPMorgan Chase, Bank of
            America, and Mastercard.
          </li>
          <li>
            Established the Fearless Foundation, providing grants and resources
            to women of color-owned businesses.
          </li>
        </ul>
        <p>
          The journey hasn't been without challenges. In 2024, the Fearless Fund
          faced a lawsuit from the American Alliance for Equal Rights, leading
          to the closure of its Strivers Grant Contest, which awarded $20,000
          grants to Black women entrepreneurs. Arian addressed the settlement,
          stating that the program was nearing its conclusion, with only one
          grant left to award. She remarked, "If we have to forego that one
          grant to stay in business to deploy millions, that is definitely a
          win."  
          <br />
          <br />
          Despite this setback, the Fearless Fund remains steadfast in its
          mission. Its core investment and support activities continue unabated,
          demonstrating resilience and an unwavering commitment to empowering
          women of color in the entrepreneurial space.
          <br />
          <br />
          Recognized as one of Entrepreneur Magazine's 100 Women of Impact and
          named to the Forbes Next 1000 list, Arian exemplifies leadership and
          determination. Her message is clear: challenges may arise, but the
          mission to support and uplift under-networked entrepreneurs will
          persist, stronger than ever.
        </p>
      </>
    ),
    double: false,
  },
  {
    id: 24,
    title: "2. The Billion-Dollar Visionary: Adeyemi 'Ade' Ajao",
    image: N2,
    description: (
      <>
        <p>
          Adeyemi “Ade” Ajao isn’t just making waves in venture capital—he’s
          engineering a tectonic shift. As the Managing Partner and Co-Founder
          of Base10 Partners, Ade is wielding a billion-dollar vision to
          reimagine what success looks like in the startup world. His mission?
          To create a venture ecosystem that serves everyone, not just the
          privileged few.
          <br />
          <br />
          Ade’s journey is the stuff of entrepreneurial legend. A graduate of
          Universidad Pontificia Comillas, he co-founded Tuenti, known as the
          Spanish Facebook, which sold for $100 million to Spanish telecom giant
          Telefónica. Not stopping there, he led Identified to a successful
          acquisition by Workday. Ade doesn’t just have the Midas touch—he has
          the vision to transform entire industries.
          <br />
          <br />
          At Base10 Partners, Ade is steering the ship with over $1 billion in
          assets under management. This milestone makes Base10 the first
          Black-led VC firm to reach the billion-dollar mark. But for Ade, it’s
          not just about numbers on a spreadsheet. His focus is on investing in
          startups solving real-world challenges for the “99 percent”—companies
          that deliver impact beyond Silicon Valley boardrooms.
        </p>
        <h3 className="text-primary600 text-3xl font-paytone mt-6 mb-3">
          Key Milestones and Metrics
        </h3>
        <ul className="list-disc mt-4 pl-7 text-xl">
          <li>First Black-led VC firm to reach $1 billion AUM  </li>
          <li>Co-founded Tuenti, sold to Telefónica for €75 million  </li>
          <li>Led Identified to acquisition by Workday  </li>
          <li>Investments targeted at startups serving the “99 percent”  </li>
          <li>
            Committed to mentoring minority entrepreneurs and providing growth
            resources   
          </li>
        </ul>
        <p>
          For Ade, venture capital isn’t just about return on investment—it’s
          about return on inclusion. He’s mentoring the next wave of diverse
          founders, ensuring they have the tools, networks, and capital to build
          thriving businesses. In Ade’s blueprint, diversity isn’t a
          checkbox—it’s the foundation for smarter innovation and exponential
          growth.
        </p>
      </>
    ),
    double: false,
  },
  {
    id: 25,
    title:
      "1. The Precursor Pioneer: Charles Hudson's Blueprint for Inclusive Innovation ",
    image: N1,
    description: (
      <>
        <p>
          Charles Hudson is the visionary founder and Managing Partner of
          Precursor Ventures, a trailblazing VC firm with over $200 million in
          assets under management. Since its inception in 2015, Precursor
          Ventures has backed more than 400 startups, solidifying its reputation
          as a powerhouse in early-stage investing and reflecting his belief in
          the power of under-networked talent and untapped potential. His firm
          leads pre-seed rounds of up to $1 million, with typical investments
          ranging from $250,000 to $500,000.
          <br />
          <br />
          Key investments in companies like AnyRoad, Pandia Health, and VoiceOps
          demonstrate Charles’s knack for spotting early gems and nurturing them
          into industry-defining successes. His portfolio isn't just about
          numbers; it’s about creating a diverse and dynamic startup ecosystem.
        </p>
        <h3 className="text-primary600 text-3xl font-paytone mt-6 mb-3">
          Key Milestones and Metrics
        </h3>
        <ul className="list-disc mt-4 pl-7 text-xl">
          <li>Founded Precursor Ventures in 2015</li>
          <li>$200 million+ AUM</li>
          <li>Portfolio of over 400 companies</li>
          <li>Typical investment: $250K-$500K pre-seed checks</li>
          <li>Notable investments: AnyRoad, Pandia Health, VoiceOps</li>
          <li>Chair of the National Venture Capital Association (NVCA)</li>
        </ul>
        <h3 className="text-primary600 text-3xl font-paytone mt-6 mb-3">
          Beyond the Checkbook
        </h3>
        <p>
          Charles’s influence extends well beyond his investments. As Chair of
          the NVCA, he is at the forefront of driving diversity and inclusion in
          venture capital. His advocacy ensures that the industry doesn’t just
          talk about equity—it actively practices it. <br />
          <br />A former Partner at Uncork Capital and Co-founder of Bionic
          Panda Games, Charles brings a blend of strategic insight, operational
          experience, and relentless dedication to every decision he makes. His
          mentorship and strategic guidance are cornerstones of his work,
          ensuring that each founder has the tools and support needed to thrive.
        </p>
        <h3 className="text-primary600 text-3xl font-paytone mt-6 mb-3">
          Redefining Early-Stage Investing
        </h3>
        <p>
          In a VC landscape where the earliest stages are often the most
          challenging, Charles Hudson and Precursor Ventures are leading the
          charge. He’s not just changing the playbook—he’s creating a new
          blueprint for how early-stage investment should work: inclusive,
          intentional, and impactful. <br />
          <br />
          With Charles at the helm, Precursor Ventures is more than a firm—it’s
          a launchpad for the future of innovation.
        </p>
      </>
    ),
    double: false,
  },
];
