// import { IUser } from "../../../@types/types";
import { createSlice } from "@reduxjs/toolkit";

interface AuthInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  access: string | null;
  refresh: string | null;
  cognitoUsername: string | null;
}

// export interface IState {
//     user: IUser | undefined,
//     auth: AuthInterface | undefined
// }

const authInitialState = {
  isAuthenticated: false,
  isLoading: false,
  access: null,
  refresh: null,
  cognitoUsername: null,
} as AuthInterface;

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    authenticate: (state, action) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.access = action.payload.AccessToken;
      state.refresh = action.payload.refresh || action.payload.RefreshToken;
      state.cognitoUsername = action.payload.CognitoUsername;

      // Need COGNITO_USERNAME because for SSO LinkedIn login using OICD, the username is assigned sub
      // from LinkedIn in cognito.  It can not be changed.  This username is different from email
      // So need to save it  locally.
      // Also, needed to reuse REFRESH_TOKEN
      !localStorage.getItem("COGNITO_USERNAME") &&
        localStorage.setItem(
          "COGNITO_USERNAME",
          action.payload.CognitoUsername
        );
      !localStorage.getItem("REFRESH_TOKEN") &&
        localStorage.setItem("REFRESH_TOKEN", action.payload.RefreshToken);
      !localStorage.getItem("accessToken") &&
        localStorage.setItem("accessToken", action.payload.AccessToken);
    },
    unauthenticate: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.access = null;
      state.refresh = null;
      // localStorage.removeItem("COGNITO_USERNAME");
      // localStorage.removeItem("REFRESH_TOKEN");
      ["COGNITO_USERNAME", "REFRESH_TOKEN"].forEach((i) =>
        localStorage.removeItem(i)
      );
    },
    completeLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { authenticate, unauthenticate, completeLoading } =
  authSlice.actions;
export default authSlice.reducer;
