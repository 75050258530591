import Logo from "../../../assets/logo.png";
import LandingPage from "../../../assets/landingPage.png";
import { useNavigate } from "react-router-dom";
import fireworks from "../../../assets/Animations/fireworks.gif";
import { motion } from "framer-motion";
import { useGenerateSemanticMutation } from "../../../lib/features/startup/startupApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../lib/store";
import { shallowEqual } from "react-redux";
import { errorMessage } from "../../../constants/notification";
import Loading from "../../../components/Loading";

const Options = () => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user, shallowEqual);
  const startupData = useSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );

  const [generateSemantic, { isLoading: generateSemanticLoading }] =
    useGenerateSemanticMutation();

  if (generateSemanticLoading) <Loading />;
  return (
    <div className="flex flex-col h-screen bg-white">
      <nav className="flex items-center justify-between pt-6 pl-4 md:pl-12 lg:pl-32">
        <img
          src={Logo}
          alt="Logo"
          className="h-8 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </nav>

      <div className="w-full h-full flex md:items-end flex-col md:flex-row bg-white">
        <motion.img
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          src={LandingPage}
          alt="landing page"
          className="h-[300px] object-contain md:h-[600px] mt-4"
        />
        <div className="w-full md:w-[600px] h-full flex flex-col items-center space-y-6 justify-center">
          <motion.img
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 20 }}
            src={fireworks}
            alt="logo"
            className="h-[120px] w-[120px]"
          />
          <motion.p
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 20 }}
            className="text-[14px] md:text-[20px] leading-[20px] md:leading-[30px] text-center"
          >
            Your next move, simplified. Start with feedback to strengthen your
            approach, or dive straight into funder matches.
          </motion.p>
          <div className="flex gap-4">
            <motion.button
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50, damping: 20 }}
              onClick={async () => {
                await generateSemantic({
                  user_id: user.id,
                  startup_id: startupData.id,
                })
                  .unwrap()
                  .then(() => {
                    localStorage.setItem("first_login", "true");

                    navigate("/feedback", { replace: true });
                  })
                  .catch((err) => {
                    errorMessage(err.data.detail || "Error! Try Again");
                  });
              }}
              className="text-[12px] md:text-base font-semibold border border-primary600 text-primary600 rounded-lg py-4 px-7 disabled:bg-gray-500"
            >
              See Feedback
            </motion.button>

            <motion.button
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              transition={{ type: "spring", stiffness: 50, damping: 20 }}
              onClick={async () => {
                await generateSemantic({
                  user_id: user.id,
                  startup_id: startupData.id,
                })
                  .unwrap()
                  .then(() => {
                    localStorage.setItem("first_login", "true");
                    navigate("/funder-pipeline", { replace: true });
                  })
                  .catch((err) => {
                    console.log("error:", err);
                  });
              }}
              className="text-[12px] md:text-base font-semibold bg-primary600 text-white rounded-lg py-4 px-7"
            >
              Get Recommendations
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Options;
