import React, { useEffect } from "react";
import SentMessage from "./components/SentMessage";
import ReceivedMessage from "./components/ReceivedMessage";
import { LucideAudioLines } from "lucide-react";
import { IoMdClose } from "react-icons/io";
import { useGetSingleAIConversationQuery } from "../../lib/features/ai_agent/aiAgentApiSlice";
import { useAppSelector } from "../../lib/hooks";
import { RootState } from "../../lib/store";
import Loading from "../../components/Loading";

const Transcript = () => {
  const userId = useAppSelector((state: RootState) => state.user?.id);

  const { data, refetch, isLoading } = useGetSingleAIConversationQuery({
    userID: userId,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <Loading />;

  return (
    <div className="w-full p-4 flex flex-col">
      <div className="flex-grow overflow-y-auto md:px-40">
        {data &&
          data.content &&
          data?.content?.map((msg: any, index: number) =>
            msg.role === "user" ? (
              <SentMessage key={index} message={msg.content} />
            ) : (
              <ReceivedMessage
                key={index}
                message={
                  msg?.content?.response ? msg?.content?.response : msg?.content
                }
              />
            )
          )}
      </div>
      <div className="bg-white w-full mx-auto pb-4 flex items-center justify-center">
        <div className="border border-[#1980b3] p-4 rounded-lg flex items-center justify-center gap-2 space-x-4">
          <div className="flex items-center space-x-2 gap-2">
            <LucideAudioLines color="#1980b3" />
            <div className="text-[#1980b3]">
              <h2>Voice Chat Ended</h2>
              <p>3m 14s</p>
            </div>
          </div>

          <div className="border-l h-full border-[#1980b3]">
            <IoMdClose color="#1980b3" size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transcript;
