import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import LandingPage from "../../assets/landingPage.png";
import { motion } from "framer-motion";

const GetStarted = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen flex flex-col md:flex-row items-end bg-white p-2">
      <motion.img
        src={LandingPage}
        alt="landing page"
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 50, damping: 20 }}
      />
      <div className="w-full md:w-[600px] py-2 h-full flex flex-col items-center space-y-6 justify-center">
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
          className="flex items-center justify-center flex-col"
        >
          <img
            onClick={() => navigate("/")}
            src={Logo}
            alt="logo"
            className="h-[64px] w-[400px] cursor-pointer mb-8"
          />
          <p className="text-[14px] md:text-[20px] leading-[20px] md:leading-[30px] text-center">
            Welcome to MyCofoundr.AI. I’m your AI co-pilot, here to help unlock
            the future of your business. Together, we’ll connect you with
            grants, investors, or loans tailored to your goals. In just three
            minutes, I’ll uncover your vision and chart your path forward. Ready
            to begin?
          </p>
        </motion.div>
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 50, damping: 20 }}
        >
          <Link
            to={"/get-started/personal-info"}
            className="text-base font-semibold bg-primary600 text-white rounded-lg py-4 px-7"
          >
            Begin Your Journey
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default GetStarted;
