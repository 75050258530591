import { FC, useState } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

type feedbackCardProps = {
  name: string;
  icon: string;
  desc?: string;
  improvements: string;
  needs: string;
  next: string;
};
const FeedbackCard: FC<feedbackCardProps> = ({
  name,
  icon,
  desc,
  improvements,
  needs,
  next,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  console.log("improvement:", improvements);
  return (
    <div
      style={{
        boxShadow: "0px 4px 6px -2px #16423C08, 0px 12px 16px -4px #16423C14",
        padding: "20px",
        borderRadius: "16px",
      }}
    >
      <div className="flex items-center gap-4">
        <img className="w-[64px] h-[64px]" src={icon} alt={name} />
        <p className="font-semibold text-[24px] leading-[32px] text-[#136086]">
          {name}
        </p>
      </div>
      <div className="flex flex-col">
        {desc && desc?.length > 0 && (
          <p className="text-[16px] text-gray-500 my-4">{desc}</p>
        )}

        {isOpen && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-5">
              <div className="flex flex-col items-center justify-center px-4 py-6 border-dashed border-2 border-[#136086]">
                <h2 className="text-[24px] font-semibold">What You Did Well</h2>
                <p className="text-[16px] text-gray-500">{improvements}</p>
              </div>

              <div className="flex flex-col items-center justify-center px-4 py-6 border-dashed border-2 border-[#136086]">
                <h2 className="text-[24px] font-semibold">
                  What Needs Improvement
                </h2>
                <p className="text-[16px] text-gray-500">{needs}</p>
              </div>
            </div>

            <div className="grid grid-cols-1 my-4">
              <div className="flex flex-col items-center justify-center px-4 py-6 border-dashed border-2 border-[#136086]">
                <h2 className="text-[24px] font-semibold">Next Steps</h2>
                <p className="text-[16px] text-gray-500">{next}</p>
              </div>
            </div>
          </>
        )}

        <div className="w-full flex justify-end">
          <button
            className="rounded-[8px] bg-primary600 p-4 text-white hover:bg-white hover:text-primary600 hover:border hover:border-primary600 transition-all duration-300"
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            {!isOpen ? (
              <div className="flex items-center gap-2">
                <FaArrowDown size={24} />
                <p className="text-[16px] leading-[24px]">Show More</p>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <FaArrowUp size={24} />
                <p className="text-[16px] leading-[24px]">Close</p>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCard;
