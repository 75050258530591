import { apiSlice } from "../../services/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials: {
        username?: string | undefined;
        password?: string | undefined;
        code?: string | undefined;
      }) => ({
        url: "auth/signin",
        method: "POST",
        body: credentials,
      }),
    }),
    signUpWithVoice: builder.mutation({
      query: (credentials: {
        email?: string | undefined;
        password?: string | undefined;
        linkedin_link?: string | undefined;
        voice_name?: string | undefined;
      }) => ({
        url: "auth/signup/",
        method: "POST",
        body: credentials,
      }),
    }),
    newPassword: builder.mutation({
      query: (credentials: {
        username?: string | undefined;
        new_password?: string | undefined;
        session?: string | undefined;
      }) => ({
        url: "auth/new-password",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (credentials: {
        username: string | undefined;
        email: string | undefined;
        first_name: string | undefined;
        last_name: string | undefined;
        password: string | undefined;
      }) => ({
        url: "auth/signup",
        method: "POST",
        body: {
          username: credentials.username,
          first_name: credentials.first_name,
          last_name: credentials.last_name,
          password: credentials.password,
          email: credentials.email,
          // type:        "Founder",
          // countryCode: "usa",
          // companyId:   "665c627ed6bb84f68bbea3ea",
        },
      }),
    }),
    confirmAccount: builder.mutation({
      query: (credentials: { email: string | null; code: string }) => ({
        url: "auth/confirm",
        method: "POST",
        body: {
          username: credentials.email,
          code: credentials.code,
        },
      }),
    }),
    resendConfirmation: builder.mutation({
      query: (credentials: { email: string | null }) => ({
        url: `auth/resend-confirmation?email=${credentials.email}`,
        method: "POST",
        body: {},
      }),
    }),
    semantic: builder.mutation({
      query: (credentials) => ({
        url: "semantic/",
        method: "POST",
        body: credentials,
      }),
    }),
    initiatePasswordReset: builder.mutation({
      query: (credentials) => ({
        url: "auth/initiate-reset-password",
        method: "POST",
        body: credentials,
      }),
    }),
    confirmPasswordReset: builder.mutation({
      query: (credentials) => ({
        url: "auth/confirm-reset-password",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useNewPasswordMutation,
  useRegisterMutation,
  useConfirmAccountMutation,
  useSemanticMutation,
  useConfirmPasswordResetMutation,
  useInitiatePasswordResetMutation,
  useResendConfirmationMutation,
  useSignUpWithVoiceMutation,
} = authApiSlice;
