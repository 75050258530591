import React, { useState, useCallback, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import {
  useGetFunnelInvestorsQuery,
  useMoveInvestorsMutation,
} from "../../../lib/features/recommendations/recommendations";
import Loading from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import AILogo from "../../../assets/Animations/AILogo.png";
import FunnelModal from "./FunnelModal";
import { AskAnything } from "../../AskAnything/AskAnything";
import { FaArrowDown } from "react-icons/fa6";

export interface SelectedFunnel {
  name: string;
  image: string | null;
  fund: string | null;
  why: string;
  stages: string[];
  focus: string[];
  willing: boolean;
  recent_investment: {
    name: string;
    date: string;
  };
  shared_interest: string[];
  investment_range: string;
  linkedIn: string;
  mail: string;
}

interface Item {
  id: string;
  content: string;
  image?: string;
  score?: number;
  fund?: string;
  email: string;
  matchLevel: string;
  bio: string;
  fundwebsite: string;
  linkedin: string;
  reason: string;
}

// a little function to help with reordering the result
const reorder = (
  list: Item[],
  startIndex: number,
  endIndex: number
): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any
): React.CSSProperties => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgreen" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? "#C9C9CA" : "#E9F5FC",
  padding: grid,
  width: "100%",
});

function Dnd() {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user);

  const [moveInvestors, { isLoading: moveIsloading }] =
    useMoveInvestorsMutation();

  const { data: vcRecommendations, isLoading } = useGetFunnelInvestorsQuery(
    user?.id
  );

  const [items, setItems] = useState<Item[]>([]);
  const [selected, setSelected] = useState<Item[]>([]);
  const [inProgress, setInProgress] = useState<Item[]>([]);
  const [completed, setCompleted] = useState<Item[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFunnel, setSelectedFunnel] = useState<any>(null);

  const mapToItems = (list: any[]) =>
    list.map((key: any) => ({
      id: key.id,
      content: key.Name,
      reason: key.Reason,
      score: 100,
      image: key?.id.toString().includes("vc")
        ? key?.metadata?.ProfilepicturesinS3
        : !key?.id.toString().includes("vc")
        ? key?.metadata?.ProfilepictureinS3
        : AILogo,
      fund: key.FundName,
      email: key?.metadata?.Email,
      matchLevel: key?.Score.toString(),
      bio: key?.metadata?.Longerbio,
      fundwebsite: key?.metadata?.Fundwebsite,
      linkedin: key?.metadata?.LinkedInprofile,
      stages: key?.metadata?.Stageofinvestment,
      focus: key?.metadata?.Sector,
    }));

  useEffect(() => {
    const ai_matches = vcRecommendations?.ai_matches || [];
    const shortlist = vcRecommendations?.shortlist || [];
    const reached_out = vcRecommendations?.reached_out || [];
    const qna_list = vcRecommendations?.qna_list || [];
    setItems(mapToItems(ai_matches));
    setSelected(mapToItems(shortlist));
    setInProgress(mapToItems(reached_out));
    setCompleted(mapToItems(qna_list));
  }, [vcRecommendations]);

  const move = useCallback(
    (
      source: Item[],
      destination: Item[],
      droppableSource: { index: number; droppableId: string },
      droppableDestination: { index: number; droppableId: string }
    ) => {
      const id2List: { [key: string]: string } = {
        droppable: "ai_matches",
        droppable2: "shortlist",
        droppable3: "reached_out", // New column
        droppable4: "qna_list", // New column
      };
      const sourceClone = Array.from(source);
      const destClone = Array.from(destination);
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);
      const result: { [key: string]: Item[] } = {};
      moveInvestors({
        user_id: user?.id,
        investor_id: removed.id,
        current_field: id2List[droppableSource.droppableId.toString()],
        next_field: id2List[droppableDestination.droppableId.toString()],
      })
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
      result[droppableSource.droppableId] = sourceClone;
      result[droppableDestination.droppableId] = destClone;
      return result;
    },
    [moveInvestors, user?.id]
  );

  const getList = useCallback(
    (id: any) => {
      switch (id) {
        case "droppable":
          return items;
        case "droppable2":
          return selected;
        case "droppable3":
          return inProgress; // New column
        case "droppable4":
          return completed; // New column
        default:
          return [];
      }
    },
    [items, selected, inProgress, completed]
  );

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (moveIsloading) return;

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable") {
        setItems(reorderedItems);
      } else if (source.droppableId === "droppable2") {
        setSelected(reorderedItems);
      } else if (source.droppableId === "droppable3") {
        setInProgress(reorderedItems); // New column
      } else {
        setCompleted(reorderedItems); // New column
      }
    } else {
      const movedResult = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setItems(movedResult.droppable || items);
      setSelected(movedResult.droppable2 || selected);
      setInProgress(movedResult.droppable3 || inProgress); // New column
      setCompleted(movedResult.droppable4 || completed); // New column
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div className="mx-16">
      {localStorage.getItem("first_login") === "false" && (
        <div className="min-h-[100px] bg-primary/10 flex items-center justify-between rounded-2xl">
          <div className="bg-white w-full rounded-2xl pt-8">
            <h1 className="text-[30px] font-semibold">
              {"Welcome back, "}

              {user?.first_name}
            </h1>

            <p className=" py-1">Let’s move your startup forward today.</p>

            <AskAnything fullHeight={false} />

            <div className="flex flex-wrap  gap-10 items-center justify-center place-items-center mt-4">
              <div className="p-5 w-[320px] text-left border border-grey200 rounded-lg relative">
                <p className="font-semibold text-grey900 text-base">
                  Potential Connections
                </p>
                <p className="text-grey900 text-3xl font-bold py-2">
                  {vcRecommendations?.ai_matches?.length ?? 0}
                </p>
              </div>

              <div className="p-5 w-[320px] text-left border border-grey200 rounded-lg relative">
                <p className="font-normal text-base">Prioritized Investors</p>
                <p className="text-grey900 text-3xl font-bold py-2">
                  {vcRecommendations?.shortlist?.length ?? 0}
                </p>
              </div>

              <div className="p-5 w-[320px] text-left border border-grey200 rounded-lg relative">
                <p className="font-normal text-base">Active Conversations</p>
                <p className="text-grey900 text-3xl font-bold py-2">
                  {vcRecommendations?.reached_out?.length ?? 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="px-[30px]">
        <div>
          <h1 className="text-xl font-semibold mt-6">
            Manage Your Funnel Pipeline
          </h1>

          <DragDropContext onDragEnd={onDragEnd}>
            <div className="grid md:grid-cols-3 md:gap-10 gap-5 mt-5">
              {/* First column */}
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="!p-5 rounded-lg"
                  >
                    <h3 className="mb-5 text-base">Potential Connections</h3>
                    {items.map((item, index) => {
                      const isDraggable = user.subscription
                        ? user.subscription
                        : index < 5;
                      return isDraggable ? (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className="rounded-md mb-2"
                              onClick={() => {
                                setIsOpen(true);
                                setSelectedFunnel(item);
                              }}
                            >
                              <div className="flex flex-col gap-y-2">
                                <div className="flex items-center justify-end">
                                  {!item.fund ? (
                                    <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
                                      ANGEL
                                    </span>
                                  ) : (
                                    <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
                                      VENTURE CAPITALIST
                                    </span>
                                  )}
                                </div>
                                <div className="flex items-center gap-x-4">
                                  <img
                                    src={item.image || AILogo}
                                    alt=""
                                    className="size-16 rounded-full"
                                  />
                                  <div>
                                    <p className="text-[18px] font-semibold text-grey900">
                                      {item.content}
                                    </p>

                                    <p className="text-[16px]">
                                      <b className="text-grey900 font-medium">
                                        {"Fund: "}
                                      </b>
                                      <span className="text-grey600">
                                        {item.fund ? item.fund : "-"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-col gap-x-4">
                                  <p className="text-[16px] font-medium text-black py-2">
                                    Why This Match Works
                                  </p>
                                  <p className="text-[14px] text-grey600 pt-2 line-clamp-3">
                                    {item.reason}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) : (
                        <div
                          className="rounded-md relative p-[16px] mb-2 bg-white"
                          style={{ cursor: "not-allowed" }}
                        >
                          <div className="absolute inset-0 bg-white bg-opacity-50 backdrop-blur rounded-md flex items-center justify-center z-10">
                            <button
                              onClick={() => navigate("/billing")}
                              className="bg-primary600 text-white px-6 py-2 rounded-md font-medium cursor-pointer cursor-pointer hover:bg-opacity-90 transition-all duration-3000"
                            >
                              Unlock to get more
                            </button>
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <div className="flex items-center justify-end">
                              {!item.fund ? (
                                <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
                                  ANGEL
                                </span>
                              ) : (
                                <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
                                  VENTURE CAPITALIST
                                </span>
                              )}
                            </div>
                            <div className="flex items-center gap-x-4">
                              <img
                                src={item.image || AILogo}
                                alt=""
                                className="size-16 rounded-full"
                              />
                              <div>
                                <p className="text-[18px] font-semibold text-grey900">
                                  {item.content}
                                </p>
                                <p className="text-[16px]">
                                  <b className="text-grey900 font-medium">
                                    {"Fund: "}
                                  </b>
                                  <span className="text-grey600">
                                    {item.fund ? item.fund : "-"}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col gap-x-4">
                              <p className="text-[16px] font-medium text-black py-2">
                                Why This Match Works
                              </p>
                              <p className="text-[14px] text-grey600 pt-2 line-clamp-3">
                                {item.reason}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {provided.placeholder}
                    {items.length > 0 && (
                      <button className="border-2 border-primary text-primary font-semibold text-[18px] w-full py-2 mt-2 flex items-center gap-2 justify-center rounded-md hover:bg-white transition-all duration-2000">
                        Find More Matches <FaArrowDown />
                      </button>
                    )}
                  </div>
                )}
              </Droppable>

              {/* Second column */}
              <Droppable droppableId="droppable2">
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="!p-5 rounded-lg"
                  >
                    <h3 className="mb-5 text-base">Prioritized Investors</h3>
                    {selected.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="rounded-md mb-2"
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedFunnel(item);
                            }}
                          >
                            <div className="flex flex-col gap-y-2">
                              <div className="flex items-center justify-end">
                                {!item.fund ? (
                                  <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
                                    ANGEL
                                  </span>
                                ) : (
                                  <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
                                    VENTURE CAPITALIST
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center gap-x-4">
                                <img
                                  src={item.image || AILogo}
                                  alt=""
                                  className="size-16 rounded-full"
                                />
                                <div>
                                  <p className="text-[18px] font-semibold text-grey900">
                                    {item.content}
                                  </p>

                                  <p className="text-[16px]">
                                    <b className="text-grey900 font-medium">
                                      {"Fund: "}
                                    </b>
                                    <span className="text-grey600">
                                      {item.fund ? item.fund : "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-col gap-x-4">
                                <p className="text-[16px] font-medium text-black py-2">
                                  Why This Match Works
                                </p>
                                <p className="text-[14px] text-grey600 pt-2 line-clamp-3">
                                  {item.reason}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {/* Third column - In Progress */}
              <Droppable droppableId="droppable3">
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="!p-5 rounded-lg"
                  >
                    <h3 className="mb-5 text-base">Active Conversations</h3>
                    {inProgress.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="rounded-md mb-2"
                            onClick={() => {
                              setIsOpen(true);
                              setSelectedFunnel(item);
                            }}
                          >
                            <div className="flex flex-col gap-y-2">
                              <div className="flex items-center justify-end">
                                {!item.fund ? (
                                  <span className="w-max bg-green-100 text-green-800 text-xs font-medium me-2 px-3 py-0.5 rounded-2xl">
                                    ANGEL
                                  </span>
                                ) : (
                                  <span className="w-max top-3 bg-primary100 text-primary700 text-xs font-medium me-2 2xl:px-3 px-1 2xl:py-2 py-0.5 rounded-2xl">
                                    VENTURE CAPITALIST
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center gap-x-4">
                                <img
                                  src={item.image || AILogo}
                                  alt=""
                                  className="size-16 rounded-full"
                                />
                                <div>
                                  <p className="text-[18px] font-semibold text-grey900">
                                    {item.content}
                                  </p>

                                  <p className="text-[16px]">
                                    <b className="text-grey900 font-medium">
                                      {"Fund: "}
                                    </b>
                                    <span className="text-grey600">
                                      {item.fund ? item.fund : "-"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-col gap-x-4">
                                <p className="text-[16px] font-medium text-black py-2">
                                  Why This Match Works
                                </p>
                                <p className="text-[14px] text-grey600 pt-2 line-clamp-3">
                                  {item.reason}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {/* Fourth column - Completed */}
              {/* <Droppable droppableId="droppable4">
                        {(provided: any, snapshot: any) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                className='!p-5 rounded-lg'
                            >
                                <h3 className='mb-5 text-base'>Q&A</h3>
                                {completed.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index} >
                                        {(provided: any, snapshot: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                className='rounded-md'
                                            >
                                                <div className='flex flex-col gap-y-2'>
                                                    <div className='flex items-center justify-end'>
                                                        <span className='bg-[#9DFF9D] text-[#1A5B20] py-0.5 px-1 rounded-md'>{item.score?.toFixed(1)}%</span>
                                                    </div>
                                                    <div className='flex items-center gap-x-2'>
                                                        <img src={item.image} alt="" className='w-6 h-6 rounded-full' />
                                                        {item.content}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable> */}
            </div>
          </DragDropContext>
        </div>
      </div>
      {selectedFunnel && (
        <FunnelModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={selectedFunnel}
        />
      )}
    </div>
  );
}

export default Dnd;
