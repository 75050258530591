import React, { FC, useState } from "react";
import logo from "../../assets/logo.png";
import LoginComponent from "../../components/Auth/Login";
import RegisterComponent from "../../components/Auth/Register";
import ConfirmComponent from "../../components/Auth/Confirm";
import { Link, useNavigate } from "react-router-dom";

interface IProps {}

export const Login: FC<IProps> = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const navigate = useNavigate();

  // if (isAuthenticated) {
  //     return <Navigate to={'/home'} />
  // }

  return (
    <>
      <style scoped>
        {`
                    .VerificationInputContainer {
                    
                    } 
                    
                    .VerificationInputStyle {
                        border: none;
                    }   
                    
                    .VerificationInputInactiveStyle {
                        border: none;
                        background-color: transparent;
                    } 
                          
                    .VerificationInputSelectedStyle {
                        border: none !important;
                        background-color: transparent;
                        outline: none;
                    }
                `}
      </style>
      <main className="h-screen bg-white">
        <header className="py-6 border-b border-gray-100">
          <nav className="flex items-center justify-between max-w-screen-xl mx-auto px-4">
            <Link to={"/"}>
              <img
                src={logo}
                style={{ height: 40, margin: "auto" }}
                alt={"logo"}
              />
            </Link>
          </nav>
        </header>

        <div className="flex justify-center items-center h-3/4">
          <div className="flex flex-col justify-center items-center">
            <img src={logo} className="h-8" alt={"logo"} />
            <h3 className="text-3xl font-semibold mt-6">
              {isLogin ? "Log in to you Account" : "Create an account"}
            </h3>
            {isLogin && (
              <p className="mt-3 text-gray-600">
                Welcome back! Please enter your detaills
              </p>
            )}
            {isLogin ? (
              <LoginComponent isLogin={isLogin} setIsLogin={setIsLogin} />
            ) : isConfirm ? (
              <ConfirmComponent
                setIsConfirm={setIsConfirm}
                setIsLogin={setIsLogin}
              />
            ) : (
              <RegisterComponent
                isLogin={isLogin}
                setIsConfirm={setIsConfirm}
              />
            )}

            <p className="text-center text-grey600 mt-6">
              Dont have an account?{" "}
              <span
                className="text-primary700 cursor-pointer font-semibold text-base"
                onClick={() => {
                  navigate("/get-started");
                }}
              >
                Sign up
              </span>
            </p>

            {/* {isLogin ?
                            (<p className='text-center text-grey600 mt-6'>Dont have an account? <span className='text-primary700 cursor-pointer font-semibold text-base' onClick={() => {
                                setIsLogin(false)
                            }}>Sign up</span></p>)
                            :
                            (
                                <p className='text-center text-grey600 mt-6'>Already have an account? <span className='text-primary700 cursor-pointer font-semibold text-base' onClick={() => {
                                    setIsLogin(true)
                                }}>Login</span></p>
                            )
                        } */}
          </div>
        </div>
      </main>
    </>
  );
};
