import React from "react";
import { Link } from "react-router-dom";
import All25 from "../../../assets/blog/25-all.jpeg";
import { vanguards } from "./vList";

function Vanguard() {
  return (
    <div className="container mb-20">
      <h1 className="text-center md:text-5xl text-2xl font-bold text-primary600 font-paytone">
        The Black Vanguard List: 25 Champions for Black founders
      </h1>
      <div className="my-12">
        <img src={All25} alt="Gary Stewart" className="w-full" />
      </div>
      <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
        A Snapshot of Funding for Black Startups
      </h3>
      <p className="md:text-xl mt-3">
        The road to funding for Black founders has never been easy, and recent
        years have underscored just how steep that climb can be. In 2023, Black
        entrepreneurs received just 0.48% of the $215 billion in VC funding—a
        stark drop from the already modest 1.3% in 2021. That fleeting moment,
        fueled by a wave of social justice momentum, has given way to a market
        defined by tighter capital, economic uncertainty, and shifting political
        winds. The result? Persistent disparities for Black innovators who
        deserve far more opportunities. The challenge is clear: In a world that
        pulls back on progress, how do we keep pushing forward? The answer lies
        in resilience, creativity, and community-driven solutions.
      </p>
      <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
        Strategies for Thriving Despite the Odds
      </h3>
      <ul className="list-disc mt-4 pl-7 md:text-xl">
        <li>
          Investors Who Invest in Black Founders: Align with funds, angels and
          accelerators with track records of supporting black founders and have
          the money to back it up. These champions offer more than capital—they
          can provide mentorship, networks, and resources that open doors.
        </li>
        <li>
          AI and Tech-Enabled Platforms: Leverage tools like MyCofoundr.AI for
          personalized funding matches, insights, and growth strategies designed
          to empower Black founders.
        </li>
      </ul>

      <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
        Reasons to Be Optimistic: Where Challenges Exist, Opportunity Awaits
      </h3>
      <p className="md:text-xl">
        In every downturn, there is the seed of a comeback. In every challenge,
        there’s a door waiting to be opened. The landscape may be tough, but
        history shows that those who rise during difficult times often reap the
        greatest rewards, and to be fair, we’ve been through all this before.
        The future may test our resolve, but history reminds us that the
        greatest opportunities and the greatest strides often emerge from the
        greatest challenges. This Black Vanguard List is more than an
        accolade—it’s an urgent call to action for a redefined future. These
        visionary investors aren’t just funding startups; they are breaking down
        barriers, sparking innovation, and laying the foundation for a future
        where talent and opportunity meet.
      </p>

      {/* founder */}
      {vanguards.map((vanguard) => {
        return vanguard.double ? (
          <div key={vanguard.id} className="my-5">
            <h1 className="text-center md:text-5xl text-2xl text-primary600 font-paytone mt-12">
              {vanguard.title}
            </h1>
            {vanguard.additional && (
              <p className="md:text-lg my-8">{vanguard.additional}</p>
            )}
            {vanguard.investors &&
              vanguard.investors.map((investor, index) => (
                <div>
                  <div className="my-12">
                    <img
                      src={investor.image}
                      alt="Gary Stewart"
                      className="w-full"
                    />
                  </div>
                  <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
                    {investor.name}
                  </h3>
                  <div className="md:text-lg my-8">{investor.description}</div>
                </div>
              ))}
            <hr />
            <div className="my-8">
              <Link
                to={"/login"}
                className=" w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
              >
                Get intros to The Black Vanguard List
              </Link>
            </div>
          </div>
        ) : (
          <div key={vanguard.id} className="my-5">
            <h1 className="text-center md:text-5xl text-2xl text-primary600 font-paytone mt-12">
              {vanguard.title}
            </h1>
            <div className="my-12">
              <img src={vanguard.image} alt="Gary Stewart" className="w-full" />
            </div>
            <div className="md:text-lg my-8">{vanguard.description}</div>
            <hr />
            <div className="my-8">
              <Link
                to={"/login"}
                className=" w-full bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
              >
                Get intros to The Black Vanguard List
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Vanguard;
