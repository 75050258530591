import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { unauthenticate } from "../lib/features/auth/authSlice";
import { unSetProfile } from "../lib/features/user/userSlice";
import { unSetStartUpData } from "../lib/features/startup/startupSlice";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

type logoutModalType = {
  isModalOpen: boolean;
  handleCancel: () => void;
};
const LogoutModal: FC<logoutModalType> = ({ isModalOpen, handleCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOk = async () => {
    dispatch(unauthenticate());
    dispatch(unSetProfile());
    dispatch(unSetStartUpData());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sidebar");
    localStorage.removeItem("user_data");
    localStorage.removeItem("user_id");
    localStorage.removeItem("first_login");
    navigate("/");
  };
  return (
    <Modal
      title={
        <h2 className="font-bold text-[18px] text-gray-900">
          Are you absolutely sure?
        </h2>
      }
      footer={
        <div className="w-full flex items-center justify-center gap-2">
          <button
            onClick={handleCancel}
            className="text-base font-semibold border border-primary600 text-primary600 rounded-lg py-2.5 px-5"
          >
            Cancel
          </button>
          <button
            onClick={handleOk}
            className="text-base font-semibold bg-primary600 text-white rounded-lg py-2.5 px-5"
          >
            Logout
          </button>
        </div>
      }
      open={isModalOpen}
      onCancel={handleCancel}
    >
      <p className="text-[14px] text-gray-600 p-2">
        Logging out will end your current session, and you’ll need to sign back
        in to access your account.
      </p>
    </Modal>
  );
};

export default LogoutModal;
