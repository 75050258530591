import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import logo from "../../assets/logoWhite.png";
import singleLogo from "../../assets/singleLogo.png";
import { RootState } from "../../lib/store";
import { useSidebar } from "../../hooks/use-sidebar";
import { getUserInitials } from "../../utils/utils";
import landingPage from "../../assets/landingPage.png";

import {
  Bell,
  BellDot,
  ChevronLeftIcon,
  ChevronRightIcon,
  LucideAudioLines,
} from "lucide-react";
import landingPageWhite from "../../assets/landingPageWhite.png";

import { FiAward, FiMenu, FiUser } from "react-icons/fi";
import { LuSparkle } from "react-icons/lu";
import { FiLogOut } from "react-icons/fi";
import { FaArrowUp, FaChevronRight, FaChevronUp } from "react-icons/fa6";
import { TbUsers } from "react-icons/tb";
import { useGetProfileImageQuery } from "../../lib/features/user/userApiSlice";
import { CiSettings } from "react-icons/ci";
import { useGetConversationsQuery } from "../../lib/features/conversation/conversationApiSlice";
import { SessionItem } from "./components/SessionItem";
import { FaTimes } from "react-icons/fa";
import LogoutModal from "../LogoutModal";

interface IProps {}

const notificationsData = [
  "You have 3 unsaved investors in your funnel. Save them to track engagement.",
  "Check new Investors",
  "Chat with our Ai Copilot",
];

export const Navbar: FC<IProps> = () => {
  const { toggleSidebar, isSidebarOpen, isLoading } = useSidebar();
  const [openSubmenus, setOpenSubmenus] = React.useState<any>({});
  const [selectedItem, setSelectedItem] =
    React.useState<string>("Funder Pipeline");
  const [openNotification, setOpenNotification] =
    React.useState<boolean>(false);

  const [openLogoutModal, setOpenLogoutModal] = React.useState<boolean>(false);

  const toggleSubmenu = (index: number) => {
    setOpenSubmenus((prev: any) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific submenu
    }));
  };

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const userId = useSelector((state: RootState) => state.user.id);
  const { data: conversations } = useGetConversationsQuery(userId);
  const user = useSelector((state: RootState) => state.user, shallowEqual);

  const navigate = useNavigate();

  const menuItems = [
    {
      id: 1,
      label: "Funder Pipeline",
      icon: TbUsers,
      path: "/funder-pipeline",
    },
    {
      id: 2,
      label: "Ask Me Anything",
      icon: LuSparkle,
      path: "/chat",
      subMenu: true,
    },
    {
      id: 3,
      label: "Startup Profile",
      icon: FiUser,
      path: "/startup-profile",
    },
    {
      id: 4,
      label: "Progress",
      icon: FiAward,
      subMenu: true,
    },
  ];

  const { data: getProfileImage } = useGetProfileImageQuery({
    userId: user?.id,
  });

  const generateSessionItems = useCallback(() => {
    if (!userId) return;

    if (conversations) {
      return conversations?.map((item: any, index: number) => {
        return (
          <SessionItem
            key={index}
            comment={item.title}
            onClick={() => {
              try {
                navigate(`/answer/${item.id}`);
              } catch (e: any) {}
            }}
          />
        );
      });
    } else return <></>;
  }, [conversations, navigate, userId]);
  return (
    <>
      <div
        className={`hidden fixed z-[10] lg:flex flex-col h-screen justify-between bg-sidebar peer ${
          isSidebarOpen ? "lg:w-[280px]" : "w-[90px]"
        } duration-300 transition-all`}
        data-state={isSidebarOpen && !isLoading ? "open" : "closed"}
      >
        <div className="flex justify-between items-center">
          <div className="p-5">
            {isSidebarOpen ? (
              <>
                <img
                  src={logo}
                  alt={"Founders Tribes Logo"}
                  className="h-8 duration-300"
                />
              </>
            ) : (
              <img
                src={singleLogo}
                alt={"Founders Tribes Logo"}
                className="h-8 duration-300"
              />
            )}
          </div>
        </div>

        <button
          className={`${
            isSidebarOpen
              ? "bg-primary700 border-white"
              : "bg-white border-primary700"
          } rounded-full border absolute -right-4 shadow-md flex items-center justify-center top-[2%] z-[99] size-8`}
          onClick={() => {
            toggleSidebar();
          }}
        >
          {isSidebarOpen ? (
            <ChevronLeftIcon className="text-white p-1" />
          ) : (
            <ChevronRightIcon className="text-primary p-1" />
          )}
        </button>

        <div
          className={`flex flex-col px-5 py-8 rounded-t-2xl overflow-auto custom-scrollbar h-full`}
        >
          {/*NAVIGATION*/}
          <>
            {isSidebarOpen && (
              <h4 className="text-sm text-white">Quick Access</h4>
            )}

            <ul className="mt-2">
              <li
                className={`${
                  selectedItem === "Notification"
                    ? "bg-white text-sidebar"
                    : "text-white"
                }  text-[16px] flex items-center justify-between gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                onClick={() => {
                  setSelectedItem("Notification");
                  setOpenNotification((prevState) => !prevState);
                }}
              >
                <span>
                  <span className="text-2xl block float-left">
                    <BellDot />
                    {/* <Bell /> */}
                  </span>
                  <span
                    className={`text-base font-medium flex-1 ml-2 duration-200 ${
                      !isSidebarOpen && "hidden"
                    }`}
                  >
                    Notifications
                  </span>
                </span>

                {isSidebarOpen && (
                  <span className="w-max bg-[#FCEEEE] text-[#E15751] h-fit text-xs font-medium flex items-center px-[10px] py-[2px] rounded-[60px]">
                    3
                  </span>
                )}
              </li>

              {isSidebarOpen &&
                openNotification &&
                (notificationsData.length > 0 ? (
                  notificationsData.map((notification, key) => (
                    <p
                      key={key}
                      className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1"
                    >
                      {notification}
                    </p>
                  ))
                ) : (
                  <p className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1">
                    No Notifications to show
                  </p>
                ))}

              {menuItems.map((item, index) => {
                return (
                  <>
                    <li
                      key={index}
                      className={`${
                        selectedItem === item.label
                          ? "bg-white text-sidebar"
                          : "text-white"
                      }  text-[16px] flex items-center justify-between gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                      onClick={() => {
                        setSelectedItem(item.label);
                      }}
                    >
                      <span
                        onClick={() => {
                          if (item.path) {
                            navigate(item.path);
                          }
                        }}
                      >
                        <span className="text-2xl block float-left">
                          <item.icon />
                        </span>
                        <span
                          className={`text-base font-medium flex-1 ml-2 duration-200 ${
                            !isSidebarOpen && "hidden"
                          }`}
                        >
                          {item.label}
                        </span>
                      </span>

                      {item.subMenu && (
                        <div onClick={() => toggleSubmenu(index)}>
                          {openSubmenus[index] ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronRight />
                          )}
                        </div>
                      )}
                    </li>
                    {openSubmenus[index] &&
                      (item.label === "Progress" ? (
                        <div className="p-2 px-5">
                          {isSidebarOpen && (
                            <p className="text-white text-xs pb-2">
                              You've reached out to 3 of 10 investors this week
                            </p>
                          )}
                          <div className="flex gap-2 items-center">
                            {isSidebarOpen && (
                              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div
                                  className="bg-blue-600 h-2.5 rounded-full"
                                  style={{ width: "45%" }}
                                ></div>
                              </div>
                            )}
                            <p className="text-white text-xs">45%</p>
                          </div>
                        </div>
                      ) : (
                        <div className="px-2 pb-2">
                          {conversations.length > 0 ? (
                            generateSessionItems()
                          ) : (
                            <p className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1">
                              No Conversations to show!
                            </p>
                          )}
                        </div>
                      ))}
                  </>
                );
              })}
            </ul>

            {isSidebarOpen && (
              <h4 className="text-sm text-white mt-8">HISTORY</h4>
            )}
            <ul className="mt-2">
              <li
                className={`${
                  selectedItem === "Transcript"
                    ? "bg-white text-sidebar"
                    : "text-white"
                }  text-[16px] flex items-center gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                onClick={() => {
                  navigate("/transcript");
                  setSelectedItem("Transcript");
                }}
              >
                <span className="text-2xl block float-left">
                  <LucideAudioLines />
                </span>
                <span
                  className={`text-base font-medium flex-1 duration-200 ${
                    !isSidebarOpen && "hidden"
                  }`}
                >
                  Transcript
                </span>
              </li>

              <li
                className={`${
                  selectedItem === "Feedback"
                    ? "bg-white text-sidebar"
                    : "text-white"
                } text-[16px] flex items-center gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                onClick={() => {
                  navigate("/feedback");
                  setSelectedItem("Feedback");
                }}
              >
                <span className="text-2xl block float-left">
                  {selectedItem !== "Feedback" ? (
                    <img
                      className="h-[24px] w-[24px] object-contain"
                      src={landingPageWhite}
                      alt="AILogo"
                    />
                  ) : (
                    <img
                      src={landingPage}
                      className="h-[24px] w-[24px]"
                      alt="AILogo"
                    />
                  )}
                </span>
                <span
                  className={`text-base font-medium flex-1 duration-200 ${
                    !isSidebarOpen && "hidden"
                  }`}
                >
                  Feedback
                </span>
              </li>
            </ul>
          </>
        </div>
        {isAuthenticated && (
          <div className="">
            <div className="flex w-full justify-between py-2 px-4 gap-2">
              <div className="flex flex-col">
                <p className="font-semibold text-[16px] text-white">
                  Free Plan
                </p>
                <span
                  onClick={() => navigate("/billing")}
                  className="flex text-white items-center gap-1 cursor-pointer"
                >
                  <FaArrowUp size={16} />
                  <p className="text-[12px] ">Upgrade</p>
                </span>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  <CiSettings
                    className={`${isSidebarOpen ? "text-white" : "text-white"}`}
                    size={24}
                  />
                </button>

                <button
                  onClick={() => {
                    setOpenLogoutModal(true);
                  }}
                >
                  <FiLogOut
                    className={`${isSidebarOpen ? "text-white" : "text-white"}`}
                    size={24}
                  />
                </button>
              </div>
            </div>
            <div className=" flex items-start justify-between gap-2.5 p-4 text-sm lg:border-t bg-sidebar lg:border-t-[#DEE2E6]  cursor-pointer">
              {isSidebarOpen && (
                <div className="flex items-center gap-x-2">
                  <div>
                    {getProfileImage?.url ? (
                      <img
                        src={getProfileImage.url}
                        alt={"avatar"}
                        className="size-10 rounded-full"
                      />
                    ) : (
                      <div className="flex size-8 shrink-0 select-none items-center justify-center rounded-full bg-white text-xs font-medium uppercase text-primary700">
                        {getUserInitials(user.first_name!!, user.last_name!!)}
                      </div>
                    )}
                  </div>
                  <div>
                    <h6 className="text-sm font-semibold text-white">
                      {user?.first_name}
                    </h6>
                    <p className="text-white">
                      {(user?.email?.length!! ?? "") > 18
                        ? user?.email?.substring(0, 18) + "..."
                        : user?.email}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* mobile sidebar */}
      <div className="block lg:hidden">
        <div className="p-4 w-full flex items-center justify-between">
          <img src={logo} alt={"Founders Tribes Logo"} className="h-8" />
          <div className="cursor-pointer" onClick={toggleSidebar}>
            {isSidebarOpen ? <FaTimes /> : <FiMenu />}
          </div>
        </div>
        {isSidebarOpen && (
          <div className="h-full absolute left-0 w-full flex flex-col justify-between bg-sidebar z-[99] pb-20 overflow-auto">
            <div>
              <div className="m-4">
                <h4 className="text-sm text-white">Quick Access</h4>
                <ul className="mt-2">
                  <li
                    className={`${
                      selectedItem === "Notification"
                        ? "bg-white text-sidebar"
                        : "text-white"
                    }  text-[16px] flex items-center justify-between gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                    onClick={() => {
                      setSelectedItem("Notification");
                      setOpenNotification((prevState) => !prevState);
                    }}
                  >
                    <span>
                      <span className="text-2xl block float-left">
                        <BellDot />
                        {/* <Bell /> */}
                      </span>
                      <span
                        className={`text-base font-medium flex-1 ml-2 duration-200 ${
                          !isSidebarOpen && "hidden"
                        }`}
                      >
                        Notifications
                      </span>
                    </span>

                    <span className="w-max bg-[#FCEEEE] text-[#E15751] h-fit text-xs font-medium me-2 flex items-center px-[10px] py-[2px] rounded-[60px]">
                      3
                    </span>
                  </li>
                  {openNotification &&
                    (notificationsData.length > 0 ? (
                      notificationsData.map((notification, key) => (
                        <p
                          key={key}
                          className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1"
                        >
                          {notification}
                        </p>
                      ))
                    ) : (
                      <p className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1">
                        No Notifications to show
                      </p>
                    ))}
                  {menuItems.map((item, index) => {
                    return (
                      <>
                        <li
                          key={index}
                          className={`${
                            selectedItem === item.label
                              ? "bg-white text-sidebar"
                              : "text-white"
                          }  text-[16px] flex items-center justify-between gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                          onClick={() => {
                            setSelectedItem(item.label);
                          }}
                        >
                          <span
                            onClick={() => {
                              if (item.path) {
                                navigate(item.path);
                              }
                            }}
                          >
                            <span className="text-2xl block float-left">
                              <item.icon />
                            </span>
                            <span
                              className={`text-base font-medium flex-1 ml-2 duration-200 ${
                                !isSidebarOpen && "hidden"
                              }`}
                            >
                              {item.label}
                            </span>
                          </span>

                          {item.subMenu && (
                            <div onClick={() => toggleSubmenu(index)}>
                              {openSubmenus[index] ? (
                                <FaChevronUp />
                              ) : (
                                <FaChevronRight />
                              )}
                            </div>
                          )}
                        </li>
                        {openSubmenus[index] &&
                          (item.label === "Progress" ? (
                            <div className="p-2 px-5">
                              {isSidebarOpen && (
                                <p className="text-white text-xs pb-2">
                                  You've reached out to 3 of 10 investors this
                                  week
                                </p>
                              )}
                              <div className="flex gap-2 items-center">
                                {isSidebarOpen && (
                                  <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                    <div
                                      className="bg-blue-600 h-2.5 rounded-full"
                                      style={{ width: "45%" }}
                                    ></div>
                                  </div>
                                )}
                                <p className="text-white text-xs">45%</p>
                              </div>
                            </div>
                          ) : (
                            <div className="px-2 pb-2">
                              {conversations.length > 0 ? (
                                generateSessionItems()
                              ) : (
                                <p className="text-[12px] leading-[18px] cursor-pointer hover:-translate-y-1 transition-all text-white px-2 py-1 mb-1">
                                  No Conversations to show!
                                </p>
                              )}
                            </div>
                          ))}
                      </>
                    );
                  })}
                </ul>
                <h4 className="text-sm text-white mt-8">HISTORY</h4>
                <ul className="mt-2">
                  <li
                    className={`${
                      selectedItem === "Transcript"
                        ? "bg-white text-sidebar"
                        : "text-white"
                    }  text-[16px] flex items-center gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                    onClick={() => {
                      navigate("/transcript");
                      setSelectedItem("Transcript");
                    }}
                  >
                    <span className="text-2xl block float-left">
                      <LucideAudioLines />
                    </span>
                    <span
                      className={`text-base font-medium flex-1 ml-2 duration-200 ${
                        !isSidebarOpen && "hidden"
                      }`}
                    >
                      Transcript
                    </span>
                  </li>

                  <li
                    className={`${
                      selectedItem === "Feedback"
                        ? "bg-white text-sidebar"
                        : "text-white"
                    } text-[16px] flex items-center gap-x-4 cursor-pointer p-2 hover:bg-white hover:text-sidebar rounded-[8px] mb-2`}
                    onClick={() => {
                      navigate("/feedback");
                      setSelectedItem("Feedback");
                    }}
                  >
                    <span className="text-2xl block float-left">
                      {selectedItem !== "Feedback" ? (
                        <img
                          className="h-[24px] w-[24px]"
                          src={landingPageWhite}
                          alt="AILogo"
                        />
                      ) : (
                        <img
                          src={landingPage}
                          className="h-[24px] w-[24px]"
                          alt="AILogo"
                        />
                      )}
                    </span>
                    <span
                      className={`text-base font-medium flex-1 duration-200 ${
                        !isSidebarOpen && "hidden"
                      }`}
                    >
                      Feedback
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="">
              <div className="flex w-full justify-between py-2 px-4 gap-2">
                <div className="flex flex-col">
                  <p className="font-semibold text-[16px] text-white">
                    Free Plan
                  </p>
                  <span
                    onClick={() => navigate("/billing")}
                    className="flex text-white items-center gap-1 cursor-pointer"
                  >
                    <FaArrowUp size={16} />
                    <p className="text-[12px] ">Upgrade</p>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    <CiSettings
                      className={`${
                        isSidebarOpen ? "text-white" : "text-white"
                      }`}
                      size={24}
                    />
                  </button>

                  <button
                    onClick={() => {
                      setOpenLogoutModal(true);
                    }}
                  >
                    <FiLogOut
                      className={`${
                        isSidebarOpen ? "text-white" : "text-white"
                      }`}
                      size={24}
                    />
                  </button>
                </div>
              </div>
              <div className=" flex items-start justify-between gap-2.5 p-4 text-sm lg:border-t lg:border-t-[#DEE2E6]  cursor-pointer">
                {isSidebarOpen && (
                  <div className="flex items-center gap-x-2">
                    <div>
                      {getProfileImage?.url ? (
                        <img
                          src={getProfileImage.url}
                          alt={"avatar"}
                          className="size-10 rounded-full"
                        />
                      ) : (
                        <div className="flex size-8 shrink-0 select-none items-center justify-center rounded-full bg-primary text-xs font-medium uppercase text-white">
                          {getUserInitials(user.first_name!!, user.last_name!!)}
                        </div>
                      )}
                    </div>
                    <div>
                      <h6 className="text-sm font-semibold text-white">
                        {user?.first_name}
                      </h6>
                      <p className="text-white">
                        {(user?.email?.length!! ?? "") > 18
                          ? user?.email?.substring(0, 18) + "..."
                          : user?.email}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <LogoutModal
        isModalOpen={openLogoutModal}
        handleCancel={() => setOpenLogoutModal(false)}
      />
    </>
  );
};
