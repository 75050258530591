import { FC } from "react";
import { defaultProfile } from "../../constants/defaults";
import { Avatar, Button, Form, FormProps, Input } from "antd";
import { RootState } from "../../lib/store";
import { useAppSelector } from "../../lib/hooks";
import {
  useGetProfileImageQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../lib/features/user/userApiSlice";
import { errorMessage, successMessage } from "../../constants/notification";
import type { UploadProps } from "antd";
import { message, Upload, Select } from "antd";
import { FiUploadCloud } from "react-icons/fi";
import { Mail } from "lucide-react";
import { SiLinkedin } from "react-icons/si";
import { Countries } from "../../constants/countries";
import { CiLock } from "react-icons/ci";
import { FaArrowUp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const { Dragger } = Upload;

type FieldType = {
  email: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  linkedin_profile: string | undefined;
  bio: string | undefined;
  bio_link: string | undefined;
  password: string | undefined;
};

interface IProps {}

export const UserSetting: FC<IProps> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user);
  const { access } = useAppSelector((state: RootState) => state.auth);
  const [form] = Form.useForm<{
    email: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    linkedin_profile: string | undefined;
    bio: string | undefined;
    bio_link: string | undefined;
    password: string | undefined;
  }>();

  const [updateUser, { isLoading: updateUserIsLoading }] =
    useUpdateUserMutation();
  const { refetch: userDataRefetch } = useGetUserQuery(user?.id);

  const { data: getProfileImage, refetch: getProfileImageRefetch } =
    useGetProfileImageQuery({ userId: user?.id });

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: `${process.env.REACT_APP_BACKEND_BASE_URL}users/upload-image/${user?.id}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        getProfileImageRefetch();
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // const [updateUserProfileImg] = useUpdateUserProfileImgMutation()

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    //
    try {
      updateUser({ userId: user?.id, ...values })
        .then((res) => {
          userDataRefetch();
          successMessage(
            res?.data?.message || "Information saved successfully"
          );
        })
        .catch(() => {
          errorMessage("Failed to update information");
        });
    } catch (e: any) {}
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {};

  // const handleFileSelect = () => {
  //     if (fileInputRef.current && fileInputRef.current.files) {
  //         const file = fileInputRef.current.files[0];
  //         if (file && file.type.startsWith('image/')) {
  //             // Do something with the selected image file
  //             setAvatar(file)
  //             const selectedImage = document.querySelector('.selectedImage');
  //             const reader = new FileReader();

  //             reader.onload = function () {
  //                 if (!selectedImage) return
  //                 // @ts-ignore
  //                 selectedImage.src = reader.result;
  //             };

  //             if (file) {
  //                 reader.readAsDataURL(file);
  //                 // Display the path of the selected image

  //                 const formData = new FormData();
  //                 formData.append('file', file);

  //                 setImagePath(URL.createObjectURL(file));

  //                 updateUserProfileImg({ userId: user?.id, formData }).then((res: any) => {
  //                     userDataRefetch();
  //                     successMessage("Profile Image updated successfully");
  //                 }).catch((err) => {
  //                     errorMessage("Failed to update profile image");
  //                 });
  //             }
  //         }
  //     }
  // };

  return (
    <div className="p-4">
      <h3 className="text-3xl font-semibold">Settings</h3>

      <div className="mt-6">
        <Form
          layout="horizontal"
          form={form}
          initialValues={{
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
            linkedin_profile: user?.linkedin_profile,
            bio: user?.bio,
            bio_link: user?.bio_link,
            password: "",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex w-full justify-center">
            <div className="w-1/5">
              <h6 className="text-sm font-semibold text-grey700">Your photo</h6>
              <p className="text-sm text-grey600">
                This will be displayed on your profile
              </p>
            </div>
            <div className="w-3/5 flex gap-x-5 items-start">
              <div className="">
                <Avatar
                  className={"selectedImage"}
                  size={96}
                  src={getProfileImage?.url || defaultProfile}
                />
              </div>

              <Dragger {...props} className="bg-transparent w-full">
                <div className="ant-upload-drag-icon flex w-full justify-center items-center ">
                  <div className="size-10 bg-grey50 rounded-full flex justify-center items-center">
                    <FiUploadCloud size={20} />
                  </div>
                </div>
                <p className="ant-upload-text">
                  <span className="text-primary700 font-semibold">
                    Click to upload
                  </span>{" "}
                  or drag and drop
                </p>
                <p className="ant-upload-hint">SVG, PNG, JPG</p>
              </Dragger>
            </div>
          </div>

          <div className="flex w-full justify-center items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Name</p>
            </div>
            <div className="flex w-3/5 justify-between gap-x-6 items-center">
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="first_name"
                rules={[
                  { required: true, message: "Please input your first name!" },
                ]}
                className="w-full"
              >
                <Input placeholder="First Name" className="h-11" />
              </Form.Item>
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="last_name"
                className="w-full"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input placeholder="Last Name" className="h-11" />
              </Form.Item>
            </div>
          </div>

          <hr />

          <div className="flex w-full justify-center items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Email address</p>
            </div>
            <div className="w-3/5">
              <Form.Item name={"email"} hasFeedback label="" className="w-full">
                <Input
                  disabled
                  prefix={<Mail className="size-5" />}
                  className="h-11"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex w-full justify-center items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">LinkedIn Account</p>
            </div>
            <div className="w-3/5">
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="linkedin_profile"
                rules={[
                  { required: true, message: "Whats your linkedIn profile?" },
                ]}
              >
                <Input
                  placeholder="LinkedIn profile"
                  prefix={<SiLinkedin className="size-5 text-[#0A66C2]" />}
                  className="h-11"
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex w-full justify-center items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Country</p>
            </div>
            <div className="w-3/5">
              <Form.Item
                name="country"
                // label="Monthly Recurring Revenue"

                label={""}
              >
                <Select placeholder="Select Country" showSearch>
                  <Option value={""}>Select country</Option>
                  {Countries.map((item) => (
                    <Option key={item.code} value={item.name}>
                      {item.emoji} {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <hr />

          <div className="flex w-full justify-center items-start my-5">
            <div className="w-1/5">
              <p className="text-grey700 font-semibold">Change your password</p>
            </div>
            <div className="w-3/5">
              <Form.Item<FieldType>
                hasFeedback
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Cannot leave password field empty",
                  },
                ]}
              >
                <Input
                  placeholder="Enter new Password"
                  type="password"
                  prefix={<CiLock className="size-5" />}
                  className="h-11"
                />
              </Form.Item>
            </div>
          </div>
          <hr />

          <div className="mt-5 flex w-full justify-end gap-x-4">
            <Form.Item>
              <Button
                disabled={updateUserIsLoading}
                loading={updateUserIsLoading}
                type="primary"
                htmlType="submit"
                className="bg-primary600 hover:opacity-90 hover:bg-primary600"
              >
                Save
              </Button>
            </Form.Item>
          </div>

          {/* <div style={{ marginTop: 25, paddingBottom: 10 }}>
                        <div style={{ fontSize: 14, color: '#495057', fontWeight: '500' }}>
                            Optional Demographic Information Form
                        </div>
                        <div style={{ fontSize: 14, color: '#868E96', marginTop: 5, marginBottom: 10 }}>
                            Filling the form is entirely optional, but useful for generating the best matches.
                        </div>

                        <img
                            src={fillForm}
                            alt={'fillForm'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/settings')
                            }} />
                    </div> */}
        </Form>

        <div className="flex flex-col">
          <h2 className="font-semibold text-[24px] text-gray-900 mb-4">
            Subscription Details
          </h2>
          <div className="flex items-center w-full lg:w-1/3 md:w-1/2 justify-between py-2">
            <p className="text-[16px] text-black">Subscription Type:</p>
            <p className="text-[16px] text-gray-600">Free Plan</p>
          </div>
          <hr />

          <div className="flex items-center w-full lg:w-1/3 md:w-1/2 justify-between py-2">
            <p className="text-[16px] text-black">Subscription Status:</p>
            <p className="text-[16px] text-gray-600">Active</p>
          </div>
          <hr />

          <div className="flex items-center w-full lg:w-1/3 md:w-1/2 justify-between py-2">
            <p className="text-[16px] text-black">Subscription Expiration:</p>
            <p className="text-[16px] text-gray-600">Nov 14, 2024</p>
          </div>

          <div className="w-full flex justify-end gap-x-4">
            <button
              className="bg-[white] rounded-md text-gray-700 text-[14px] p-2 font-semibold border border-gray-300 transition-all duration-300"
              style={{ boxShadow: "0px 1px 2px 0px #0A0D120D" }}
            >
              Cancel Subscription
            </button>

            <button
              className="bg-[#1980b3] rounded-md text-white text-[14px] font-semibold flex items-center gap-2 p-2 hover:bg-white hover:text-[#1980b3] hover:border hover:border-[#1980b3] transition-all duration-300"
              onClick={() => navigate("/billing")}
            >
              <FaArrowUp />
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
