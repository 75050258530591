import React, { FC } from "react";

type sentMessageProps = {
  message: string;
};
const SentMessage: FC<sentMessageProps> = ({ message }) => {
  return (
    <div className="flex justify-end my-2">
      <div className="max-w-lg px-4 py-2 bg-[#1980b3] text-white rounded-lg rounded-tr-none">
        <p className="text-[16px] leading-[28px] italic">"{message}"</p>
      </div>
    </div>
  );
};

export default SentMessage;
