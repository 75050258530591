import Logo from "../../../assets/logo.png";
import LandingPage from "../../../assets/landingPage.png";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useLazyGetStartUpQuery } from "../../../lib/features/startup/startupApiSlice";
import { useDispatch } from "react-redux";
import { authenticate } from "../../../lib/features/auth/authSlice";
import { setProfile } from "../../../lib/features/user/userSlice";
import { useLazyGetConversationsQuery } from "../../../lib/features/conversation/conversationApiSlice";
import { errorMessage } from "../../../constants/notification";
import { motion } from "framer-motion";
import { useSignUpWithVoiceMutation } from "../../../lib/features/auth/authApiSlice";

const voices = ["Ava", "Sofia", "James", "Ethan", "Lila"];
const PersonalInfo = () => {
  const [linkedInUrl, setLinkedInUrl] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [voice, setVoice] = useState<string>("");
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStartingConversation, setIsStartingConversation] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<{
    linkedInUrl: string;
    email: string;
    password: {
      length: boolean;
      uppercase: boolean;
      lowercase: boolean;
      number: boolean;
      specialChar: boolean;
    };
    voice: string;
  }>({
    linkedInUrl: "",
    email: "",
    password: {
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      specialChar: false,
    },
    voice: "",
  });

  const [signUpWithVoice] = useSignUpWithVoiceMutation();
  const dispatch = useDispatch();

  const [getStartUp] = useLazyGetStartUpQuery();
  const [getConversations] = useLazyGetConversationsQuery();

  const navigate = useNavigate();

  const toggleOpen = () => {
    if (!isLoading || !isStartingConversation) setOpenDropdown(!openDropdown);
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password: string) => {
    const newErrors = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[@$!%*?&]/.test(password),
    };
    return newErrors;
  };

  const handleStartConversation = async () => {
    if (voice.length === 0) {
      setErrors((prevState) => ({
        ...prevState,
        voice: "You need to select a voice",
      }));
      if (linkedInUrl.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          linkedInUrl: "Cannot leave this field empty",
        }));
      }
      if (email.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          linkedInUrl: "Cannot leave this field empty",
        }));
      }
      if (password.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          linkedInUrl: "Cannot leave this field empty",
        }));
      }
    } else {
      setIsStartingConversation(true);
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      await signUpWithVoice({
        email: email,
        password: password,
        linkedin_link: linkedInUrl,
        voice_name: voice,
      })
        .unwrap()
        .then(async (res) => {
          await getStartUp(res.userData.id);
          dispatch(authenticate(res.signinResponse.AuthenticationResult));
          dispatch(setProfile(res.userData));
          await getStartUp(res.userData.id);
          await getConversations(res.userData.id);
          localStorage.setItem(
            "REFRESH_TOKEN",
            res.signinResponse.AuthenticationResult.RefreshToken
          );

          localStorage.setItem(
            "accessToken",
            res.signinResponse.AuthenticationResult.AccessToken
          );
          localStorage.setItem("user_id", res.userData.id);
          localStorage.setItem("user_data", res.userData);
          setEmail("");
          setPassword("");
          setLinkedInUrl("");
          setVoice("");
          navigate("/get-started/questions", { replace: true });
        })
        .catch((error: any) => {
          console.log("error:", error);
          errorMessage(error?.data?.detail ?? "Error! Try again");
        })
        .finally(() => {
          setIsStartingConversation(false);
        });
    }
  };
  return (
    <div className="flex flex-col h-screen bg-white">
      <nav className="flex items-center justify-between pt-6 pl-4 md:pl-12 lg:pl-32">
        <img
          src={Logo}
          alt="Logo"
          className="h-8 cursor-pointer"
          onClick={() => navigate("/")}
        />
      </nav>

      <div className="flex-grow flex justify-center mt-8">
        <div className="w-full flex flex-col w-full md:w-[530px] p-2">
          <div className="flex items-center gap-4">
            <img
              src={LandingPage}
              className="w-[64px] h-[75px]"
              alt="landingPage"
            />
            <h2 className="text-[16px] md:text-[24px] leading-[32px]">
              Can we get your LinkedIn and Email?
            </h2>
          </div>

          <motion.div
            className="mt-4"
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 50, damping: 20 }}
          >
            <div className="flex flex-col items-start gap-2">
              <p className="mt-2 text-gray-700 text-[14px] md:text-[20px] leading-[30px] font-medium">
                Please Enter your LinkedIn:
              </p>
              <input
                placeholder="https://www.linkedin.com/in/john-doe"
                className="text-primary border-none w-full p-2 outline-none disabled:cursor-not-allowed"
                style={{ borderBottom: "2px solid #1980B3" }}
                value={linkedInUrl}
                onChange={(e) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    linkedInUrl: "",
                  }));
                  setLinkedInUrl(e.target.value);

                  if (e.target.value.length === 0) {
                    setErrors((prevState) => ({
                      ...prevState,
                      linkedInUrl: "Cannot leave this field empty",
                    }));
                  }
                }}
                disabled={isLoading || isStartingConversation}
              />
              {errors.linkedInUrl && (
                <span className="mt-2 text-red-500">{errors.linkedInUrl}</span>
              )}
            </div>

            <div className="flex flex-col items-start gap-2 mt-4">
              <p className="mt-2 text-gray-700 text-[14px] md:text-[20px] leading-[30px] font-medium">
                Please Enter your Email:
              </p>
              <input
                placeholder="test@gmail.com"
                className="text-primary600 border-none w-full p-2 outline-none disabled:cursor-not-allowed"
                style={{ borderBottom: "2px solid #1980B3" }}
                value={email}
                type="email"
                onChange={(e) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    email: "",
                  }));
                  setEmail(e.target.value);
                  if (e.target.value.length === 0) {
                    setErrors((prevState) => ({
                      ...prevState,
                      email: "Cannot leave this field empty",
                    }));
                  }

                  if (!validateEmail(e.target.value)) {
                    setErrors((prevState) => ({
                      ...prevState,
                      email: "Invalid Email Address",
                    }));
                  }
                }}
                disabled={isLoading || isStartingConversation}
              />
              {errors.email && (
                <span className="mt-2 text-red-500">{errors.email}</span>
              )}
            </div>

            <div className="flex flex-col items-start gap-2 mt-4">
              <p className="mt-2 text-gray-700 text-[14px] md:text-[20px] leading-[30px] font-medium">
                Please Enter your Password:
              </p>
              <input
                placeholder="Password"
                className="text-primary600 border-none w-full p-2 outline-none disabled:cursor-not-allowed"
                style={{ borderBottom: "2px solid #1980B3" }}
                value={password}
                type="password"
                onChange={(e) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    password: {
                      length: false,
                      uppercase: false,
                      lowercase: false,
                      number: false,
                      specialChar: false,
                    },
                  }));
                  setPassword(e.target.value);

                  setErrors((prevState) => ({
                    ...prevState,
                    password: { ...validatePassword(e.target.value) },
                  }));
                }}
                disabled={isLoading || isStartingConversation}
              />
              <div className="mt-2 text-sm">
                <p>Password must include:</p>
                <ul>
                  <li
                    className={
                      errors.password.length ? "text-green-600" : "text-red-500"
                    }
                  >
                    {errors.password.length ? "✔" : "✖"} At least 8 characters
                  </li>
                  <li
                    className={
                      errors.password.uppercase
                        ? "text-green-600"
                        : "text-red-500"
                    }
                  >
                    {errors.password.uppercase ? "✔" : "✖"} At least 1 uppercase
                    letter
                  </li>
                  <li
                    className={
                      errors.password.lowercase
                        ? "text-green-600"
                        : "text-red-500"
                    }
                  >
                    {errors.password.lowercase ? "✔" : "✖"} At least 1 lowercase
                    letter
                  </li>
                  <li
                    className={
                      errors.password.number ? "text-green-600" : "text-red-500"
                    }
                  >
                    {errors.password.number ? "✔" : "✖"} At least 1 number
                  </li>
                  <li
                    className={
                      errors.password.specialChar
                        ? "text-green-600"
                        : "text-red-500"
                    }
                  >
                    {errors.password.specialChar ? "✔" : "✖"} At least 1 special
                    character (@, $, !, %, *, ?, &)
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col items-start gap-2 mt-4">
              <p className="mt-2 text-gray-700 text-[14px] md:text-[20px] leading-[30px] font-medium">
                Choose your favorite Voice:
              </p>
              <div className="relative w-full mt-2 text-primary600">
                <div
                  className={`w-full p-2 pr-6 ${
                    openDropdown ? "rounded-t-lg" : "rounded-lg"
                  } cursor-pointer border-2 border-primary600 h-[48px]`}
                  onClick={toggleOpen}
                >
                  {voice || "Choose your Favorite Voice"}
                </div>
                {isLoading ? (
                  <div className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer">
                    <Spin />
                  </div>
                ) : (
                  <div
                    className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
                    onClick={toggleOpen}
                  >
                    {openDropdown ? (
                      <FaChevronUp color="#1980B3" />
                    ) : (
                      <FaChevronDown color="#1980B3" />
                    )}
                  </div>
                )}
                <div
                  className={`z-100 absolute top-[100%] w-full ${
                    openDropdown ? "max-h-60" : "max-h-0"
                  } overflow-hidden duration-150 ease-linear`}
                >
                  <div className="flex flex-col border-l-2 border-r-2 border-b-2 border-primary600 rounded-b-lg mb-4">
                    {voices.map((item, index) => (
                      <div key={index}>
                        <div
                          className={` p-2 ${
                            voice === item
                              ? "bg-primary600 text-white"
                              : "text-primary600"
                          } hover:bg-primary600 hover:text-white cursor-pointer
                                    ${
                                      index === 0
                                        ? "border-b-2 border-primary600"
                                        : index === voices.length - 1
                                        ? "rounded-b-lg"
                                        : "rounded-none border-b-2 border-primary600"
                                    }
                                    
                                    `}
                          onClick={async () => {
                            setErrors((prevState) => ({
                              ...prevState,
                              voice: "",
                            }));
                            toggleOpen();
                            setVoice(item);

                            try {
                              setIsLoading(true);
                              const audioUrl = `/audios/${item}.wav`;
                              if (currentAudio) {
                                currentAudio.pause();
                                currentAudio.currentTime = 0;
                              }
                              const audio = new Audio(audioUrl);
                              setCurrentAudio(audio);
                              audio.play();
                            } catch (error) {
                            } finally {
                              setIsLoading(false);
                            }
                          }}
                        >
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {errors.voice && (
                <span className="mt-2 text-red-500">{errors.voice}</span>
              )}
            </div>
          </motion.div>

          {!openDropdown && (
            <motion.button
              disabled={isLoading || isStartingConversation}
              onClick={handleStartConversation}
              className="text-base font-semibold bg-primary600 w-[220px] text-center text-white rounded-lg py-4 px-7 self-end mt-8 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500"
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 50, damping: 20 }}
            >
              {isStartingConversation ? (
                <Spin style={{ color: "white" }} />
              ) : (
                "Start Conversation"
              )}
            </motion.button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
