import { FC, useEffect, useRef, useState } from "react";
import { IoClose, IoMicOutline } from "react-icons/io5";
import LandingPage from "../assets/landingPage.png";
import { Modal, Spin } from "antd";
import { useAppSelector } from "../lib/hooks";
import { RootState } from "../lib/store";
import { useAiCopilotMutation } from "../lib/features/ai_agent/aiAgentApiSlice";

type conversationModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const mimeType = "audio/webm";

const ConversationModal: FC<conversationModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const [permissions, setPermissions] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [startRecording, setStartRecording] = useState(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const userId = useAppSelector((state: RootState) => state.user?.id);
  const startupId = useAppSelector((state: RootState) => state.startup?.id);

  const [aiCopilot, { isLoading }] = useAiCopilotMutation();

  const uploadAudio = async (blob: Blob) => {
    const formData = new FormData();

    if (blob) {
      formData.append("user_audio", blob, "recording.webm");
    }

    try {
      await aiCopilot(formData).then((res) => {
        const base64Audio = res.data.ai_response_voice;

        const byteCharacters = atob(base64Audio);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const byteArray = new ArrayBuffer(1024);
          const byteArrayView = new Uint8Array(byteArray);

          for (let i = 0; i < byteArrayView.length; i++) {
            byteArrayView[i] = byteCharacters.charCodeAt(offset + i);
          }

          byteArrays.push(byteArrayView);
        }

        const audioBlob = new Blob(byteArrays, {
          type: "audio/mpeg",
        });
        const audioUrl = URL.createObjectURL(audioBlob);

        const audio = new Audio(audioUrl);

        setIsAnimating(true);

        audio.onended = () => setIsAnimating(false);

        audio.play();
        console.log("response:", res);
      });
    } catch (error) {
      console.error("Error submitting response:", error);
    }
  };

  const getMicrophonePermissions = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setPermissions(true);
      setStream(streamData);
    } catch (error: any) {
      alert(error.message || "Failed to get microphone permissions.");
    }
  };

  const handleStartRecording = () => {
    if (!stream) return;
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    media.start();
    setStartRecording(true);

    const chunks: Blob[] = [];
    media.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    media.onstop = () => {
      const audioBlob = new Blob(chunks, { type: mimeType });
      uploadAudio(audioBlob);
    };
  };

  const handleStopRecording = () => {
    if (mediaRecorder.current && startRecording) {
      mediaRecorder.current.stop();
      setStartRecording(false);
    }
  };

  useEffect(() => {
    getMicrophonePermissions();
  }, []);

  return (
    <Modal
      title={<p>Talk to your AI Pilot</p>}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <div className="h-full w-full">
        <input type="file" name="ai-audio" hidden ref={fileRef} />
        <div className="flex-grow flex mt-20 md:mt-36">
          <div className="w-full flex flex-col justify-between items-center px-2">
            <div className="bg-[#f7fcff] flex items-center justify-center rounded-full w-[264px] h-[264px]">
              <img
                src={LandingPage}
                alt="landingPage"
                className={`h-[189px] w-[158px] ${
                  startRecording
                    ? "animate-pulse"
                    : isAnimating
                    ? "animate-bounce"
                    : ""
                }`}
              />
            </div>
            <div className="h-[158px] w-full flex items-center justify-center border-t border-primary600">
              {isLoading && <Spin />}
              {!permissions && !isLoading && (
                <button
                  onClick={getMicrophonePermissions}
                  className="bg-white border border-primary600 rounded-lg flex items-center p-4 gap-2 cursor-pointer hover:bg-primary400 transition-all duration-300 hover:text-white hover:border-primary400"
                >
                  Get Permissions
                </button>
              )}
              <div className="flex gap-4">
                {permissions && !startRecording && !isLoading && (
                  <div
                    onClick={handleStartRecording}
                    className="bg-primary600 w-[64px] h-[64px] text-white rounded-full flex items-center justify-center cursor-pointer"
                  >
                    <IoMicOutline size={24} />
                  </div>
                )}
                {permissions && startRecording && !isLoading && (
                  <button
                    onClick={handleStopRecording}
                    className="bg-[#e4e5e7] w-[64px] h-[64px] rounded-full flex items-center justify-center cursor-pointer"
                  >
                    <IoClose size={24} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConversationModal;
