import { apiSlice } from "../../services/apiSlice";

export const aiAgentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    aiCompleteProfile: builder.mutation({
      query: (data: any) => ({
        url: `ai_agent/ai_conversational_agent/`,
        method: "POST",
        body: data,
      }),
    }),
    aiFAQ: builder.mutation({
      query: (data: { user_id: string | undefined; question: string }) => ({
        url: `ai_agent/ai_faq_agent/`,
        method: "POST",
        body: {
          user_id: data.user_id,
          question: data.question,
        },
      }),
    }),
    getSingleAIConversation: builder.query({
      query: (data) => ({
        url: `ai_agent/conversation/${data.userID}/`,
        method: "GET",
      }),
    }),
    getSingleAIFAQConversation: builder.query({
      query: (data) => ({
        url: `ai_agent/faq_conversation/${data.userID}/`,
        method: "GET",
      }),
    }),
    aiCopilot: builder.mutation({
      query: (data: any) => ({
        url: `co-pilot/`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useAiCompleteProfileMutation,
  useGetSingleAIConversationQuery,
  useAiFAQMutation,
  useGetSingleAIFAQConversationQuery,
  useAiCopilotMutation,
} = aiAgentApiSlice;
