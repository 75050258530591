import React from "react";
import Info1 from "../../../assets/blog/infographics/1.jpg";
import Info2 from "../../../assets/blog/infographics/2.jpg";
import Info3 from "../../../assets/blog/infographics/3.jpg";
import { Link } from "react-router-dom";
// import One from "../../../assets/blog/1.jpeg";
// import { Link } from "react-router-dom";

function Inclusive() {
  return (
    <div className="container mb-20">
      <h1 className="text-center md:text-5xl text-2xl font-bold text-primary600 font-paytone">
        The Inclusive <span className="text-[#EDC10C]">Gold</span> List: Top
        Venture Capitalists and Accelerator Heads Who Have Sent the Wire or Made
        the Hire
      </h1>
      <p className="md:text-xl mt-12">
        Now more than ever, it’s essential that we amplify the voices and
        resources that empower under-networked founders. As some push for
        Diversity, Equity and Inclusion (DEI) programs to become a relic of the
        past and funding for under-networked founders is in deep decline,
        knowing whom we can trust, who stands with us, and where we can find
        support has never been more critical. That’s why we’re spotlighting the
        dedicated champions driving change and uplifting under-networked
        founders across diverse categories. These champions – from Brian
        Brackeen at Lightship Capital investing in overlooked founders in the
        Midwest to Arian Simone’s historic efforts at Fearless Fund — are
        focused on building empires of generational wealth. Visionaries like
        Miriam Rivera of Ulu Ventures, Samara Mejia Hernandez of Chingona
        Ventures, and Stephanie Campbell of The Artemis Fund are paving roads
        for future generations to follow, proving that inclusive innovation is
        not an oxymoron.
      </p>
      <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
        The Landscape of Funding for Under-Networked Founders
      </h3>
      <p className="md:text-xl">
        Over the past five years, venture capital (VC) funding for diverse
        founders has seen both the best and worst of times. The social justice
        movements of 2020 led to a surge in DEI commitments, peaking in 2021.
        But funding rates have since dropped significantly, coinciding with
        economic contraction and political pushback against DEI initiatives.
        <br />
        Here’s a snapshot of the data:
      </p>
      <ul className="list-disc mt-4 pl-7 md:text-xl">
        <li>
          Black Founders: Received 0.48% of VC funding in 2023, down from 1.3%
          in 2021.
        </li>
        <li>
          Latino Founders: Received approximately 1% of VC funding in 2023, down
          from 2% in 2021.
        </li>
        <li>
          Female Founders: Secured just 2% of all VC funding in 2023, a decline
          from 2.4% in 2019.
        </li>
        <li>
          LGBTQIA Founders: Funding data remains scarce, but estimates suggest
          less than 0.5% of total VC funding goes to openly LGBTQIA-led
          companies.
        </li>
        <li>
          Working-Class and Rural Founders: Over 75% of U.S. VC funding is
          concentrated in major urban hubs.
        </li>
      </ul>
      <div className="flex flex-col items-center justify-center gap-y-2 w-full">
        <img src={Info1} alt="" className="w-2/3" />
        <img src={Info2} alt="" className="w-2/3" />
      </div>
      <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-4 mb-3">
        Why These Lists Matter Now
      </h3>
      <p className="md:text-xl">
        It’s unlikely that the next four years are going to be any better for
        under-networked founders given the national political environment. In
        this context, visibility and solidarity become our most powerful tools.
        By identifying and spotlighting the funds, investors, and institutions
        genuinely committed to supporting diverse entrepreneurs, we:
      </p>
      <ul className="list-disc mt-4 pl-7 md:text-xl">
        <li>
          Create Trustworthy Networks: Founders know who they can turn to for
          genuine support.
        </li>
        <li>
          Celebrate Inclusive Investors: Highlight those walking the talk of
          inclusion, ensuring diversity remains part of the investment agenda.
        </li>
        <li>
          Foster Resilience and Collaboration: Build a community where
          under-networked founders can uplift one another and share critical
          resources.
        </li>
      </ul>
      <div className="flex flex-col items-center justify-center">
        <img src={Info3} alt="" className="w-2/3" />
      </div>
      <h3 className="text-primary600 md:text-3xl text-xl font-paytone  mb-3">
        The Path Forward: Strength in Numbers
      </h3>
      <p className="md:text-xl">
        While the challenges are real, so is the potential for change. By
        celebrating our inclusion champions, we can drive more capital toward
        under-networked founders, unlock new opportunities for growth, and
        continue to innovate against the odds. This series of lists is a call to
        action: to recognize those doing the hard work, leverage our collective
        power, and continue fighting so that opportunity is available to anyone
        with the talent, motivation and resilience. We begin with The Black
        Vanguard List spotlighting the top 25 champions for Black founders, and
        we'll soon release additional lists highlighting supporters of Latino,
        female, LGBTQIA, working-class and rural, and other under-networked
        founders.
      </p>

      {/* founder */}
      {/* <div>
        <h1 className="text-center md:text-5xl text-2xl text-primary600 font-paytone mt-12">
          1. Gary Stewart
        </h1>
        <div className="my-12">
          <img src={One} alt="Gary Stewart" className="w-full" />
        </div>

        <div className="space-y-4">
          <div className="">
            <h3 className="text-primary600 text-xl md:text-3xl font-paytone mt-12 mb-3">
              Why we chose Gary:
            </h3>

            <p className="md:text-lg mt-2">
              Gary is a venture partner at Hearst’s Level Up Ventures and the
              former Managing Director of the Techstars New York City
              Accelerator powered by J.P. Morgan. He is a VC-backed, exited
              serial entrepreneur who has worked across the globe and whose
              European portfolio has raised more than $2 billion. He has taught
              entrepreneurship at Yale Law School and IE Business School in
              Madrid.
            </p>
          </div>

          <div className="">
            <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
              Investment Focus:
            </h3>

            <p className="text-lg mt-2">
              Gary invests in FinTech, Health Tech and AI startups at the
              pre-seed and seed stages.
            </p>
          </div>

          <div className="">
            <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
              Track record:
            </h3>

            <p className="md:text-lg mt-2">
              Gary has invested in xxx startups led by Black founders or that
              had a Black founder who has more than 25% of the startup’s equity.
            </p>
          </div>

          <div className="">
            <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
              Thesis:
            </h3>

            <p className="md:text-lg mt-2">
              XXX fund is committed to ensuring that at least [50%] of its
              portfolio companies has at least one underrepresented founder.
              Outside of questions of diversity, XXX fund invests in AI startups
              at the preseed or seed stages that have at least $25,000 in
              monthly recurring revenue.
            </p>
          </div>

          <div className="">
            <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
              Key diversity initiatives:
            </h3>

            <p className="md:text-lg mt-2">
              50% of the founders of XXX fund come from an underrepresented
              background and 60% of the employees of XXX fund come from an
              underrepresented category. <br />
              Founders rave about XXX found. They have 12/12 NPS scores and
              founders have said the following: “Gary is awesome” !
            </p>
          </div>

          <div className="">
            <h3 className="text-primary600 md:text-3xl text-xl font-paytone mt-12 mb-3">
              Founder testimonial: 
            </h3>

            <p className="md:text-lg mt-2">
              Founders rave about XXX found. They have 12/12 NPS scores and
              founders have said the following:
            </p>

            <p className="mt-3 text-primary600 italic md:text-xl font-semibold border-l-2 border-l-primary600 p-3">
              "Gary is Awesome"
            </p>
          </div>
        </div>
      </div> */}

      <div className="mt-20 flex items-center justify-center">
        <Link
          to={"/blog/black_vanguard_list"}
          className=" bg-primary600 hover:bg-primary text-white py-3 px-6 rounded-lg font-medium transition-colors duration-200 text-center"
        >
          Go to the Black Vanguard List
        </Link>
      </div>
    </div>
  );
}

export default Inclusive;
