export const Races = [
  "American Indian or Alaska Native",
  "East Asian or East Asian American",
  "Black, African American, Afro-Caribbean or Afro-Latino",
  "Hispanic, Latino, Latina, or Latinx",
  "Middle Eastern or Northern African",
  "Native Hawaiian or Other Pacific Islander",
  "White or Caucasian",
  "South East Asian or South East Asian American",
  "Native American",
  "Multiracial/Multiethnic",
  "Other",
  "Prefer not to say",
  "Not Specified",
];

export const Genders = [
  "Male",
  "Female",
  "Transgender Male/Trans Man",
  "Transgender Female/Trans Woman",
  "Non-binary",
  "Genderqueer",
  "Gender non-confirming",
  "Two-Spirit",
  "Other",
  "Prefer not to say",
  "Not Specified",
];

export const ProductStagess = [
  {
    stage: "Pre-seed",
    tip: "Your product is in the initial concept phase, where you're focusing on idea validation, market research, and possibly building a prototype.",
  },
  {
    stage: "Seed",
    tip: "You've moved beyond the concept stage and are now developing the product, securing initial funding, and possibly starting to build a small team",
  },
  {
    stage: "Series A",
    tip: "Your product is gaining traction with users or customers, and you're securing further funding to expand development and operations.",
  },
  {
    stage: "Series B",
    tip: "Your product is in the growth phase, focusing on scaling, increasing market share, and potentially preparing for large-scale expansion or additional rounds of investment.",
  },
];

export const Industries = [
  "AI",
  "Fintech",
  "Healthtech",
  "Edtech",
  "Cleantech",
  "E-commerce",
  "Marketplace",
  "Cybersecurity",
  "Web3",
  "Enterprise software",
  "Gaming",
  "Robotics",
  "Cloud",
  "Biotech",
  "Fashion",
  "Other",
  "Not Specified",
];

export const MRR = [
  "Under $15,000",
  "Between $15,000-$50,000",
  "Between $50,000-$100,000",
  "$100,000+",
  "$500,000+",
  "$1,000,000",
];

export const Users = [
  "0-100",
  "101-1000",
  "1001-50000",
  "50001-100000",
  "100001-500000",
  "500001-1000000",
  "over 1 million",
];
export const Funds_Raised = [
  "$0-$100,000",
  "$100,000-$250,000",
  "$250,000-$500,000",
  "$500,000-$1,000,000",
  "$1,000,000-$2,500,000",
  "$2,500,000-$5,000,000",
  "$5,000,000-$10,000,000",
  "Over $10,000,000",
];

export const Entrepreneurial_Experience = [
  "First-Time founder",
  "Serial entrepreneur",
  "Exited entrepreneur",
  "VC-backed entrepreneur",
  "Previous operator experience in a VC-backed start-up",
  "Previous operator experience in a VC-backed, pre-IPO start-up",
  "Technical co-founder with at least a 10 percent equity stake",
  "Not Specified",
];

export const Nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "Andorran",
  "Angolan",
  "Argentine",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Botswanan",
  "Brazilian",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djiboutian",
  "Dominican",
  "Ecuadorian",
  "Egyptian",
  "Salvadoran",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Eswatini",
  "Ethiopian",
  "Fijian",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinean",
  "Bissau-Guinean",
  "Guyanese",
  "Haitian",
  "Honduran",
  "Hungarian",
  "Icelandic",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakh",
  "Kenyan",
  "I-Kiribati",
  "North Korean",
  "South Korean",
  "Kosovar",
  "Kuwaiti",
  "Kyrgyz",
  "Lao",
  "Latvian",
  "Lebanese",
  "Basotho",
  "Liberian",
  "Libyan",
  "Liechtenstein",
  "Lithuanian",
  "Luxembourgish",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Montenegrin",
  "Moroccan",
  "Mozambican",
  "Burmese",
  "Namibian",
  "Nauruan",
  "Nepali",
  "Dutch",
  "New Zealander",
  "Nicaraguan",
  "Nigerien",
  "Nigerian",
  "North Macedonian",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Palestinian",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Filipino",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Kittitian",
  "Lucian",
  "Vincentian",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovak",
  "Slovene",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Sudanese",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamese",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Timorese",
  "Togolese",
  "Tongan",
  "Trinidadian",
  "Tunisian",
  "Turkish",
  "Turkmen",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Emirati",
  "British",
  "American",
  "Uruguayan",
  "Uzbek",
  "Vanuatuan",
  "Vatican",
  "Venezuelan",
  "Vietnamese",
  "Yemeni",
  "Zambian",
  "Zimbabwean",
];

export const Ethnicities = [
  "African",
  "African American",
  "Afro-Caribbean",
  "Arab",
  "Asian",
  "East Asian",
  "South Asian",
  "Southeast Asian",
  "Central Asian",
  "European",
  "Eastern European",
  "Western European",
  "Northern European",
  "Southern European",
  "Hispanic",
  "Latino/Latina",
  "Middle Eastern",
  "Native American",
  "Indigenous Australian",
  "Pacific Islander",
  "Polynesian",
  "Melanesian",
  "Micronesian",
  "Caribbean",
  "Jewish",
  "Ashkenazi",
  "Sephardic",
  "Mizrahi",
  "Mixed Ethnicity",
  "Other",
];
