import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../components/Navbar/Navbar";
import { SidebarProvider } from "../hooks/use-sidebar";
import LandingPage from "../assets/landingPage.png";
import ConversationModal from "../components/ConversationModal";

// import Topbar from '../components/Navbar/Topbar';

const Layout = ({ showNavbar }: { showNavbar?: boolean }) => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <SidebarProvider>
      {/* <Topbar /> */}
      <div className="lg:flex h-[screen] bg-white overflow-hidden">
        {showNavbar && <Navbar />}

        <div className="rounded-2xl w-full shadow-[0px 10px 15px -3px rgba(0,0,0,0.1)] peer-[[data-state=open]]:lg:ml-[280px] peer-[[data-state=closed]]:lg:ml-[90px]  peer-[[data-state=closed]]:opacity-100 peer-[[data-state=closed]]:lg:opacity-100 peer-[[data-state=open]]:lg:opacity-100 transition-all">
          {!location?.pathname.includes("answer") && (
            <div className="flex w-full items-center justify-end py-4 px-6">
              <div
                onClick={() => setOpenModal(true)}
                className="bg-white border border-primary600 rounded-lg flex items-center p-4 gap-2 cursor-pointer hover:bg-primary400 transition-all duration-300 hover:text-white hover:border-primary400"
              >
                <img
                  src={LandingPage}
                  alt="AI-logo"
                  className="h-8 cursor-pointer"
                />
                <p>Talk to your AI Pilot</p>
              </div>
            </div>
          )}
          <Outlet />
        </div>
        <ConversationModal isOpen={openModal} setIsOpen={setOpenModal} />
      </div>
    </SidebarProvider>
  );
};

export default Layout;
