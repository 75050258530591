import React, { useCallback } from "react";
import { InvestorCard } from "../../Recommendation/components/InvestorCard";
import Kofi from "../../../assets/KofiAmpadu.jpg";
import Shashi from "../../../assets/ShashiSrikantan.jpg";
import Fabian from "../../../assets/Fabian.png";
import iRecommendation from "../../../assets/svg/IRecommendations.svg";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { defaultProfile } from "../../../constants/defaults";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useGetFunnelInvestorsQuery,
  useGetRecommendationsQuery,
} from "../../../lib/features/recommendations/recommendations";
import { calculatePercentage } from "../../../utils/calculatePercentage";

export const dummyReason: string[] = [
  "The VC fund is an excellent match for your startup as it aligns perfectly with your current stage and sector. The fund's focus on fertility tech and digital health solutions complements your platform's mission to provide a patient-centered, cost-saving, and hassle-free fertility clinic. Furthermore, the shared educational background and geographical proximity between you and the VC enhance the potential for a strong, collaborative relationship.",

  "Fabian is an excellent match for you as a venture capitalist due to several compelling reasons. Firstly, Fabian is offering a stage that matches your startup's requirements, ensuring that your application receives the appropriate level of support and investment at this critical early stage. Additionally, the sector of Fabian's fund, aligns perfectly with your startup's sector, providing a strong foundation for mutual growth and success. The fund's alignment with your startup's profile description further underscores its suitability, as Fabian's expertise in healthcare and life sciences will be invaluable in driving the patient-centered, cost-saving, and hassle-free solutions your platform offers. Lastly, the recommendation for Fabian as a VC aligns with the shared cultural background between you two, fostering a deeper connection and understanding that can enhance collaboration and trust. These factors collectively make Fabian Ronisky a highly suitable venture capitalist for your startup.",

  "Shashi Srikantan is an excellent match for you due to several key factors. Firstly, the stage of investment that Shashi offers aligns perfectly with the requirements of your startup, ensuring that you receive the appropriate level of support and resources. Additionally, the sector focus of Shashi's fund is in harmony with your startup's sector, which means that she has a deep understanding of the industry and can provide valuable insights and guidance. Lastly, the fund is highly recommended because it aligns well with your startup's profile description, making it a strategic fit for your business needs. Shashi's extensive experience and background further reinforce her capability to support your innovative application.",
];

function Overview({ setTab }: { setTab: any }) {
  const navigate = useNavigate();
  const [recommendationCard, setRecommendationCard] = React.useState<any>(null);
  const user = useAppSelector((state: RootState) => state.user);
  const startupData = useAppSelector((state: RootState) => state.startup);

  const { data: vcRecommendations } = useGetRecommendationsQuery(user?.id);

  const { data: funnelRecommendations, isLoading } = useGetFunnelInvestorsQuery(
    user?.id
  );

  const shortlist = funnelRecommendations?.shortlist || [];

  const userRecommendations = vcRecommendations?.vcs_list || [];
  const userRecommendationsInvestors = vcRecommendations?.investors_list || [];

  const calculateNonEmptyPercentage = useCallback(() => {
    const val = calculatePercentage(user, startupData);
    return val;
  }, [startupData, user]);

  return (
    <div>
      <div className="bg-white w-full rounded-2xl pt-8">
        <h1 className="text-xl font-semibold">
          {parseInt(calculateNonEmptyPercentage()) > 70
            ? "Welcome back,"
            : "Hello"}{" "}
          {user?.first_name}
        </h1>

        {startupData.name_of_start_up && (
          <p className=" py-2">{startupData.name_of_start_up}'s progress</p>
        )}

        <div className="grid xl:grid-cols-4 grid-cols-2 gap-10 mt-4">
          <div
            className="p-5 w-full text-left border border-grey200 rounded-lg relative"
            onClick={() => {
              setTab("matches");
            }}
          >
            <p className="font-semibold text-grey900 text-base">Matches</p>
            <p className="text-grey900 text-3xl font-bold py-2">
              {funnelRecommendations?.ai_matches?.length ?? 0}
            </p>
          </div>

          <div
            className="p-5 w-full text-left border border-grey200 rounded-lg relative"
            onClick={() => {
              setTab("shortlist");
            }}
          >
            <p className="font-normal text-base">Shortlist</p>
            <p className="text-grey900 text-3xl font-bold py-2">
              {funnelRecommendations?.shortlist?.length ?? 0}
            </p>
          </div>

          <div
            className="p-5 w-full text-left border border-grey200 rounded-lg relative"
            onClick={() => {
              setTab("reached");
            }}
          >
            <p className="font-normal text-base">Reached out</p>
            <p className="text-grey900 text-3xl font-bold py-2">
              {funnelRecommendations?.reached_out?.length ?? 0}
            </p>
          </div>

          <div className="p-5 w-full text-left border border-grey200 rounded-lg relative">
            <p className="font-normal text-base">Views</p>
            <p className="text-grey900 text-3xl font-bold py-2">{"--"}</p>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className=" w-full pt-8 pb-14">
          <div className="flex items-center gap-x-3 mb-4">
            <h1 className="text-xl font-semibold">
              {userRecommendations.length > 0 ? "Funder" : "Sample"}{" "}
              Recommendations
            </h1>
          </div>

          {userRecommendations.length > 0 ? (
            <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 mt-4">
              {[...userRecommendations]
                .sort((a: any, b: any) => b.Score - a.Score)
                .slice(0, 3)
                .map((key: any, index: number) => {
                  const recommendation = key;
                  return (
                    <InvestorCard
                      key={index}
                      index={index}
                      id={recommendation?.id}
                      // avatar={defaultProfile}
                      avatar={
                        recommendation?.metadata?.ProfilepicturesinS3 ||
                        defaultProfile
                      }
                      Reason={recommendation.Reason}
                      name={recommendation.Name}
                      fund={recommendation.FundName}
                      stages={recommendation?.metadata?.Stageofinvestment}
                      sectors={recommendation?.metadata?.Sector}
                      email={recommendation?.metadata?.Email}
                      matchLevel={recommendation?.Score.toString()}
                      bio={recommendation?.metadata?.Longerbio}
                      fundwebsite={recommendation?.metadata?.Fundwebsite}
                      linkedin={recommendation?.metadata?.LinkedInprofile}
                      loading={false}
                      active={true}
                      setRecommendationCard={setRecommendationCard}
                      recommendationCard={recommendationCard}
                      shortlist={shortlist.includes(recommendation?.id)}
                    />
                  );
                })}
              {[...userRecommendationsInvestors]
                .sort((a: any, b: any) => b.Score - a.Score)
                .slice(0, 50)
                .map((key: any, index: number) => {
                  const recommendation = key;
                  return (
                    <InvestorCard
                      key={index - userRecommendationsInvestors.length}
                      index={index - userRecommendationsInvestors.length}
                      id={recommendation?.id}
                      // avatar={defaultProfile}
                      tag={true}
                      avatar={
                        recommendation?.metadata?.ProfilepictureinS3 ||
                        defaultProfile
                      }
                      Reason={recommendation.Reason}
                      name={recommendation.Name}
                      fund={recommendation.FundName}
                      stages={recommendation?.metadata?.Stageofinvestment}
                      sectors={recommendation?.metadata?.Sector}
                      email={recommendation?.metadata?.Email}
                      matchLevel={recommendation?.Score.toString()}
                      bio={recommendation?.metadata?.Longerbio}
                      fundwebsite={recommendation?.metadata?.Fundwebsite}
                      linkedin={recommendation?.metadata?.LinkedInprofile}
                      loading={false}
                      active={true}
                      setRecommendationCard={setRecommendationCard}
                      recommendationCard={recommendationCard}
                      shortlist={shortlist.includes(recommendation?.id)}
                    />
                  );
                })}
            </div>
          ) : (
            <>
              <div className="grid xl:grid-cols-3 md:grid-cols-2 mt-4">
                <InvestorCard
                  key={-3000}
                  index={-3000}
                  avatar={Kofi}
                  name={"Kofi Ampadu"}
                  fund={"a16z (partner at TxO)"}
                  stages={"Pre-seed, seed"}
                  sectors={""}
                  Reason={dummyReason[0]}
                  email={"N/A"}
                  matchLevel={"5"}
                  bio={
                    " Kofi Ampadu, a Partner at Andreessen Horowitz (a16z) and the Head of the Talent x Opportunity Initiative (TxO), plays a pivotal role in driving innovation and supporting visionary entrepreneurs. With a focus on fostering diversity and inclusion in the entrepreneurial landscape, Kofi Ampadu leads the TxO program, which collaborates with promising companies and founders to develop products and services inspired by unique cultural insights. As the Head of TxO at Andreessen Horowitz, Kofi Ampadu works closely with founders to address significant challenges, ranging from wellness through video games to delivering affordable internet service to underserved communities. His commitment to empowering entrepreneurs and promoting innovative solutions underscores his dedication to driving positive change and supporting underrepresented voices in the tech and startup ecosystem."
                  }
                  fundwebsite={"https://a16z.com"}
                  linkedin={"https://www.linkedin.com/in/kofiampadu/"}
                  loading={false}
                  setRecommendationCard={setRecommendationCard}
                  recommendationCard={recommendationCard}
                  shortlist={true}
                />
                <InvestorCard
                  key={-2000}
                  index={-2000}
                  avatar={Fabian}
                  Reason={dummyReason[1]}
                  name={"Fabián Ronisky"}
                  fund={"L'Attitude Ventures"}
                  sectors={""}
                  email={"N/A"}
                  matchLevel={"5"}
                  bio={"20"}
                  fundwebsite={"https://lattitudeventures.com"}
                  linkedin={"https://www.linkedin.com/in/fabianronisky/"}
                  loading={false}
                  setRecommendationCard={setRecommendationCard}
                  recommendationCard={recommendationCard}
                  shortlist={true}
                />

                <InvestorCard
                  key={-1000}
                  index={-1000}
                  avatar={Shashi}
                  name={"Shashi Srikantan"}
                  fund={"Level Up Ventures"}
                  Reason={dummyReason[2]}
                  stages={"Seed, Series A"}
                  sectors={""}
                  email={"shashi101@aol.com"}
                  matchLevel={"5"}
                  bio={"20"}
                  fundwebsite={"https://levelupventures.com"}
                  linkedin={
                    "https://www.linkedin.com/in/shashi-srikantan/overlay/about-this-profile/"
                  }
                  loading={false}
                  setRecommendationCard={setRecommendationCard}
                  recommendationCard={recommendationCard}
                  shortlist={true}
                />
              </div>
              {/* {userCompany &&
                                    <div className='w-full flex justify-center justify-items mt-6'>
                                        <Button type="primary" size="large" shape="round" onClick={getSemantic} loading={getSemanticLoading} disabled={getSemanticLoading || userDataIsLoading} >
                                            Get Personalized Recommendations
                                        </Button>
                                    </div>

                                } */}
            </>
          )}

          {userRecommendations &&
            Object.keys(userRecommendations).length > 0 && (
              <div className="flex justify-center mt-10">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate("/recommendation");
                  }}
                  className="px-4"
                >
                  See more
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Overview;
