import React from "react";
import { useParams } from "react-router-dom";
import {
  useGetSingleConversationQuery,
  useAskMutation,
} from "../../lib/features/conversation/conversationApiSlice";
import { RootState } from "../../lib/store";
import { useAppSelector } from "../../lib/hooks";
import { Sources } from "./components/Sources";
// import tools from '../../assets/svg/Tools.svg'
import { Connection } from "./components/Connection";
import askBtn from "../../assets/svg/askBtn.svg";
import { PulseLoader } from "react-spinners";
import { ArrowRight } from "lucide-react";
import { SearchSkeleton } from "../../components/SearchSkeleton";
import AIWhite from "../../assets/icon-light-blue.png";
import { AIMessage } from "../../components/message";
import { FaCheck } from "react-icons/fa";
import { IoCheckmark } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";

function Conversation() {
  let { id } = useParams();
  const [question, setQuestion] = React.useState("");
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const userId = useAppSelector((state: RootState) => state.user?.id);

  const { data, refetch } = useGetSingleConversationQuery({
    userID: userId,
    conversationId: id,
  });
  const [ask, { isLoading: askIsLoading }] = useAskMutation();

  const scrollToBottom = React.useCallback(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // Trigger button click
      buttonRef.current?.click();
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [data, refetch, scrollToBottom, userId]);

  // if (data) {
  //     let parsedConversation: { answer: string, relatedQuestions: string[], sources: { title: string, link: string }[] } | undefined
  //     parsedConversation = JSON.parse(data?.content?.content || "")
  // }

  return (
    <div className="h-screen w-full overflow-y-auto">
      <div className="lg:p-9 flex flex-col relative p-3" ref={scrollableDivRef}>
        <div className="flex lg:justify-end justify-center gap-x-3">
          {/* <Connection persona={data?.chatPersona} /> */}
          {/* <div style={{ marginLeft: 10 }}>
                        <img src={share} alt={'share'} />
                    </div> */}
        </div>

        {/*Answer/Question*/}
        <div className="w-full mx-auto pb-20 px-10 md:px-40">
          {data?.content.map((item: any, index: number) => {
            const isQuestion = item?.role === "user";
            const parsedConversation = !isQuestion && JSON.parse(item.content);
            const isLastItem = index === data?.content.length - 1;
            //
            return isQuestion ? (
              <>
                <div
                  style={{ marginTop: 40 }}
                  className="flex justify-end items-center"
                >
                  <p className="p-2.5 bg-primary600 w-[360px] text-[18px] text-white leading-[28px] rounded-b-md rounded-tl-md">
                    {item.content}
                  </p>
                </div>
              </>
            ) : (
              <div className="mt-5 flex items-start gap-x-2">
                <img src={AIWhite} alt="" className="my-1 w-[32px] h-[32px]" />
                <div>
                  <Sources sources={parsedConversation.sources ?? []} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <IoCheckmark size={15} />
                    <h2 className="leading-none py-2 text-[20px] font-semibold">
                      Answer
                    </h2>
                  </div>

                  {/* <div className="mt-2">{parsedConversation?.answer}</div> */}
                  <AIMessage content={parsedConversation?.answer} />

                  <div className="mt-2 flex items-center gap-2.5">
                    <LuUsers size={15} />
                    <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                      Related
                    </h2>
                  </div>
                  {parsedConversation?.relatedQuestions.map(
                    (rq: any, index: number) => (
                      <div className="flex items-center w-full" key={index}>
                        <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0 text-gray-700" />
                        <button
                          className="flex-1 justify-start px-0 py-1 h-fit "
                          value={rq}
                          onClick={() => {
                            scrollToBottom();
                            setQuestion(rq);
                            ask({
                              id: id,
                              userId: userId,
                              title: data?.title,
                              chatPersona: data?.chatPersona,
                              content: [
                                {
                                  role: "user",
                                  content: rq,
                                },
                              ],
                            })
                              .then((res) => {
                                setQuestion("");
                                refetch();
                                scrollToBottom();
                              })
                              .catch((err) => {});
                          }}
                        >
                          <p className="font-normal text-sm text-gray-700 whitespace-normal text-left">
                            {rq}
                          </p>
                        </button>
                      </div>
                    )
                  )}
                </div>
                {/*TOOLS*/}
                {/* <div style={{ marginTop: 20, }}>
                                        <img src={tools} alt={'tools'} />
                                    </div> */}
                {/* <div className='mt-5 flex items-center justify-between max-w-[810px] text-accent-foreground/50'>
                                        <div className='flex items-center gap-x-4'>
                                            <button className='flex items-center gap-x-1'>
                                                <SquareArrowOutUpRight className='size-4' />
                                                <p>Share</p>
                                            </button>
                                            <button className='flex items-center gap-x-1'>
                                                <RotateCcw className='size-4' />
                                                <p>Rewrite</p>
                                            </button>
                                        </div>

                                        <div className='flex items-center gap-x-4'>
                                            <button>
                                                <LayoutList className='size-4' />
                                            </button>
                                            <button>
                                                <Copy className='size-4' />
                                            </button>
                                            <button>
                                                <ThumbsDown className='size-4' />
                                            </button>
                                        </div>
                                    </div> */}
                <hr className="my-6 outline outline-gray-300" />
                {isLastItem && !askIsLoading && (
                  <div style={{ width: "100%", height: "18vh" }} />
                )}
              </div>
            );
          })}

          {question !== "" && (
            <>
              <div
                style={{ fontSize: 25, marginTop: 40 }}
                className="flex items-center justify-end"
              >
                <p className="p-2.5 bg-primary600 w-1/3 text-sm text-white font-bold rounded-b-md rounded-tl-md">
                  {question}
                </p>
              </div>
            </>
          )}

          {askIsLoading && (
            <>
              <SearchSkeleton />
              <div style={{ width: "100%", height: "18vh" }} />
            </>
          )}
        </div>

        <div className="fixed inset-x-0 bottom-0 py-4 w-full bg-white border-t border-gray-200">
          <div className="flex items-center justify-center ">
            <div className="lg:w-min w-full relative">
              <input
                ref={inputRef}
                onKeyDown={handleKeyDown}
                placeholder={"What do you want to know?"}
                value={question}
                onChange={({ target: { value } }) => {
                  setQuestion(value);
                }}
                style={{
                  outline: "none",

                  border: "1px solid #1980B3",
                  borderRadius: 100,
                  height: 54,
                  paddingRight: 100,
                  paddingLeft: 20,
                  fontSize: 16,
                  color: "#495057",
                }}
                className="lg:w-[68vh] w-full"
              />

              <div className="flex items-center absolute right-2.5 gap-1.5 top-2">
                {askIsLoading ? (
                  <PulseLoader
                    color="#1980B3"
                    style={{ marginTop: 10, marginRight: 5 }}
                    size={12}
                  />
                ) : (
                  <>
                    {/* <div style={{ cursor: 'pointer' }}><img src={newSessionBtn} alt={'newSessionBtn'} /></div> */}
                    <button
                      ref={buttonRef}
                      onClick={async () => {
                        scrollToBottom();
                        ask({
                          id: id,
                          userId: userId,
                          title: data?.title,
                          chatPersona: data?.chatPersona,
                          content: [
                            {
                              role: "user",
                              content: question,
                            },
                          ],
                        })
                          .then((res) => {
                            setQuestion("");
                            refetch();
                            scrollToBottom();
                          })
                          .catch((err) => {});
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={askBtn} alt={"askBtn"} />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conversation;
