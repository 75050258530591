import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeroBg from "../../../assets/heroBg.png";
import Story from "../../../assets/story.png";
import newStory from "../../../assets/new.png";
import {
  FiUsers,
  FiHeart,
  FiTrendingUp,
  FiBell,
  FiFlag,
  FiZap,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from "react-icons/fi";
import Rating from "../../../components/landing/Rating";
import LandingLayout from "../Layout";
import { Element } from "react-scroll";

const solution = [
  {
    title: "Funder Matching",
    description:
      "Leverage AI to connect with funders who align with your startup’s stage, industry, and goals, ensuring targeted outreach.",
    icon: FiUsers,
  },
  {
    title: "Personalized Pitch Deck Feedback ",
    description:
      "Get tailored suggestions to improve your pitch deck and stand out to potential investors, increasing your chances of success.",
    icon: FiHeart,
  },
  {
    title: "Competitors Analysis",
    description:
      "Understand how your startup compares to others in the market, helping you refine your strategy and approach to funders.",
    icon: FiTrendingUp,
  },
  {
    title: "Smart Notifications",
    description:
      "Stay on top of important funding deadlines and personalized reminders to follow up with potential funders",
    icon: FiBell,
  },
  {
    title: "Relationship Management",
    description:
      "Track funder interactions and manage communications to keep your fundraising process organized and efficient.",
    icon: FiFlag,
  },
  {
    title: "AI-Powered Email Writing",
    description:
      "Generate winning emails and blurbs to improve response rates and ensure your outreach is as effective as possible.",
    icon: FiZap,
  },
];

const testimonials = [
  {
    name: "Emily Johnson",
    image: "https://picsum.photos/id/10/400/400",
    description:
      "MyCofoundr.AI helped me connect with the right investors in record time. Their AI-driven recommendations are a game-changer!",
    rating: 4.5,
  },
  {
    name: "Michael Roberts",
    image: "https://picsum.photos/id/12/400/400",
    description:
      "The feedback on my pitch deck was invaluable. I felt much more confident approaching investors after using the platform.",
    rating: 4.5,
  },
  {
    name: "David Harris",
    image: "https://picsum.photos/id/121/400/400",
    description:
      "I love the smart notifications feature! It keeps me organized and ensures I never miss a follow-up or important deadline.",
    rating: 4.5,
  },
  {
    name: "Emily Davis",
    image: "https://picsum.photos/id/14/400/400",
    description:
      "The personalized funder matchmaking made all the difference. I was able to secure funding that I never thought possible!",
    rating: 4.5,
  },
];

const faq = [
  {
    question: "What is MyCofoundr.AI?",
    answer:
      "MyCofoundr.AI is an AI co-pilot to help founders of startups and small and medium-sized businesses (SMBs) improve their chances of success. Our platform provides personalized, 24/7 access to business advice, funding recommendations and pitch optimization tools. Using our proprietary AI matching engine, we connect you with funders, accelerators and other resources based on shared backgrounds, experiences and preferences—dramatically increasing your chances of securing the right support for your business. Whether you’re seeking funding, mentoring, or strategic advice, MyCofoundr.AI is here to guide you every step of the way.",
  },
  {
    question: "How does the funder matchmaking process work?",
    answer:
      'Our proprietary AI-powered matchmaking engine uses what we call our "homophily engine” based on the anthropological reality that people with similar backgrounds and experiences are more likely to collaborate. We analyze vast amounts of data from investors, accelerators, banks, and grants, and then match you with funders whose preferences, interests, or experiences align with yours. This dramatically increases the likelihood of a successful connection, helping you find the right partners to grow your business. Once matched, you’ll receive access to their contact information and AI-powered insights on how to approach them.',
  },
  {
    question: "Can I get feedback on my pitch deck?",
    answer:
      "Yes! MyCofoundr.AI offers a powerful AI-driven pitch deck and business plan review tool. You can upload your deck or plan directly into the platform, and our AI will provide detailed, actionable feedback based on best practices from the startup and funding ecosystem. This includes suggestions for answering key investor/funder concerns and improving structure, clarity, and overall messaging to ensure that your presentation resonates with investors and funders.",
  },
  {
    question: "How do I activate smart notifications, and how do they help me?",
    answer:
      "Activating smart notifications is simple. Once you sign up and complete your profile, go to the settings menu and enable notifications. From there, you can customize what you’d like to be alerted about, such as new funder matches, upcoming events, or deadlines for relevant grants or programs. These notifications are designed to keep you informed and give you a competitive edge, helping you stay on top of new opportunities that are personalized to your business needs.",
  },
  {
    question: "What should be included in the pitch deck or business plan?",
    answer: (
      <p>
        Your pitch deck or business plan should cover the key elements that
        funders look for:
        <ul className="list-disc ml-5">
          <li>
            Problem Statement: What specific problem are you solving? Solution:
            How does your product or service solve this problem?
          </li>
          <li>
            Market Opportunity: What is the size of the market, and who are your
            customers?
          </li>
          <li> Business Model: How will you make money?</li>
          <li>
            Go-to-Market Strategy: How do you plan to reach and acquire
            customers?
          </li>
          <li>
            Traction: Include any key milestones, customer growth, or early
            revenue.
          </li>
          <li>
            Team: Highlight the experience and expertise of your core team.
          </li>
          <li>
            Financial Projections: Provide high-level financial projections for
            at least the next 3 years.
          </li>
        </ul>
      </p>
    ),
  },
  {
    question: "Is there a cost to use MyCofoundr.AI?",
    answer: (
      <p>
        Yes, MyCofoundr.AI operates on a subscription model. For solo founders,
        the cost is $49.99 per month (on an annual basis), which includes
        unlimited access to our funder-matchmaking engine, full AI-powered pitch
        deck reviews and personalized fundraising guidance. There is also a $99
        monthly option for founders who don’t want an annual subscription. Teams
        and enterprises have additional pricing options depending on their size
        and needs. We also offer a freemium version and occasional trials for
        new users, so you can explore the platform before committing to a paid
        plan. <br />
        <br />
        Yes, MyCofoundr.AI operates on a subscription model with various options
        to suit different needs. For solo founders, we offer two plans: an
        annual subscription at $49.99 per month (billed annually) and a monthly
        option at $99 per month for those who prefer more flexibility. The
        annual includes unlimited access to our funder-matchmaking engine, full
        AI-powered pitch deck reviews, and personalized fundraising guidance.
        Teams and enterprises have access to additional pricing options tailored
        to their size and specific requirements. We understand that some users
        might want to try before they commit, so we also provide a limited,
        7-day free trial for new users to explore the platform's capabilities.
        This allows potential subscribers to experience the value of our
        services before choosing a paid plan. Whether you're a solo entrepreneur
        or part of a larger organization, we have a subscription option designed
        to support your fundraising journey.
      </p>
    ),
  },
];

function LandingIndex() {
  const [accordion, setAccordion] = useState(-1);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  return (
    <LandingLayout>
      <>
        {/* hero section */}
        <section className="md:pt-20 pt-10 xl:pb-36 pb-10 max-w-[1440px] mx-auto px-7 ">
          <div className="flex xl:flex-row flex-col xl:items-center justify-between w-full gap-6 ">
            <div className="xl:w-1/2 space-y-6 xl:max-w-[620px]">
              <h1 className="xl:text-6xl text-3xl font-bold text-grey900 font-spProDisplay ">
                Welcome to MyCofoundr.AI, your unfair advantage.
              </h1>
              <p className="text-grey600 text-xl font-regular">
                AI-powered matchmaking to connect founders to the funders most
                likely to invest or provide non-dilutive funding.
              </p>
              <div className="pt-3">
                <Link
                  to={"/get-started"}
                  className="text-base font-semibold bg-primary600 text-white rounded-lg py-4 px-7"
                >
                  Join Today
                </Link>
              </div>
            </div>
            <div className="xl:w-1/2 w-full h-full">
              {/* <img src={HeroBg} alt={"hero bg"} className="w-full" /> */}
              <iframe
                width="100%"
                height="400px"
                className="max-w-[1200px] mx-auto rounded-3xl flex justify-center items-center w-full"
                src="https://www.youtube.com/embed/YXGf97E8QiU"
                title="MyCofoundr.AI: Demo Video (GS - no music)"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>

        {/* Our Story */}
        <section className="">
          <div className="xl:py-[120px] max-w-[1440px] mx-auto flex xl:flex-row flex-col items-center justify-between px-7">
            <div className="mt-5 xl:mt-0 order-2 xl:order-1">
              <img src={newStory} alt={"hero bg"} className="w-full" />
            </div>
            <div className="mt-5 xl:mt-0 xl:w-[48%] order-1 xl:order-2 ">
              <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5">
                Our Story
              </span>
              <h3 className="text-4xl font-semibold mt-3">
                Building a Smarter Path to Funding for Every Founder
              </h3>
              <p className="text-grey600 text-xl font-regular mt-5 text-justify">
                We are a team of seasoned tech entrepreneurs and small business
                owners, and expert AI engineers. With a track record of
                successfully raising funds and exiting our startups, we
                intimately understand the exhilarating highs and challenging
                lows of the entrepreneurial journey. Driven by our own
                experiences, we are passionately crafting the solution we wish
                we had—a tool designed to make your path as a founder smoother
                and more rewarding.  
              </p>
            </div>
          </div>
        </section>

        {/* About Us */}
        <Element name="AboutUs">
          <section className="bg-primary50" id="AboutUs">
            <div className="py-[120px] max-w-[1440px] mx-auto xl:flex items-center justify-between px-7">
              <div className="xl:w-[48%]">
                <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5">
                  About us
                </span>
                <h3 className="text-4xl font-semibold mt-3">
                  Empowering Entrepreneurs: From Survival to Success
                </h3>
                <p className="text-grey600 text-xl font-regular mt-5 text-justify">
                  In a world in which 460,000 new companies launch monthly in
                  the US (including 85,000 tech startups), the stark reality is
                  that only 10% survive long-term. MyCofoundr.AI revolutionizes
                  these odds. We're not just another business tool; we're your
                  24/7 AI co-pilot, engineered to transform startup struggles
                  into success stories.
                  <br />
                  <br />
                  Our cutting-edge AI-powered platform doesn't just answer
                  questions—it anticipates challenges, provides tailored
                  solutions, and connects you with the right funding sources at
                  the right time. We're democratizing access to world-class
                  business support, leveling the playing field for startups and
                  SMEs across diverse communities.
                  <br />
                  <br />
                  With MyCofoundr.AI, you're not just aiming to survive. You're
                  positioned to thrive. We're creating pathways to generational
                  wealth, ensuring that every innovative business, regardless of
                  background, has the opportunity to make a lasting impact.
                  Don't just dream of success; let MyCofoundr.AI help you
                  engineer it.
                </p>
              </div>
              <div className="mt-5 xl:mt-0">
                <img src={Story} alt={"hero bg"} className="w-full" />
              </div>
            </div>
          </section>
        </Element>

        {/* our solution */}
        <Element name="Solution">
          <section
            className="py-[150px] max-w-[1440px] mx-auto px-7"
            id="Solution"
          >
            <div className="flex flex-col justify-center items-center">
              <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5">
                Our Solution
              </span>
              <h3 className="text-4xl font-semibold mt-3 max-w-[768px] text-center">
                Transforming Startup Success Through AI-Powered Funding
                Solutions
              </h3>
              <p className="text-grey600 text-xl font-regular mt-5 text-center">
                Connect you with the right funding sources and optimize your
                fundraising efforts.
              </p>
            </div>

            <div className="mt-16">
              <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-x-8 gap-y-16">
                {solution.map((item, index) => {
                  return (
                    <div className="flex flex-col items-center justify-center">
                      <div className="bg-primary100 p-4 rounded-full w-fit">
                        <item.icon className="size-7 text-primary600" />
                      </div>
                      <h1 className="mt-5 text-xl font-medium">{item.title}</h1>
                      <p className="text-center text-md font-regular text-grey600 max-w-[400px]">
                        {item.description}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </Element>

        {/* Testimonials */}
        <Element name="Testimonials">
          <section className="bg-primary50" id="Testimonials">
            <div className="py-[120px] max-w-[1440px] mx-auto px-4 flex flex-col items-center justify-center">
              <div className="flex flex-col justify-center items-center">
                <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5">
                  What Our Users Say
                </span>
                <h3 className="text-4xl font-semibold mt-3 max-w-[768px] text-center">
                  Real Experiences from Founders like You
                </h3>
                <p className="text-grey600 text-xl font-regular mt-5 max-w-[768px] text-center">
                  Hear how MyCofoundr.AI has transformed the fundraising
                  journeys of startups and empowered entrepreneurs to connect
                  with the right funder sources
                </p>
              </div>
              <div className="mt-16 ">
                <div
                  className="flex items-center gap-x-6 xl:w-full w-screen overflow-x-scroll px-4 xl:px-0"
                  ref={scrollRef}
                >
                  {testimonials.map((testimonial, index) => {
                    return (
                      <div className="bg-white py-8 px-2 flex flex-col items-center justify-center rounded h-[350px] w-full min-w-[300px]">
                        <img
                          src={testimonial.image}
                          alt="testimonial"
                          className="size-20 rounded-full"
                        />
                        <h3 className="mt-5 text-lg font-semibold">
                          {testimonial.name}
                        </h3>
                        <div className="mt-2">
                          <Rating rating={testimonial.rating} maxRating={5} />
                        </div>
                        <p className="max-w-[285px] text-grey600 text-base font-regular mt-5 text-center">
                          {testimonial.description}
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div className="flex items-center justify-center gap-x-6 mt-16">
                  <div
                    className="p-6 cursor-pointer rounded-full border-2 border-grey600"
                    onClick={() => {
                      if (scrollRef.current) {
                        scrollRef.current.scrollLeft -= 280;
                      }
                    }}
                  >
                    <FiChevronLeft />
                  </div>
                  <div
                    className="p-6 cursor-pointer rounded-full bg-primary600"
                    onClick={() => {
                      if (scrollRef.current) {
                        scrollRef.current.scrollLeft += 280;
                      }
                    }}
                  >
                    <FiChevronRight className="text-white " />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Element>

        {/* FAQ */}
        <Element name="FAQ">
          <section className="py-[150px] max-w-[1440px] mx-auto " id="FAQ">
            <div className="flex flex-col justify-center items-center">
              <span className="text-primary600 rounded-3xl border border-primary text-base font-semibold px-[25px] py-0.5">
                Frequently Asked Questions
              </span>
              <h3 className="text-4xl font-semibold mt-3 max-w-[768px] text-center">
                Get the Answers You Need
              </h3>
              <p className="text-grey600 text-xl font-regular mt-5 text-center max-w-[768px]">
                We've gathered the most common questions from founders like you.
                Explore how MyCofoundr.AI works, what features we offer, and how
                we can help you secure the right funders for your startup.
              </p>
            </div>

            <div className="mt-16 py-4 px-8 rounded-md bg-primary50">
              {faq.map((item, index) => (
                <div
                  className="cursor-pointer"
                  onClick={() => setAccordion(accordion === index ? -1 : index)}
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <h5 className="border-b py-3 px-4 text-lg font-semibold text-grey700">
                      {item.question}
                    </h5>
                    <FiChevronUp
                      className={`text-grey900 text-base transition-transform ${
                        accordion === index ? "" : "rotate-180"
                      }`}
                    />
                  </div>
                  <p
                    className={` px-4 border-b text-base font-regular text-grey600 overflow-y-scroll overflow-x-hidden transition-all ease-in-out duration-500 ${
                      accordion === index ? "py-2 h-full" : "h-0"
                    }`}
                  >
                    {item.answer}
                  </p>
                </div>
              ))}
            </div>
          </section>
        </Element>
      </>
    </LandingLayout>
  );
}

export default LandingIndex;
