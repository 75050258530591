import React, { FC, useCallback, useState } from "react";
import { Alert, Button, Progress, Timeline } from "antd";
import BusinesInfo from "../tabs/businesInfo";
import DemographicsInfo from "../tabs/demographicsInfo";
import { useAppSelector } from "../../../lib/hooks";
import { RootState } from "../../../lib/store";
import { shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculatePercentage } from "../../../utils/calculatePercentage";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/Tabs";
import AiBlue from "../../../assets/svg/ai-blue.svg";
import { useSemanticMutation } from "../../../lib/features/auth/authApiSlice";
import { toast } from "react-toastify";
import { useGetRecommendationsQuery } from "../../../lib/features/recommendations/recommendations";
import {
  capitalizeFirstLetter,
  TimelineInterface,
} from "../../Recommendation/Recommendation";
import { infoMessage } from "../../../constants/notification";
import useAnalyticsEventTracker from "../../../utils/Analytics";

interface IProps {}

export const Information: FC<IProps> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.user, shallowEqual);
  const startupData = useAppSelector(
    (state: RootState) => state.startup,
    shallowEqual
  );

  const gAnalytics = useAnalyticsEventTracker("profile");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const semanticNotif = [
    `Searching for (${capitalizeFirstLetter(
      startupData?.product_stages || ""
    )}) stage investors`,
    `Getting ${user.race} Venture Capitalists`,
    `Finding VCs in ${capitalizeFirstLetter(user?.state || "")}`,
    `Searching for investors that are focused on ${startupData?.industry?.map(
      (item, index) => capitalizeFirstLetter(item)
    )}`,
    "Searching for investors willing to lead.",
  ];

  const [semanticNofifList, setSemanticNofitList] =
    React.useState(semanticNotif);
  const [activeKey, setActiveKey] = useState("Business");
  const [timelineItems, setTimelineItems] = useState<TimelineInterface[] | []>(
    []
  );

  const calculateNonEmptyPercentage = useCallback(() => {
    const val = calculatePercentage(user, startupData);
    return val;
  }, [startupData, user]);

  const [semantic, { isLoading: getSemanticLoading }] = useSemanticMutation();
  const [pendingNotification, setPendingNotification] = useState("");

  const { data: vcRecommendations, refetch: vcRecommendationsRefetch } =
    useGetRecommendationsQuery(user?.id);

  const vcRecommendationsInvestors = vcRecommendations?.vcs_list || [];
  const anglesRecommendationsInvestors =
    vcRecommendations?.investors_list || [];

  const getSemantic = () => {
    let semanticCompleted = false;
    const toastId = toast.loading("Getting recommendations");
    gAnalytics("Clicked Get recommendations", user?.email || "User");

    try {
      semantic({ user_id: user?.id || "", startup_id: startupData?.id || "" })
        .then((res: any) => {
          semanticCompleted = true;
          if (res.error) {
            throw new Error(
              res.error?.data?.detail || "Failed to fetch recommendations"
            );
          }
          if (
            res.data instanceof Object &&
            Object.keys(res.data).length === 0
          ) {
            semanticCompleted = true;
            // infoMessage("No recommendations retrieved, Please make sure your profile data is 100% completed.");
            toast.update(toastId, {
              render:
                "No recommendations retrieved, Please make sure your profile data is 100% completed.",
              type: "info",
              isLoading: false,
              autoClose: 4000,
            });
            return;
          }

          //
          vcRecommendationsRefetch();
          // successMessage("Personalized recommendations retrieved")
          toast.update(toastId, {
            render: "Personalized recommendations retrieved",
            type: "success",
            isLoading: false,
            autoClose: 4000,
          });
          setTimelineItems([]);

          setTimeout(() => {
            navigate("/recommendation");
            // setNavigateToAsk(true)
          }, 1000);
        })
        .catch((err) => {
          semanticCompleted = true;
          // infoMessage(err.toString() || "Failed to fetch recommendations")
          toast.update(toastId, {
            render: err.toString() || "Failed to fetch recommendations",
            type: "error",
            isLoading: false,
            autoClose: 4000,
          });
        });
    } catch (err: any) {
      infoMessage(err);
    }

    if (!semanticCompleted) {
      const updateTimeline = (index: any) => {
        if (index < semanticNofifList.length) {
          if (
            !timelineItems.some(
              (timelineItem) =>
                timelineItem.children === semanticNofifList[index]
            )
          ) {
            setPendingNotification(
              semanticNofifList[index % semanticNofifList.length]
            );
            setSemanticNofitList((prevList) => {
              const newList = prevList.filter((_, i) => i !== index);
              return newList;
            });
            setTimelineItems((prevItems) => [
              ...prevItems,
              { children: semanticNofifList[index] },
            ]);
          }
        }
      };

      const intervals = [0, 4000, 8000, 12000, 14000, 18000];
      intervals.forEach((delay, indexInterval) => {
        setTimeout(() => updateTimeline(indexInterval), delay);
      });
    }
  };

  return (
    <div className="px-0 pt-4 w-full h-[95vh] overflow-y-scroll">
      <h2 className="text-xl font-medium">Information</h2>
      <div className="mt-6 py-2 xl:px-10 ">
        {parseInt(calculateNonEmptyPercentage()) < 99 && (
          <div className="bg-[#E3F0FF] p-1 rounded-md flex items-center justify-between">
            <div className="flex items-center gap-x-3 pl-5">
              <img src={AiBlue} alt="" />
              <p className="text-sm text-primary">
                Complete your profile using our AI assistant. (Recommended)
              </p>
            </div>
            <button
              onClick={() => {
                gAnalytics(
                  "Clicked to complete profile with AI",
                  user?.email || "User"
                );
                navigate("/ai-complete-profile");
              }}
              className="flex items-center gap-x-2 bg-primary text-[15px] text-white px-5 py-3.5 rounded-md text-sm font-normal"
            >
              <p>
                {parseInt(calculateNonEmptyPercentage()) > 40
                  ? "Continue"
                  : "Get Started"}
              </p>
            </button>
          </div>
        )}
        {getSemanticLoading && (
          // <div className='flex items-center justify-center h-[50vh]'>
          <div className="mt-6">
            <div>
              <Timeline
                className="text-2xl font-bold"
                pending={pendingNotification}
                reverse={false}
                items={timelineItems}
              />
            </div>
          </div>
        )}

        <p className="text-[13px] mt-7 text-[#4A4A4A]">
          Please complete your profile. The more information you provide, the
          more personalized our recommendations
        </p>
        <div className="mt-5 mb-7">
          <Progress percent={parseInt(calculateNonEmptyPercentage())} />
        </div>
        {parseInt(calculateNonEmptyPercentage()) >= 90 && (
          <div className="mb-3">
            <Alert
              message="🎉 Your profile is sufficiently complete!"
              description={
                <div>
                  <p>
                    You can now explore personalized recommendations just for
                    you. Click the button below to check them out.
                  </p>
                  <p>
                    Alternatively, if you have any questions or need assistance,
                    feel free to ask our assistant anytime!
                  </p>
                  <div className="flex flex-col xl:flex-row gap-3 mt-4">
                    <Link
                      className="bg-primary text-white rounded-lg px-3 py-1.5 w-fit"
                      to="/process-feedback"
                      onClick={() => {
                        gAnalytics(
                          "Clicked Get personalized feedback",
                          user?.email || "User"
                        );
                      }}
                    >
                      Get personalized feedback
                    </Link>
                    <Button
                      className="bg-primary text-white rounded-lg px-3 py-1.5 w-fit"
                      onClick={getSemantic}
                      loading={getSemanticLoading}
                    >
                      Get{" "}
                      {vcRecommendationsInvestors.length +
                        anglesRecommendationsInvestors.length >=
                        10 && "More"}{" "}
                      personalized funder recommendation
                    </Button>
                    {/* <Link className='bg-primary text-white rounded-lg px-3 py-1.5' to="/chat">Get personalized support and advice</Link> */}
                  </div>
                </div>
              }
              type="info"
              showIcon
              // closable
              // onClose={() => setNavigateToAsk(false)}
            />
          </div>
        )}

        <Tabs defaultValue="Business" value={activeKey}>
          <TabsList className="gap-x-4 bg-primary50" color="red | tomato">
            <TabsTrigger
              value="Business"
              onClick={() => setActiveKey("Business")}
            >
              Business Information
            </TabsTrigger>
            <TabsTrigger
              value="Demographic"
              onClick={() => setActiveKey("Demographic")}
            >
              Demographic Information
            </TabsTrigger>
          </TabsList>

          <TabsContent value="Business">
            {/* <BusinesInfo setActiveKey={setActiveKey} /> */}
          </TabsContent>
          <TabsContent value="Demographic">
            <DemographicsInfo
              complete={parseInt(calculateNonEmptyPercentage()) >= 90}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
