import React from "react";
import { RootState } from "../../lib/store";
import { useAppSelector } from "../../lib/hooks";
import askBtn from "../../assets/svg/askBtn.svg";
import { PulseLoader } from "react-spinners";
import { ArrowRight } from "lucide-react";
import { SearchSkeleton } from "../../components/SearchSkeleton";
import {
  useAiCompleteProfileMutation,
  useGetSingleAIConversationQuery,
} from "../../lib/features/ai_agent/aiAgentApiSlice";
import AIBlue from "../../assets/svg/ai-light-blue.svg";
import { useNavigate } from "react-router-dom";
import { successMessage } from "../../constants/notification";
import { AIMessage } from "../../components/message";
import { useGetUserQuery } from "../../lib/features/user/userApiSlice";
import { useGetStartUpQuery } from "../../lib/features/startup/startupApiSlice";
import { IconWhite } from "../../assets";
import { AiOutlineAudio } from "react-icons/ai";
import { RiStopFill } from "react-icons/ri";

function AICompleteProfile() {
  const navigate = useNavigate();
  const [question, setQuestion] = React.useState("");
  const scrollableDivRef = React.useRef<HTMLDivElement | null>(null);
  const [isRecording, setIsRecording] = React.useState(false);
  const [audioBlob, setAudioBlob] = React.useState<Blob | null>(null);
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const audioChunksRef = React.useRef<Blob[]>([]);

  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const userId = useAppSelector((state: RootState) => state.user?.id);
  const startupId = useAppSelector((state: RootState) => state.startup?.id);
  const { refetch: userDataRefetch } = useGetUserQuery(userId);
  const { refetch: startupDataRefetch } = useGetStartUpQuery(userId);

  const { data, refetch } = useGetSingleAIConversationQuery({ userID: userId });
  const [aiCompleteProfile, { isLoading: askIsLoading }] =
    useAiCompleteProfileMutation();

  // Start Recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        setAudioBlob(blob);
        console.log(audioBlob);
        if (blob) {
          handleSubmit(blob);
          // const audioUrl = URL.createObjectURL(audioBlob);
          // console.log(audioUrl);
          // const audio = new Audio(audioUrl);
          // console.log(audio);
          // audio.play();
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    console.log("Your recording has been stopped");
  };

  const handleSubmit = async (blob: Blob) => {
    const formData = new FormData();
    formData.append("user_id", userId as string);
    formData.append("startup_id", startupId as string);
    formData.append("user_response", question);

    if (blob.size > 0) {
      formData.append("user_audio", blob, "recording.webm");
    }

    try {
      await aiCompleteProfile(formData)
        .unwrap()
        .then((res) => {
          setQuestion("");
          refetch()
            .then(() => {
              console.log(res);
              if (res?.response_type === "audio") {
                try {
                  const newAudioBlob = new Blob(
                    [
                      Uint8Array.from(atob(res?.response), (c) =>
                        c.charCodeAt(0)
                      ),
                    ],
                    { type: "audio/webm" }
                  );
                  console.log(newAudioBlob);
                  const audioUrl = URL.createObjectURL(newAudioBlob);
                  console.log(audioUrl);
                  const audio = new Audio(audioUrl);
                  console.log(audio);
                  audio.play();
                  setAudioBlob(null);
                } catch (error) {
                  console.error("Error playing audio:", error);
                }
              }
              scrollToBottom();
            })
            .catch((err) => {
              scrollToBottom();
            });
        });
    } catch (error) {
      console.error("Error submitting response:", error);
    }
  };

  // const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
  //     'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  const scrollToBottom = React.useCallback(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, []);

  const scrollToDivBottom = React.useCallback(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // Trigger button click
      buttonRef.current?.click();
      scrollToBottom();
    }
  };

  React.useEffect(() => {
    scrollToBottom();
    if (data?.content?.length === 0) {
      // console.log("This will come in handy")
      aiCompleteProfile({
        user_id: userId!!,
        startup_id: startupId!!,
        user_response: "",
      })
        .then((res) => {
          setQuestion("");
          startupDataRefetch();
          userDataRefetch();
          refetch();
          scrollToBottom();
        })
        .catch((err) => {});
    }
    if (data?.unanswered_fields_list?.length === 0) {
      successMessage(
        "You have completed all the questions, You will be redirected to the recommendation page in 3 seconds"
      );
      // const timer = setTimeout(() => {
      //   navigate("/profile");
      // }, 3000);
      // return () => clearTimeout(timer);
    }
  }, [
    aiCompleteProfile,
    data,
    navigate,
    refetch,
    scrollToBottom,
    startupDataRefetch,
    startupId,
    userDataRefetch,
    userId,
  ]);

  return (
    <div className="h-screen w-full overflow-y-auto relative">
      <div className="lg:fixed flex justify-between items-center p-6 bg-white z-[1] w-full ml-3 border-b">
        <h2 className="text-xl font-semibold">Complete profile</h2>
        {/* <div className="w-full h-0.5 border-t border-grey50" /> */}
      </div>

      <div className="flex flex-col relative lg:mt-20">
        <div
          className="lg:w-[60vw] w-full lg:max-w-[60vw] mx-auto"
          ref={scrollableDivRef}
        >
          {data?.content?.map((item: any, index: number) => {
            const isQuestion = item?.role === "user";
            const parsedConversation = !isQuestion && item.content;
            const isLastItem = index === data?.length - 1;

            //
            return isQuestion ? (
              <div key={index}>
                <div className="text-[18px] mt-10 w-full flex justify-end">
                  <div className="flex items-center gap-x-1">
                    <p className="p-4 bg-primary600 min-w-[360px] max-w-[640px] text-lg text-white font-bold rounded-b-md rounded-tl-md">
                      {item.content.USER}
                    </p>
                    <div className="border border-gray-500 rounded-full p-0.5 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        fill="currentColor"
                        className="size-6"
                      >
                        <path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-5" key={index}>
                {/* <Sources sources={parsedConversation.sources ?? []} /> */}
                <div className="flex gap-x-2 items-start">
                  <img src={AIBlue} alt="" />

                  <div>
                    <AIMessage content={parsedConversation?.AI} />
                    {/* <p className='mt-2 font-medium'>{parsedConversation?.AI}</p> */}
                    {parsedConversation?.options.length > 0 && (
                      <>
                        <div className="mt-2 flex items-center gap-2.5">
                          <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"
                            >
                              <path d="m2 9 3-3 3 3"></path>
                              <path d="M13 18H7a2 2 0 0 1-2-2V6"></path>
                              <path d="m22 15-3 3-3-3"></path>
                              <path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                            </svg>
                            Choose Options
                          </h2>
                        </div>
                        <div className="flex flex-wrap gap-4 mt-2">
                          {parsedConversation?.options.map(
                            (option: any, index: number) => (
                              <div className="" key={index}>
                                <button
                                  className="flex w-fit items-center gap-x-1 border border-gray-500 rounded-lg hover:bg-foreground/5 px-3 text-accent-foreground/50"
                                  value={option}
                                  onClick={() => {
                                    scrollToDivBottom();
                                    setQuestion(option);
                                    aiCompleteProfile({
                                      user_id: userId!!,
                                      startup_id: startupId!!,
                                      user_response: option,
                                    })
                                      .then((res) => {
                                        setQuestion("");
                                        refetch();
                                        scrollToBottom();
                                      })
                                      .catch((err) => {});
                                  }}
                                >
                                  {/* <p className='font-normal text-sm whitespace-normal text-left'>{alphabets[index]} -</p> */}
                                  <p className="font-normal text-sm text-accent-foreground/50 whitespace-normal text-left">
                                    {option}
                                  </p>
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}

                    {parsedConversation?.follow_up_questions.length > 0 && (
                      <>
                        <div className="mt-2 flex items-center gap-2.5">
                          <h2 className="flex items-center leading-none py-2 text-lg font-semibold">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="lucide lucide-repeat2 mr-1.5 text-muted-foreground"
                            >
                              <path d="m2 9 3-3 3 3"></path>
                              <path d="M13 18H7a2 2 0 0 1-2-2V6"></path>
                              <path d="m22 15-3 3-3-3"></path>
                              <path d="M11 6h6a2 2 0 0 1 2 2v10"></path>
                            </svg>
                            Follow up question
                          </h2>
                        </div>
                        {parsedConversation?.follow_up_questions.map(
                          (rq: any, index: number) => (
                            <div
                              className="flex items-center w-full"
                              key={index}
                            >
                              <ArrowRight className="h-4 w-4 mr-2 mt-1 flex-shrink-0 " />
                              <button
                                className="flex-1 justify-start px-0 py-1 h-fit "
                                value={rq}
                                onClick={() => {
                                  scrollToDivBottom();
                                  setQuestion(rq);
                                  aiCompleteProfile({
                                    user_id: userId!!,
                                    startup_id: startupId!!,
                                    user_response: rq,
                                  })
                                    .then((res) => {
                                      setQuestion("");
                                      refetch();
                                      scrollToBottom();
                                    })
                                    .catch((err) => {});
                                }}
                              >
                                <p className="font-normal text-base  whitespace-normal text-left">
                                  {rq}
                                </p>
                              </button>
                            </div>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>

                <hr className="my-6 outline outline-gray-300" />

                {isLastItem && !askIsLoading && question === "" && (
                  <div style={{ width: "100%", height: "18vh" }} />
                )}
              </div>
            );
          })}

          {question !== "" && (
            <>
              <div className="text-[18px] mt-10 w-full flex justify-end">
                <div className="flex items-center gap-x-1">
                  <p>{question}</p>
                  <div className="border border-gray-500 rounded-full p-0.5 flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 256"
                      fill="currentColor"
                      className="size-4"
                    >
                      <path d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8ZM72 96a56 56 0 1 1 56 56 56.06 56.06 0 0 1-56-56Z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </>
          )}

          {askIsLoading && (
            <>
              <SearchSkeleton />
              <div style={{ width: "100%", height: "18vh" }} />
            </>
          )}

          {/* {!askIsLoading && */}
          <div ref={divRef} style={{ width: "100%", height: "18vh" }} />
          {/* } */}
        </div>

        <div className="fixed w-full mt-5 bottom-0 flex justify-center bg-white py-6 lg:left-[10%]">
          <div className="relative lg:w-1/2 w-full">
            <input
              ref={inputRef}
              onKeyDown={handleKeyDown}
              placeholder={"Reply"}
              value={question}
              onChange={({ target: { value } }) => {
                setQuestion(value);
              }}
              className="outline-none border border-[#A5D8FF] rounded-[100px] h-[54px] shadow-[0px_0px_8px_8px_#E7F5FF] pr-[100px] pl-5 text-base text-[#495057] w-full "
            />

            <div className="flex items-center absolute right-2.5 gap-1.5 top-3">
              {isRecording ? (
                <button
                  onClick={stopRecording}
                  className="bg-red-500 text-white p-2 rounded-full"
                >
                  <RiStopFill />
                </button>
              ) : (
                <button
                  onClick={startRecording}
                  className="bg-blue-500 text-white p-2 rounded-full"
                >
                  <AiOutlineAudio />
                </button>
              )}
              {askIsLoading ? (
                <PulseLoader
                  color="#228BE6"
                  style={{ marginTop: 10, marginRight: 5 }}
                  size={12}
                />
              ) : (
                <>
                  {/* <div style={{ cursor: 'pointer' }}><img src={newSessionBtn} alt={'newSessionBtn'} /></div> */}
                  <button
                    ref={buttonRef}
                    onClick={() => handleSubmit(new Blob())}
                    style={{ cursor: "pointer" }}
                    className="size-8 rounded-full bg-primary disabled:bg-primary/40 p-1.5 mr-2"
                    disabled={isRecording}
                  >
                    <img src={IconWhite} alt={"askBtn"} />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AICompleteProfile;
