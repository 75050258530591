import setting from "../../assets/Animations/consulting.gif";
import solution from "../../assets/Animations/hand-with-light-bulb.gif";
import opportunity from "../../assets/Animations/flow.gif";
import product from "../../assets/Animations/available.gif";
import transactions from "../../assets/Animations/exchange.gif";
import FeedbackCard from "./components/FeedbackCard";
import { useGetGeneratedFeedbackQuery } from "../../lib/features/user/userApiSlice";
import Loading from "../../components/Loading";
import { useEffect, useState } from "react";

type feedbackDataType = {
  parameter_name: string;
  what_user_did_well: string;
  what_needs_improvement: string;
  next_steps: string;
};
const Feedback = () => {
  const { data, isLoading } = useGetGeneratedFeedbackQuery(false);

  const [feedbackData, setFeedbackData] = useState<feedbackDataType[]>([]);

  const getIcon = (name: string) => {
    switch (name) {
      case "problem":
        return setting;
      case "solution":
        return solution;
      case "market_opportunity":
        return opportunity;
      case "products":
        return product;
      default:
        return transactions;
    }
  };

  const formatName = (name: string) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  useEffect(() => {
    if (!isLoading && data) {
      const cleanData = Object.keys(data).reduce((acc: any, key) => {
        if (key !== "_id" && key !== "userId") {
          const cleanedObj = Object.entries(data[key]).reduce(
            (obj: any, [subKey, value]) => {
              const cleanedKey = subKey.replace(/\s+/g, "");
              obj[cleanedKey] = value;
              return obj;
            },
            {}
          );
          acc[key] = cleanedObj;
        } else {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const result = Object.keys(cleanData)
        .filter((key) => key !== "_id" && key !== "userId")
        .map((key) => cleanData[key])
        .filter((obj) => Object.keys(obj).length > 0);

      setFeedbackData(result);
    }
  }, [isLoading, data]);

  if (isLoading) return <Loading />;

  console.log("feedback data:", feedbackData);
  return (
    <div className="w-full flex flex-col space-y-5 py-4 px-16">
      {feedbackData.length > 0 ? (
        feedbackData.map((feedback, index) => (
          <FeedbackCard
            key={index}
            name={formatName(feedback.parameter_name)}
            icon={getIcon(feedback.parameter_name)}
            improvements={feedback.what_user_did_well}
            needs={feedback.what_needs_improvement}
            next={feedback.next_steps}
          />
        ))
      ) : (
        <p className="text-primary600 text-[20px] font-semibold text-center">
          No Feedback to show!
        </p>
      )}
    </div>
  );
};

export default Feedback;
