import React from "react";
import logo from "../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, FormProps, Input } from "antd";
import { useNewPasswordMutation } from "../../lib/features/auth/authApiSlice";
import { errorMessage, successMessage } from "../../constants/notification";
import { useDispatch } from "react-redux";
import { authenticate } from "../../lib/features/auth/authSlice";
import { setProfile } from "../../lib/features/user/userSlice";
import { useLazyGetStartUpQuery } from "../../lib/features/startup/startupApiSlice";
import { useLazyGetConversationsQuery } from "../../lib/features/conversation/conversationApiSlice";

type FieldType = {
  username?: string;
  password?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
};

function NewPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getStartUp] = useLazyGetStartUpQuery();
  const [getConversations] = useLazyGetConversationsQuery();

  const [newPassword, { isLoading: newPasswordIsLoading }] =
    useNewPasswordMutation();
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const username = localStorage.getItem("username") || "";
    const session = localStorage.getItem("session") || "";
    newPassword({
      username: username,
      session: session,
      new_password: values.password,
    })
      .unwrap()
      .then(async (res: any) => {
        // await updateProfileImage({ userId: res?.userData.id })
        successMessage("Password changed successfully, Logining in...");
        dispatch(authenticate(res.signinResponse.AuthenticationResult));
        dispatch(setProfile(res?.userData));
        getStartUp(res?.userData.id);
        getConversations(res?.userData.id);
        console.log(res);
        if (res?.userData.watched_tutorial) {
          navigate("/funder-pipeline");
        } else {
          navigate("/funder-pipeline");
        }
      })
      .catch((err) => {
        console.log(err);
        errorMessage(err?.data?.detail || "Failed to login");
      });
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {};
  return (
    <>
      <style scoped>
        {`
                    .VerificationInputContainer {
                    
                    } 
                    
                    .VerificationInputStyle {
                        border: none;
                    }   
                    
                    .VerificationInputInactiveStyle {
                        border: none;
                        background-color: transparent;
                    } 
                          
                    .VerificationInputSelectedStyle {
                        border: none !important;
                        background-color: transparent;
                        outline: none;
                    }
                `}
      </style>
      <main className="h-screen bg-white">
        <header className="py-6 border-b border-gray-100">
          <nav className="flex items-center justify-between max-w-screen-xl mx-auto px-4">
            <Link to={"/"}>
              <img
                src={logo}
                style={{ height: 40, margin: "auto" }}
                alt={"logo"}
              />
            </Link>
          </nav>
        </header>

        <div className="flex justify-center items-center h-3/4">
          <div className="flex flex-col justify-center items-center">
            <img src={logo} className="h-8" alt={"logo"} />
            <h3 className="text-3xl font-semibold mt-6">
              {"Enter new password"}
            </h3>
            <p className="mt-3 text-gray-600">Your password needs attention</p>

            <div className="mt-8">
              <Form
                layout="vertical"
                style={{ width: 350 }}
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item<FieldType>
                  label="Password"
                  name="password"
                  hasFeedback
                  rules={[
                    { required: true, message: "Please input your password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || value.length <= 7) {
                          return Promise.reject(
                            new Error("Please input a stronger password")
                          );
                        }
                        const regex =
                          /^(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
                        if (!regex.test(value)) {
                          return Promise.reject(
                            new Error(
                              "Password must contain at least one number, one special character (!@#$%^&*-), one uppercase letter, and one lowercase letter."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="h-11"
                    placeholder="Create a password"
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="h-11"
                    placeholder="Confirm Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={newPasswordIsLoading}
                    loading={newPasswordIsLoading}
                    className="py-4 w-full bg-primary600 text-base font-semibold text-white"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>

              {/* <Button
                        onClick={() => {
                            setIsLogin(false)
                        }}
                        type="dashed"
                        style={{ width: '100%', borderColor: '#228BE6', color: '#228BE6' }}
                        htmlType="submit"
                    >
                        back
                    </Button> */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NewPassword;
